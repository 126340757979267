import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import {
  PencilIcon,
  TrashIcon,
  DownloadIcon,
  ChevronLeftIcon,
  UploadIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import { parseSnippetAddress } from '../../../../utils'

import { feedbackMessage } from '../../../../components/atoms/feedback'
import DefList from '../../../../components/atoms/def-list'
import Dropdown from '../../../../components/atoms/dropdown'
import Button from '../../../../components/atoms/button'

import usePPA from '../../../../hooks/use-ppa'
import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import { parseQuery } from '../../../../hooks/use-ppa/use-ppa-get-list'

import SlidingDrawer from '../../../../components/molecules/sliding-drawer'
import CollapseList from '../../../../components/molecules/collapse-list'
import DownloadHhd from '../../../../components/organisms/latest-hhd-download'

import { Meter, MeterCompany } from '../../../../types'

import { container, breakPointsContainer, transition } from '../../../../styles'
import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'

import EditMeter from './components/edit-meter'
import UploadHHD from './components/upload-hhd'
import UploadBill from './components/upload-bill'
import UploadContract from './components/upload-contract'

type File = Blob & {
  fileName: string
}

const MeterDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const { meterId } = useParams<{ meterId: string }>()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [isBillDrawerOpen, setIsBillDrawerOpen] = useState(false)
  const [isHhdDrawerOpen, setIsHhdDrawerOpen] = useState(false)
  const [isContractDrawerOpen, setIsContractDrawerOpen] = useState(false)

  const [isLoadingBillFiles, setIsLoadingBillFiles] = useState(false)
  const [billFiles, setBillFiles] = useState<File[] | []>([])

  const [isDeletingBillFiles, setIsDeletingBillFiles] = useState(false)
  const [isDownloadingBillFiles, setIsDownloadingBillFiles] = useState(false)

  const [isLoadingContractFiles, setIsLoadingContractFiles] = useState(false)
  const [contractFiles, setContractFiles] = useState<File[] | []>([])

  const [isDeletingContractFiles, setIsDeletingContractFiles] = useState(false)
  const [isDownloadingContractFiles, setIsDownloadingContractFiles] =
    useState(false)

  const [isLoadingHhdFiles, setIsLoadingHhdFiles] = useState(false)
  const [hhdFiles, setHhdFiles] = useState<File[] | undefined>()
  const [isDownloadingHhdFiles, setIsDownloadingHhdFiles] = useState(false)

  const { fetchData } = usePPA()

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<MeterCompany>({
      dataKey: 'meter',
      path: `/core/private/consumer-meter/consumer/${meterId}`,
    })

  const meterDetails: MeterCompany | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'mpan', title: 'MPAN' },
      { keyName: 'hhd', title: 'HHD timeframe' },
      {
        keyName: 'annualConsumption',
        title: 'Average annual consumption',
        suffix: 'MWh',
      },
      {
        keyName: 'gridConnectionSize',
        title: 'Grid connection size',
        suffix: 'KVA',
      },
      {
        keyName: 'profileClass',
        title: 'Profile class',
      },
      {
        keyName: 'company',
        title: 'Company',
        renderCustomEl: (item: Meter) => (
          <div className="flex flex-col">
            <div>{item.company?.name}</div>
            {item.company?.number && <div>{item.company?.number}</div>}
            {item?.company?.address && (
              <div>{parseSnippetAddress(item?.company?.address)}</div>
            )}
          </div>
        ),
      },
      {
        keyName: 'address',
        title: 'Meter address',
        renderCustomEl: (item: Meter) => parseSnippetAddress(item?.address),
      },
      {
        keyName: 'existingSupplier',
        title: 'Existing supplier',
        renderCustomEl: (item: Meter) => item.existingSupplier,
      },
      {
        keyName: 'brokerFee',
        title: 'Broker fee',
        suffix: '£/MWh',
        renderCustomEl: (item: Meter) => item?.brokerFee,
      },
    ]
  }, [meterDetails])

  const handleEditMeter = () => {
    setIsEditDrawerOpen(false)
    refetch()
  }

  const handleDeleteMeter = async () => {
    if (isLoading) return

    try {
      const { response, error: err } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-meter/consumer/${meterId}`,
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: t('actionsDropdown.delete.success'),
        },
        'success',
      )

      navigate('/meters')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'meter',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes('Meter.delete()') &&
            message.includes(
              'Meter cannot be deleted when associated with any tenders.',
            )
          ) {
            const [_, tenders] = message.split('The tenders are: ')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.meter.SERVER_ERROR.meterAssociatedWithTenders.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.meter.SERVER_ERROR.meterAssociatedWithTenders.description',
              )}\n${tenders}.`,
              type: 'error',
              duration: 8000,
            }
          }

          return undefined
        },
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Meter found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.meter.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleFetchBillFiles = async () => {
    if (isLoadingBillFiles) return
    setIsLoadingBillFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/bill/list?${parseQuery({
          meterId,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.billFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setBillFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingBillFiles(false)
    }
  }

  const handleDeleteBill = async (file: any) => {
    if (isDeletingBillFiles) return
    setIsDeletingBillFiles(true)

    try {
      const { error: hhdDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-meter/consumer/bill?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
      })

      if (hhdDeleteError) throw hhdDeleteError

      feedbackMessage(
        {
          title: 'Bill file deleted successfully',
        },
        'success',
      )
      handleFetchBillFiles()
    } catch (err: any) {
      throwFeedbackError({ err })
    } finally {
      setIsDeletingBillFiles(false)
    }
  }

  const handleDownloadBill = async (file: any) => {
    if (isDownloadingBillFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/bill?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.billFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingBillFiles(false)
    }
  }

  const handleBillUpload = () => {
    handleFetchBillFiles()
    setIsBillDrawerOpen(false)
  }

  const handleFetchHhdFiles = async () => {
    if (isLoadingHhdFiles) return
    setIsLoadingHhdFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/hhd/list?${parseQuery({
          meterId,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.hhdFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setHhdFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingHhdFiles(false)
    }
  }

  const handleDeleteHhd = async (file: any) => {
    if (isLoadingHhdFiles) return

    try {
      const { error: hhdDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-meter/consumer/hhd?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
      })

      if (hhdDeleteError) throw hhdDeleteError

      feedbackMessage(
        {
          title: 'Hhd file deleted successfully',
        },
        'success',
      )
      handleFetchHhdFiles()
    } catch (err: any) {
      throwFeedbackError({ err })
    }
  }

  const handleDownloadHhd = async (file: any) => {
    if (isDownloadingHhdFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/hhd?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.hhdFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingHhdFiles(false)
    }
  }

  const handleHhdUpload = () => {
    handleFetchHhdFiles()
    setIsHhdDrawerOpen(false)
  }

  const handleFetchContractFiles = async () => {
    if (isLoadingContractFiles) return
    setIsLoadingContractFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/contract/list?${parseQuery({
          meterId,
        })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const files = response?.data.contractFiles || []

      const processedFiles = await Promise.all(
        files.map(async (fileObject: { file: string; fileName: string }) => {
          const base64Data = fileObject.file || ''
          const fileNameFromResponse =
            fileObject.fileName || 'defaultFileName.pdf'

          const binaryResponse = await fetch(
            `data:application/octet-stream;base64,${base64Data}`,
          )
          const binaryData = await binaryResponse.blob()

          const blob = new Blob([binaryData], {
            type: 'application/octet-stream',
          })

          return { blob, fileName: fileNameFromResponse }
        }),
      )

      setContractFiles(processedFiles)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingContractFiles(false)
    }
  }

  const handleDeleteContract = async (file: any) => {
    if (isDeletingContractFiles) return
    setIsDeletingContractFiles(true)

    try {
      const { error: contractDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-meter/consumer/contract?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
      })

      if (contractDeleteError) throw contractDeleteError

      feedbackMessage(
        {
          title: 'Contract file deleted successfully',
        },
        'success',
      )
      handleFetchContractFiles()
    } catch (err: any) {
      throwFeedbackError({ err })
    } finally {
      setIsDeletingContractFiles(false)
    }
  }

  const handleDownloadContract = async (file: any) => {
    if (isDownloadingContractFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/consumer-meter/consumer/contract?${parseQuery({
          meterId,
          fileName: file.blob.fileName,
        })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.contractFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.blob.fileName)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingContractFiles(false)
    }
  }

  const handleContractUpload = () => {
    handleFetchContractFiles()
    setIsContractDrawerOpen(false)
  }

  useEffect(() => {
    if (!meterId) navigate('/meters')
  }, [meterId])

  useEffect(() => {
    if (error) throwFeedbackError({ err: error })
  }, [error])

  useEffect(() => {
    handleFetchBillFiles()
  }, [])

  useEffect(() => {
    handleFetchHhdFiles()
  }, [])

  useEffect(() => {
    handleFetchContractFiles()
  }, [])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/meters')}
        >
          {t('backButton')}
        </Button>
      </div>
      {isLoading && (
        <div className={classNames(transition, 'w-full fade-in')}>
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}
      {!isLoading && error && (
        <div className={classNames(transition, 'flex flex-col fade-in')}>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}
      {!isLoading && !error && meterDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
              {meterDetails.name}
            </h1>
            <Dropdown
              content={
                <div className="flex flex-col justify-start items-start">
                  <Button
                    variant="text"
                    icon={<PencilIcon />}
                    onClick={() => {
                      setIsEditDrawerOpen(true)
                    }}
                  >
                    {t('actionsDropdown.edit')}
                  </Button>
                  <Button
                    variant="text"
                    icon={<TrashIcon />}
                    onClick={handleDeleteMeter}
                    data-testid="button-delete"
                  >
                    {t('actionsDropdown.delete.button')}
                  </Button>
                </div>
              }
            />
          </div>
          <DefList rowKeys={rowKeys} data={meterDetails} />

          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('hhd.title')}
              </h1>
              <Button
                onClick={() => setIsHhdDrawerOpen(true)}
                variant="primary"
                icon={<UploadIcon className="w-4 h-4" />}
                smallLabel
              >
                {t('hhd.uploadButton')}
              </Button>
            </div>

            <CollapseList
              title={t('hhd.collapseList.title')}
              titleEmptyList={t('hhd.collapseList.filesNotFound')}
              list={
                hhdFiles && hhdFiles.length > 0
                  ? hhdFiles.map((file, index) => ({
                      id: `hhdFile-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadHhd(file)}
                        />
                        <TrashIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDeleteHhd(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
            <div className="my-5">
              <DownloadHhd mpan={meterDetails?.mpan as string} />
            </div>
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('bill.title')}
              </h1>
              <Button
                onClick={() => setIsBillDrawerOpen(true)}
                variant="primary"
                icon={<UploadIcon className="w-4 h-4" />}
                smallLabel
              >
                {t('bill.uploadButton')}
              </Button>
            </div>

            <CollapseList
              title={t('bill.collapseList.title')}
              titleEmptyList={t('bill.collapseList.filesNotFound')}
              list={
                billFiles && billFiles.length > 0
                  ? billFiles.map((file, index) => ({
                      id: `billFile-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadBill(file)}
                        />
                        <TrashIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDeleteBill(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>

          <div className="flex flex-col w-full">
            <div className="flex items-end mb-5 mt-10">
              <h1 className="text-lg font-medium text-ppa/title mr-3">
                {t('contract.title')}
              </h1>
              <Button
                onClick={() => setIsContractDrawerOpen(true)}
                variant="primary"
                icon={<UploadIcon className="w-4 h-4" />}
                smallLabel
              >
                {t('contract.uploadButton')}
              </Button>
            </div>

            <CollapseList
              title={t('contract.collapseList.title')}
              titleEmptyList={t('contract.collapseList.filesNotFound')}
              list={
                contractFiles && contractFiles.length > 0
                  ? contractFiles.map((file, index) => ({
                      id: `contractFile-${index}`,
                      name: file.fileName,
                      blob: file,
                    }))
                  : []
              }
              defaultOpen
              rowKeys={[
                { keyName: 'name' },
                {
                  keyName: 'actions',
                  containerClass: 'ml-auto pl-2 max-w-[50px]',
                  renderCustomEl: (file: any) => {
                    return (
                      <div className="flex items-center gap-x-2">
                        <DownloadIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDownloadContract(file)}
                        />
                        <TrashIcon
                          className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                          onClick={() => handleDeleteContract(file)}
                        />
                      </div>
                    )
                  },
                },
              ]}
            />
          </div>

          <EditMeter
            meter={meterDetails}
            onSuccess={handleEditMeter}
            isOpen={isEditDrawerOpen}
            handleClose={() => setIsEditDrawerOpen(false)}
          />
        </>
      )}
      <SlidingDrawer
        isOpen={isBillDrawerOpen}
        handleClose={() => setIsBillDrawerOpen(false)}
      >
        <UploadBill
          idMeter={meterDetails?.id}
          isDrawerOpen={isBillDrawerOpen}
          onSuccess={handleBillUpload}
          onCancel={() => setIsBillDrawerOpen(false)}
        />
      </SlidingDrawer>
      <SlidingDrawer
        isOpen={isHhdDrawerOpen}
        handleClose={() => setIsHhdDrawerOpen(false)}
      >
        <UploadHHD
          idMeter={meterDetails?.id}
          isDrawerOpen={isHhdDrawerOpen}
          onSuccess={handleHhdUpload}
          onCancel={() => setIsHhdDrawerOpen(false)}
        />
      </SlidingDrawer>
      <SlidingDrawer
        isOpen={isContractDrawerOpen}
        handleClose={() => setIsContractDrawerOpen(false)}
      >
        <UploadContract
          idMeter={meterDetails?.id}
          isDrawerOpen={isContractDrawerOpen}
          onSuccess={handleContractUpload}
          onCancel={() => setIsContractDrawerOpen(false)}
        />
      </SlidingDrawer>
    </section>
  )
}

export default MeterDetails
