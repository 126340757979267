import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import Stepper from '../../../../../../components/atoms/stepper'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import FormHHD, { HHDFormRef } from '../form-hhd'
import SiteForm from '../form-site'

import type { HHDFile } from '../../../../../../types'

export interface CreateSiteProps {
  handleClose: () => void
  onSuccess: () => void
  isOpen?: boolean
}

const CreateSite: React.FC<CreateSiteProps> = ({
  onSuccess,
  handleClose,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.siteDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const hhdForm = useRef<HHDFormRef>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const [siteId, setSiteId] = useState<string | undefined>()

  const handleCreateSite = async (site: any) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      let parsedRocBand: number | undefined

      if (site?.rocBand) {
        if (Number.isNaN(Number(site.rocBand))) {
          feedbackMessage(
            {
              title: t('create.onSubmit.error.rocNotANumber.title'),
              description: t('create.onSubmit.error.rocNotANumber.description'),
            },
            'error',
          )
          setIsLoading(false)
          return
        }
        parsedRocBand = parseFloat(parseFloat(site.rocBand).toFixed(3))
      }

      if (!site.capacity || Number.isNaN(Number(site.capacity))) {
        feedbackMessage(
          {
            title: t('create.onSubmit.error.capacityNotANumber.title'),
            description: t(
              'create.onSubmit.error.capacityNotANumber.description',
            ),
          },
          'error',
        )
        setIsLoading(false)
        return
      }

      if (!site.voltage || Number.isNaN(Number(site.voltage))) {
        feedbackMessage(
          {
            title: t('create.onSubmit.error.voltageNotANumber.title'),
            description: t(
              'create.onSubmit.error.voltageNotANumber.description',
            ),
          },
          'error',
        )
        setIsLoading(false)
        return
      }

      const parsedCompany = JSON.parse(site.company)
      const { response, error } = await fetchData({
        method: 'POST',
        url: '/core/private/site',
        body: {
          name: site.name,
          companyId: parsedCompany.id,
          address: {
            ...site.address,
          },
          technology: site.technology,
          capacity: parseFloat(parseFloat(site.capacity).toFixed(3)),
          voltage: parseFloat(parseFloat(site.voltage).toFixed(3)),
          rocBand: parsedRocBand,
          mpan: site?.mpan || null,
          msid: site?.msid || null,
        },
      })

      if (error || !response) throw error

      feedbackMessage(
        {
          title: t('create.onSubmit.success.title', { siteName: site.name }),
          description: t('create.onSubmit.success.description'),
        },
        'success',
      )

      setCurrentStep(1)

      setSiteId(response.data.site.id)
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'site',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes('The company') &&
            message.includes('must be active')
          ) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.site.SERVER_ERROR.companyMustBeActive',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleCreateHHD = async (data: HHDFile) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      if (!siteId) throw Error('Missing site ID')

      const formData = new FormData()
      formData.append('file', data.hhdFile as any)
      formData.append('siteId', siteId)

      const { error } = await fetchData({
        method: 'POST',
        url: `/core/private/site/hhd`,
        body: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('hhd.onUpload.success.description'),
        },
        'success',
      )

      onSuccess()
      hhdForm.current?.resetForm()
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
      setCurrentStep(0)
    }
  }

  const onSkipHHD = () => {
    setCurrentStep(0)
    setSiteId(undefined)
    onSuccess()
  }

  const handleCancel = () => {
    setCurrentStep(0)
    setSiteId(undefined)
    handleClose()
  }

  const stepElements = useMemo(() => {
    return [
      <SiteForm
        isLoading={isLoading}
        onSubmit={handleCreateSite}
        onCancel={handleCancel}
      />,
      <FormHHD
        isLoading={isLoading}
        onSubmit={handleCreateHHD}
        onSkip={onSkipHHD}
      />,
    ]
  }, [isLoading])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5">
        <h1 className="text-xl font-bold text-ppa/title">
          {t('create.title')}
        </h1>

        {isOpen && (
          <>
            <Stepper
              steps={[t('steps.addDetails'), t('steps.uploadHHD')]}
              activeStep={currentStep}
              setActiveStep={setCurrentStep}
              disabledStep={currentStep === 0 || currentStep === 1}
            />
            {stepElements[currentStep]}
          </>
        )}
      </div>
    </SlidingDrawer>
  )
}

export default CreateSite
