import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPA from '../../../../../../hooks/use-ppa'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'
import { parseCompanyToSelect } from '../../../../../../components/molecules/company-select'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import { Company, Meter as MeterDTO } from '../../../../../../types'
import { ErrorNames } from '../../../../../../types/errors'

import MeterForm, { FormValues } from '../form-meter'

type Meter = MeterDTO & { company: Company }

export interface EditMeterProps {
  onSuccess: () => void
  handleClose: () => void
  isOpen?: boolean
  meter: Meter
}

const EditMeter: React.FC<EditMeterProps> = ({
  onSuccess,
  meter: meterData,
  handleClose,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.meterDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isSubmitting, setIsSubmitting] = useState(false)

  const onSubmit = async (formData: FormValues) => {
    if (isSubmitting) return
    setIsSubmitting(true)

    try {
      if (
        formData.gridConnectionSize &&
        Number.isNaN(Number(formData.gridConnectionSize))
      ) {
        feedbackMessage(
          {
            title: t('edit.onSubmit.error.gridConnectionSizeNotANumber.title'),
            description: t(
              'edit.onSubmit.error.gridConnectionSizerNotANumber.description',
            ),
          },
          'error',
        )
        setIsSubmitting(false)
        return
      }

      if (
        formData.meterOperator &&
        Number.isNaN(Number(formData.meterOperator))
      ) {
        feedbackMessage(
          {
            title: t('edit.onSubmit.error.meterOperatorNotANumber.title'),
            description: t(
              'edit.onSubmit.error.meterOperatorNotANumber.description',
            ),
          },
          'error',
        )
        setIsSubmitting(false)
        return
      }

      if (formData.dcda && Number.isNaN(Number(formData.dcda))) {
        feedbackMessage(
          {
            title: t('edit.onSubmit.error.dcdaNotANumber.title'),
            description: t('edit.onSubmit.error.dcdaNotANumber.description'),
          },
          'error',
        )
        setIsSubmitting(false)
        return
      }

      const parsedCompany = formData.company && JSON.parse(formData.company)

      const { response, error } = await fetchData({
        method: 'PUT',
        url: `/core/private/consumer-meter/consumer/${meterData.id}`,
        body: {
          meterId: meterData.id,
          companyId: parsedCompany.id,
          name: formData.name,
          address: {
            id: meterData.addressId,
            ...formData.address,
            country: formData?.address?.country,
            region: formData.address?.region || null,
            addressLine2: formData.address?.addressLine2 || null,
          },
          existingSupplier: formData.existingSupplier || null,
          mpan: formData?.mpan || null,
          gridConnectionSize: formData?.gridConnectionSize || null,
          meterOperator: formData?.meterOperator || null,
          dataCollectorDataAggregator: formData?.dcda || null,
          profileClass: formData?.profileClass
            ? parseFloat(`${formData?.profileClass}`)
            : null,
        },
      })

      if (error && !response) throw error

      feedbackMessage(
        {
          title: t('edit.onSubmit.success.title', { meterName: formData.name }),
        },
        'success',
      )

      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerMeter',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes('The company') &&
            message.includes('must be active')
          ) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerMeter.SERVER_ERROR.companyMustBeActive',
              ),
              type: 'error',
            }
          }

          if (
            message.includes('consumerMeter.getUnderOfferOrActiveTenders()')
          ) {
            const [_, consumerTenders] = message.split('The tenders are: ')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.consumerMeter.SERVER_ERROR.METER_HAS_ACTIVE_TENDERS.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.consumerMeter.SERVER_ERROR.METER_HAS_ACTIVE_TENDERS.description',
              )}\n${consumerTenders}.`,
              type: 'error',
              duration: 8000,
            }
          }

          return undefined
        },
      })
    } finally {
      setIsSubmitting(false)
    }
  }

  const meterEdit = useMemo((): FormValues | undefined => {
    if (!meterData) return undefined

    return {
      company: parseCompanyToSelect(meterData.company).value,
      name: meterData.name,
      address: {
        addressLine1: meterData.address?.addressLine1,
        addressLine2: meterData.address?.addressLine2,
        postalCode: meterData.address?.postalCode,
        region: meterData.address?.region,
        locality: meterData.address?.locality,
        country: meterData.address.country,
      },
      existingSupplier: meterData?.existingSupplier,
      mpan: meterData?.mpan,
      gridConnectionSize: meterData?.gridConnectionSize || '',
      meterOperator: meterData?.meterOperator || '',
      dcda: meterData?.dataCollectorDataAggregator || '',
      profileClass: meterData?.profileClass,
    }
  }, [meterData])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5">
        <h1 className="text-xl font-bold text-ppa/title">{t('edit.title')}</h1>

        {isOpen && (
          <MeterForm
            onSubmit={onSubmit as any}
            isLoading={isSubmitting}
            defaultValues={meterEdit}
            onCancel={handleClose}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default EditMeter
