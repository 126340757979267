import React, { useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import {
  ChevronLeftIcon,
  BanIcon,
  CheckIcon,
  XCircleIcon,
  BadgeCheckIcon,
  TrashIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPAGetDetails from '../../../../hooks/use-ppa/use-ppa-get-details'
import usePPA from '../../../../hooks/use-ppa'
import useUser from '../../../../hooks/use-user'

import { container, breakPointsContainer } from '../../../../styles'

import { ReactComponent as Spinner } from '../../../../assets/spinner/spinner.svg'
import { ReactComponent as MemberIcon } from './assets/memberIcon.svg'
import { ReactComponent as UserIcon } from './assets/UserIcon.svg'

import { Member, SupplierWithCompanyAndProfile } from '../../../../types'

import { parseSnippetAddress } from '../../../../utils'
import { isFeatureEnabled } from '../../../../utils/features'

import UserPill from '../../components/user-pill'
import Dropdown from '../../../../components/atoms/dropdown'
import DefList, { RowKeys } from '../../../../components/atoms/def-list'
import Button from '../../../../components/atoms/button'
import Table, { TableConfigProps } from '../../../../components/atoms/table'

import { feedbackMessage } from '../../../../components/atoms/feedback'

import ChangeMemberTypeDrawer from '../components/change-member-type'

const rowKeysMember: TableConfigProps[] = [
  {
    keyName: 'name',
    renderCustomEl: (member: Member) => {
      if (member.firstName || member.lastName || member.email) {
        return (
          <div className="flex">
            <UserIcon />
            <div className="ml-6 font-normal">
              {member.firstName} {member.lastName}
              <div className="font-light">{member?.email}</div>
            </div>
          </div>
        )
      }
      return undefined
    },
  },
  {
    keyName: 'phoneNumber',
  },
]

export type SupplierDetails = SupplierWithCompanyAndProfile & {
  members: Member[]
}

const SupplierDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.suppliers.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { actAs, user } = useUser()
  const { fetchData, throwFeedbackError } = usePPA()

  const navigate = useNavigate()

  const { supplierId } = useParams<{ supplierId?: string }>()

  const [isMemberTypeDrawerOpen, setIsMemberTypeDrawerOpen] =
    useState<boolean>(false)
  const [selectedUserId, setSelectedUserId] = useState<string | null>(null)

  const {
    isLoading,
    refetch,
    throwFeedbackError: detailsThrowFeedbackError,
    data,
    error,
  } = usePPAGetDetails<SupplierDetails>({
    dataKey: 'supplier',
    path: `/core/private/supplier/details/${supplierId}`,
  })

  const supplierDetails = useMemo((): SupplierDetails | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const handleBlock = async () => {
    if (!supplierDetails || supplierDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/supplier/disable/${supplierDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplier',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleDelete = async () => {
    if (!supplierDetails || !supplierDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'DELETE',
        url: `/core/private/supplier/${supplierDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      navigate('/suppliers')
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplier',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Failed to delete user in auth0')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.delete',
              ),
              type: 'error',
            }
          }

          return undefined
        },
        PAYLOAD_ERROR: ({ message }) => {
          if (
            message.includes('Supplier must be blocked in order to be deleted')
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.supplier.PAYLOAD_ERROR.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.supplier.PAYLOAD_ERROR.reason.DELETE',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleUnblock = async () => {
    if (!supplierDetails || !supplierDetails.blocked) return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/supplier/enable/${supplierDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplier',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleAcceptOnboarding = async () => {
    if (!supplierDetails || supplierDetails.status !== 'REVIEW') return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/supplier/approve/${supplierDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplier',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleRejectOnboarding = async () => {
    if (!supplierDetails || supplierDetails.status !== 'REVIEW') return

    try {
      const { error: respError, response } = await fetchData<{
        message: string
      }>({
        method: 'PATCH',
        url: `/core/private/supplier/reject/${supplierDetails.id}`,
      })

      if (respError || !response) {
        throw respError
      }

      feedbackMessage(
        { title: tUtils('feedbackMessage.success.title') },
        'success',
      )
      refetch()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplier',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Auth0 update failed')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.update',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    }
  }

  const handleActAs = () => {
    if (supplierDetails === undefined) return
    if (supplierDetails.status !== 'ACTIVE') {
      feedbackMessage(
        {
          title: 'You cannot do this action.',
          description:
            'Users that are not active cannot being used in "acting as".',
        },
        'warn',
      )
      return
    }

    const { id, name, members } = supplierDetails

    const filteredMembers = members.filter(
      (member) => member.memberType === 'OWNER',
    )
    if (filteredMembers.length === 0) {
      throw new Error('Something went wrong, member OWNER could not be found.')
    }

    actAs({
      appMetadata: {
        id,
        memberId: filteredMembers[0].id,
        memberType: filteredMembers[0].memberType,
        name,
        userType: 'SUPPLIER',
        status: supplierDetails.status,
        userId: supplierDetails.userId,
        brokerContextName: supplierDetails.brokerContextName,
      },
    })

    navigate('/tenders')
  }

  useEffect(() => {
    if (error) {
      detailsThrowFeedbackError({ err: error })
    }
  }, [error])

  useEffect(() => {
    if (!supplierId) {
      navigate('/suppliers')
    }
  }, [supplierId])

  const rowKeys = useMemo((): RowKeys[] => {
    const result: RowKeys[] = [
      {
        keyName: 'company.name',
        title: t('defList.company.name'),
        renderCustomEl: (supplier: SupplierDetails) => {
          return supplier.company.name
        },
      },
      {
        keyName: 'company.number',
        title: t('defList.company.number'),
        renderCustomEl: (supplier: SupplierDetails) => {
          return supplier.company.number
        },
      },
      {
        keyName: 'company.address',
        title: t('defList.company.address'),
        renderCustomEl: (supplier: SupplierDetails) => {
          return parseSnippetAddress(supplier.company.address)
        },
      },
      {
        keyName: 'company.billingAddress',
        title: t('defList.company.billingAddress'),
        renderCustomEl: (supplier: SupplierDetails) => {
          return parseSnippetAddress(supplier.company?.billingAddress)
        },
      },
    ]

    if (user.appMetadata.brokerContextName === 'PPAYA') {
      result.push({
        keyName: 'company.contactNumber',
        title: t('defList.company.contactNumber'),
        renderCustomEl: (supplier: SupplierDetails) => {
          const filteredMembers = supplier.members.filter(
            (item) => item.memberType === 'OWNER',
          )

          if (filteredMembers.length === 0) return undefined
          return filteredMembers[0]?.phoneNumber
        },
      })
    }

    return result
  }, [supplierDetails])

  const rowKeysProfile = useMemo((): RowKeys[] => {
    return [
      {
        keyName: 'description',
        title: t('defListProfile.description'),
        renderCustomEl: (item: SupplierWithCompanyAndProfile) => {
          return item?.profile?.description
        },
      },
      {
        keyName: 'totalCapacity',
        title: t('defListProfile.totalCapacity'),

        renderCustomEl: (item: SupplierWithCompanyAndProfile) => {
          return `${item?.profile?.totalCapacity || 0} MW`
        },
      },
      {
        keyName: 'backing',
        title: t('defListProfile.backing.label'),
        renderCustomEl: (item: SupplierWithCompanyAndProfile) => {
          const backingKeys = item?.profile?.backing
          if (backingKeys && backingKeys.length > 0) {
            const backingTranslations = backingKeys.map((key: any) =>
              t(`defListProfile.backing.options.${key}`),
            )
            return backingTranslations.join(', ')
          }
          return ''
        },
      },
      {
        keyName: 'lenders',
        title: t('defListProfile.lenders'),
        renderCustomEl: (item: SupplierWithCompanyAndProfile) => {
          if (!item) return undefined
          return item.profile?.lenders.join(', ')
        },
      },
      {
        keyName: 'other',
        title: t('defListProfile.other'),
        renderCustomEl: (item: SupplierWithCompanyAndProfile) => {
          if (!item) return 'N/A'
          return item.profile?.other
        },
      },
    ]
  }, [supplierDetails])

  return (
    <section
      className={classNames(
        container,
        breakPointsContainer,
        'items-center pt-12 relative gap-y-8',
      )}
    >
      <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
        <Button
          variant="text"
          icon={<ChevronLeftIcon />}
          onClick={() => navigate('/suppliers')}
        >
          {t('backButton')}
        </Button>
      </div>

      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoading && error && (
        <div>
          <strong>{t('error.title')}</strong>

          <Button variant="primary" onClick={() => refetch()}>
            {t('error.button')}
          </Button>
        </div>
      )}

      {!isLoading && !error && supplierDetails && (
        <>
          <div className="flex items-center justify-between w-full">
            <div className="flex flex-1 items-center justify-start mt-5 gap-x-3">
              <h1 className="text-3xl font-semibold text-ppa/title mr-4">
                {supplierDetails.name}
              </h1>
              <UserPill
                status={supplierDetails.status}
                blocked={supplierDetails.blocked}
              />
              {supplierDetails.blocked && (
                <div className="flex items-center gap-x-2 font-semibold text-xs">
                  <span>{t('userBlocked')}</span>
                  <BanIcon className="w-5 h-5 text-ppa/redError" />
                </div>
              )}
            </div>
            {user.appMetadata.brokerContextName === 'PPAYA' && (
              <Dropdown
                content={
                  <div className="flex flex-col items-start">
                    {supplierDetails.blocked ? (
                      <>
                        <Button
                          variant="text"
                          icon={<BadgeCheckIcon />}
                          onClick={handleUnblock}
                          dropdownLabel
                          data-testid="button-unblock"
                        >
                          {t('actionsDropdown.unblock')}
                        </Button>
                        <Button
                          variant="text"
                          icon={<TrashIcon />}
                          onClick={handleDelete}
                          dropdownLabel
                          data-testid="button-delete"
                        >
                          {t('actionsDropdown.delete.button')}
                        </Button>
                      </>
                    ) : (
                      <Button
                        variant="text"
                        icon={<BanIcon />}
                        onClick={handleBlock}
                        dropdownLabel
                        data-testid="button-block"
                      >
                        {t('actionsDropdown.block')}
                      </Button>
                    )}
                    {supplierDetails.status === 'REVIEW' && (
                      <>
                        <Button
                          variant="text"
                          icon={<CheckIcon />}
                          onClick={handleAcceptOnboarding}
                          data-testid="button-accept-onboarding"
                        >
                          {t('actionsDropdown.accept')}
                        </Button>
                        <Button
                          variant="text"
                          icon={<XCircleIcon />}
                          onClick={handleRejectOnboarding}
                          data-testid="button-reject-onboarding"
                        >
                          {t('actionsDropdown.reject')}
                        </Button>
                      </>
                    )}
                    {supplierDetails.status === 'ACTIVE' && (
                      <Button
                        variant="text"
                        icon={<UserIcon />}
                        onClick={handleActAs}
                        dropdownLabel
                        data-testid="button-act-as"
                      >
                        {t('actionsDropdown.actAs')}
                      </Button>
                    )}
                  </div>
                }
              />
            )}
          </div>
          <DefList rowKeys={rowKeys} data={data} />

          {isFeatureEnabled('supplierProfileImage') &&
            supplierDetails.profile?.logo && (
              <div>
                <img src={supplierDetails.profile?.logo} alt="profile" />
              </div>
            )}
          <DefList rowKeys={rowKeysProfile} data={data} />

          {user.appMetadata.brokerContextName === 'PPAYA' &&
            supplierDetails.members && (
              <div className="flex flex-col mr-auto gap-y-2 text-ppa/title text-lg font-medium">
                <h2 className="mt-2 mb-2.5">
                  {t('usersInOrganisationDetails.title')}
                </h2>

                <Table
                  headers={[
                    t('table.headers.userDetails'),
                    t('table.headers.phoneNumber'),
                    t('table.headers.memberType'),
                    isFeatureEnabled('memberStatusAdmin') &&
                      t('table.headers.status'),
                  ]}
                  rowKeys={[
                    ...rowKeysMember,
                    {
                      keyName: 'memberType',
                      renderCustomEl: (member: Member) =>
                        t(`table.body.memberType.${member.memberType}`),
                    },
                    {
                      keyName: 'action',
                      renderCustomEl: (item) => {
                        return (
                          <Dropdown
                            orientation="bottom-left"
                            content={
                              <div className="flex flex-col gap-y-1.5 text-ppa/title text-xs font-normal">
                                <button
                                  type="button"
                                  onClick={() => handleDelete()}
                                  className="flex items-center"
                                >
                                  <TrashIcon className="w-4 h-4 mr-2.5" />
                                  {t('table.body.actions.deleteUser')}
                                </button>
                                <button
                                  type="button"
                                  onClick={() => {
                                    setSelectedUserId(item.userId)
                                    setIsMemberTypeDrawerOpen(true)
                                  }}
                                  className="flex items-center"
                                >
                                  <MemberIcon className="w-4 h-4 mr-2.5" />
                                  {t('table.body.actions.changeMemberType')}
                                </button>
                              </div>
                            }
                          />
                        )
                      },
                      customColumnClass: 'py-6',
                    },
                  ]}
                  isLoading={isLoading}
                  data={supplierDetails.members}
                />
              </div>
            )}
          <ChangeMemberTypeDrawer
            userType="SUPPLIER"
            userId={selectedUserId}
            isOpen={isMemberTypeDrawerOpen}
            onSuccess={() => {
              setIsMemberTypeDrawerOpen(false)
              refetch()
            }}
            handleClose={() => setIsMemberTypeDrawerOpen(false)}
          />
        </>
      )}
    </section>
  )
}

export default SupplierDetails
