import React, { useState, useMemo, useEffect, useCallback } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import usePPAGetList, {
  Filters,
} from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import { ReactComponent as ReviewIcon } from '../../../../assets/reviewIcon.svg'

import Table, {
  TableConfigProps,
} from '../../../../../../components/atoms/table'
import TenderPill from '../../../../components/tender-pill'
import Button from '../../../../../../components/atoms/button'
import Message from '../../../../../../components/atoms/message'

import CreateTender from '../create-tender'

import SearchFilterSite from '../search-filter-sites'

import {
  ConsumerTender,
  SupplierTenderInvitation,
  Site,
} from '../../../../../../types'
import CreateOrUpdateBid from '../../../sleeved-tenders/components/bid-create-update'

interface SitesTablesProps {
  tenderData: ConsumerTender
}

const SitesTables: React.FC<SitesTablesProps> = ({ tenderData }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.sitesTable',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { tenderId } = useParams<{ tenderId: string }>()

  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>([])
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [selectedSite, setSelectedSite] = useState<Site | null>(null)
  const [selectedTenderInvitation, setSelectedTenderInvitation] =
    useState<SupplierTenderInvitation | null>(null)

  const { isLoading, loadMore, throwFeedbackError, data, error, reset } =
    usePPAGetList<SupplierTenderInvitation[]>({
      dataKey: 'supplierTenderInvitations',
      path: `/core/private/supplier-tender/supplier/list/accepted-invites/${tenderId}`,
      pagination: {
        offset: 0,
        limit: 10,
      },
    })

  const {
    applyFilters,
    isLoading: isLoadingEligibleSites,
    loadMore: loadMoreEligibleSites,
    throwFeedbackError: throwFeedbackErrorEligibleSites,
    data: dataEligibleSites,
    error: errorEligibleSites,
    reset: resetEligibleSites,
  } = usePPAGetList<Site[]>({
    dataKey: 'sites',
    path: `/core/private/site/by-supplier/eligible-sites/${tenderId}`,
    pagination: {
      offset: 0,
      limit: 10,
    },
  })

  const interestedSites = useMemo(():
    | SupplierTenderInvitation[]
    | undefined => {
    if (isLoading || error || !data) return undefined

    return data.map((invitation: any) => invitation)
  }, [data, isLoading, error])

  const eligibleSites = useMemo(() => {
    if (isLoadingEligibleSites || errorEligibleSites || !dataEligibleSites)
      return undefined

    return dataEligibleSites
  }, [dataEligibleSites, isLoadingEligibleSites, errorEligibleSites])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoadingEligibleSites || errorEligibleSites || !dataEligibleSites)
        return

      try {
        await applyFilters(newFilters)
        setFilters(newFilters)
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [
      isLoadingEligibleSites,
      errorEligibleSites,
      dataEligibleSites,
      applyFilters,
    ],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter((item) => item.field === 'search')
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  const [isBidDrawerOpen, setIsBidDrawerOpen] = useState(false)

  const handleCloseDrawer = () => {
    setSelectedTenderInvitation(null)
    setIsBidDrawerOpen(false)
  }
  const handleOnSuccessDrawer = () => {
    setSelectedTenderInvitation(null)
    setIsBidDrawerOpen(false)
    reset()
  }

  const rowKeysShownInterestSites: TableConfigProps[] = [
    {
      keyName: 'generator',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        if (!item.supplierTender.generator) return undefined
        return item.supplierTender.generator.name
      },
    },
    {
      keyName: 'site',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        if (!item.supplierTender.site) return undefined
        return item.supplierTender.site.name
      },
    },
    {
      keyName: 'mpan',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        if (!item.supplierTender.site || !item.supplierTender.site.mpan)
          return undefined
        return item.supplierTender.site.mpan
      },
    },
    {
      keyName: 'technology',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        if (!item.supplierTender.site) return undefined
        return tUtils(
          `technology.${item.supplierTender.site.technology}.fullName`,
        )
      },
    },
    {
      keyName: 'capacity',
      suffix: 'MW',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        if (!item.supplierTender.site) return undefined
        return item.supplierTender.site.capacity
      },
    },
    {
      keyName: 'status',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        return <TenderPill status={item.supplierTender.status} />
      },
    },
    {
      keyName: 'action',
      renderCustomEl: (item: SupplierTenderInvitation) => {
        const handleBidClick = (event: React.MouseEvent) => {
          event.stopPropagation()
          setSelectedTenderInvitation(item)
          setIsBidDrawerOpen(true)
        }

        const handleBiddedClick = (event: React.MouseEvent) => {
          event.stopPropagation()
          navigate(`/sleevedTenders/${item.supplierTender.id}`)
        }

        return (
          <div>
            {item.supplierTenderBid ? (
              <Button
                variant="secondary"
                icon={<ReviewIcon className="w-3 h-3" />}
                onClick={handleBiddedClick}
                smallLabel
              >
                {t('buttons.bidded')}
              </Button>
            ) : (
              <Button variant="primary" onClick={handleBidClick} smallLabel>
                <span className="px-6">{t('buttons.bid')}</span>
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  const rowKeysEligibleSitesForTender: TableConfigProps[] = [
    {
      keyName: 'generator',
      renderCustomEl: (item: ConsumerTender['site']) => {
        if (!item?.generator) return undefined
        return item.generator.name
      },
    },
    {
      keyName: 'meter',
      renderCustomEl: (item: ConsumerTender['site']) => {
        return item?.name
      },
    },
    {
      keyName: 'mpan',
      renderCustomEl: (item: ConsumerTender['site']) => {
        if (!item?.mpan) return undefined
        return item.mpan
      },
    },
    {
      keyName: 'technology',
      renderCustomEl: (item: ConsumerTender['site']) => {
        if (!item?.technology) return undefined
        return tUtils(`technology.${item.technology}.fullName`)
      },
    },
    {
      keyName: 'capacity',
      suffix: 'MW',
      renderCustomEl: (item: ConsumerTender['site']) => {
        if (!item?.capacity) return undefined
        return item.capacity
      },
    },
    {
      keyName: 'action',
      renderCustomEl: (item: ConsumerTender['site']) => {
        if (!item) return undefined
        return (
          <div>
            {item.supplierTenders && item.supplierTenders.length ? (
              <Button
                variant="senary"
                onClick={() =>
                  item.supplierTenders &&
                  navigate(`/sleevedTenders/${item.supplierTenders[0].id}`)
                }
                smallLabel
              >
                <span className="flex items-center px-2.5">
                  {t('buttons.review')} <ReviewIcon className="w-6 h-6 pl-2" />
                </span>
              </Button>
            ) : (
              <Button
                variant="primary"
                onClick={() => {
                  setSelectedSite(item)
                  setIsModalOpen(true)
                }}
                smallLabel
              >
                {t('buttons.showInterest')}
              </Button>
            )}
          </div>
        )
      },
    },
  ]

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  useEffect(() => {
    if (errorEligibleSites) {
      throwFeedbackErrorEligibleSites({
        err: errorEligibleSites,
      })
    }
  }, [errorEligibleSites])

  return (
    <div className="flex flex-col gap-y-8 mt-10">
      <h2 className="text-2xl text-ppa/title font-semibold">
        {t('shownInterestTitle')}
      </h2>
      {interestedSites ? (
        <div className="relative z-10">
          <Table
            headers={[
              t('headers.generator'),
              t('headers.site'),
              t('headers.mpan'),
              t('headers.technology'),
              t('headers.capacity'),
              t('headers.status'),
              '',
            ]}
            rowKeys={rowKeysShownInterestSites}
            isLoading={isLoading}
            loadMore={loadMore}
            data={interestedSites}
            onRowClick={(site) =>
              navigate(`/sleevedTenders/${site.supplierTender.id}`)
            }
          />
        </div>
      ) : (
        <span className="text-ppa/title text-base">{t('noSites')}</span>
      )}

      <div className="flex flex-col gap-y-5">
        <h2 className="text-2xl text-ppa/title font-semibold">
          {t('eligibleSitesForTenderTitle')}
        </h2>
        <Message variant="info" size="text-sm">
          {t('infoMessage')}
        </Message>
        <SearchFilterSite
          onSubmit={fetchWithFilters}
          cleanFilters={handleCleanFilters}
        />
        <div className="relative z-10">
          <Table
            headers={[
              t('headers.generator'),
              t('headers.site'),
              t('headers.mpan'),
              t('headers.technology'),
              t('headers.capacity'),
              '',
            ]}
            rowKeys={rowKeysEligibleSitesForTender}
            isLoading={isLoadingEligibleSites}
            loadMore={loadMoreEligibleSites}
            data={eligibleSites}
          />
        </div>
      </div>
      {selectedSite && (
        <CreateTender
          tenderData={tenderData}
          siteData={selectedSite}
          isModalOpen={isModalOpen}
          closeModal={() => setIsModalOpen(false)}
          reset={() => {
            resetEligibleSites()
            reset()
          }}
        />
      )}
      {selectedTenderInvitation && (
        <CreateOrUpdateBid
          isDrawerOpen={isBidDrawerOpen}
          tenderInvitation={selectedTenderInvitation}
          onSuccess={handleOnSuccessDrawer}
          closeDrawer={handleCloseDrawer}
        />
      )}
    </div>
  )
}

export default SitesTables
