import { useEffect, useRef, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import classNames from 'classnames'

import { PlusIcon } from '@heroicons/react/solid'

import useRegisterCompany from '../../../../../../hooks/use-register-company'

import {
  getCountryOptionsData,
  validateDecimalNumbers,
} from '../../../../../../utils'

import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'
import InputGroup from '../../../../../../components/molecules/input-group'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import RequiredField from '../../../../../../components/atoms/required-form-field'

import SelectComponent, {
  SelectOptions,
} from '../../../../../../components/atoms/select'

import SelectExistingCompanies, {
  SelectCompaniesRef,
} from '../../../../../../components/molecules/company-select'

interface Address {
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  region?: string
  locality?: string
  country?: string
}

export type FormValues = {
  company?: string
  name?: string
  address: Address
  existingSupplier?: string
  mpan?: string
  gridConnectionSize?: string | number
  meterOperator?: string | number
  dcda?: string
  profileClass?: string | number
}

export interface MeterFormProps {
  onCancel: () => void
  onSubmit: (data: any) => void
  defaultValues?: FormValues
  isLoading?: boolean
}

const formDefaultValues: FormValues = {
  company: undefined,
  name: '',
  address: {
    addressLine1: '',
    addressLine2: '',
    postalCode: '',
    region: '',
    locality: '',
  },
  existingSupplier: '',
  mpan: '',
  gridConnectionSize: '',
  meterOperator: '',
  dcda: '',
  profileClass: '',
}

const MeterForm: React.FC<MeterFormProps> = ({
  onSubmit,
  onCancel,
  defaultValues,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.meterDrawer.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const selectCompaniesRef = useRef<SelectCompaniesRef>(null)
  const { drawer, openDrawer } = useRegisterCompany({
    onSuccess: (company) => {
      if (selectCompaniesRef.current?.refetch)
        selectCompaniesRef.current.refetch()

      feedbackMessage(
        {
          title: t('registerCompany.onSuccessMessage.title'),
          description: t('registerCompany.onSuccessMessage.description', {
            companyName: company.name,
          }),
        },
        'success',
      )
    },
  })

  const countryOptions = useMemo(() => getCountryOptionsData(), [])

  const mappedCountryOptions = useMemo(() => {
    return (
      countryOptions.map((country) => ({
        value: country.alpha3,
        label: country.country,
      })) || []
    )
  }, [countryOptions])

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  useEffect(() => {
    reset(defaultValues || formDefaultValues)
  }, [defaultValues])

  return (
    <>
      <form
        onSubmit={handleSubmit(onSubmit)}
        className="flex flex-col mx-px gap-y-5 min-w-[500px] max-w-[510px]"
      >
        <Controller
          name="company"
          control={control}
          rules={{ required: { value: true, message: t('company.required') } }}
          render={({ field: { ref: _, ...props } }) => (
            <div className="flex flex-col gap-x-2">
              <InputGroup
                label={t('company.title')}
                infoLabel={t('company.infoLabel')}
                error={errors.company?.message}
                required
              >
                <SelectExistingCompanies
                  {...props}
                  ref={selectCompaniesRef}
                  placeholder={t('company.placeholder')}
                />
              </InputGroup>
              <div className="flex justify-end">
                <Button
                  variant="text"
                  type="button"
                  icon={<PlusIcon />}
                  onClick={openDrawer}
                >
                  {t('addNewCompany')}
                </Button>
              </div>
            </div>
          )}
        />
        <Controller
          name="name"
          control={control}
          rules={{
            required: { value: true, message: t('name.required') },
            minLength: {
              value: 3,
              message: t('name.minLength'),
            },
          }}
          render={({ field: props }) => (
            <InputGroup
              label={t('name.title')}
              error={errors.name?.message}
              required
            >
              <Input
                {...props}
                placeholder={t('name.placeholder')}
                error={errors.name?.message}
              />
            </InputGroup>
          )}
        />

        <InputGroup label={t('addressSectionTitle')} required>
          <div
            className={classNames(
              'flex flex-col',
              'mt-3 gap-y-3',
              'border-t border-ppa/grayBorder',
              'md:border-t-0 md:border-l md:pl-5',
            )}
          >
            <Controller
              name="address.addressLine1"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('addressLine1.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('addressLine1.title')}
                  error={errors.address?.addressLine1?.message as any}
                  required
                >
                  <Input
                    {...props}
                    placeholder={t('addressLine1.placeholder')}
                    error={errors.address?.addressLine1?.message}
                    attribute="address-line1"
                  />
                </InputGroup>
              )}
            />
            <Controller
              name="address.addressLine2"
              control={control}
              render={({ field: props }) => (
                <InputGroup label={t('addressLine2.title')}>
                  <Input
                    {...props}
                    placeholder={t('addressLine2.placeholder')}
                    attribute="address-line2"
                  />
                </InputGroup>
              )}
            />
            <div className="flex flex-col gap-3 md:flex-row">
              <Controller
                name="address.postalCode"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('postalCode.required'),
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('postalCode.title')}
                    error={errors.address?.postalCode?.message as any}
                    required
                  >
                    <Input
                      {...props}
                      placeholder={t('postalCode.placeholder')}
                      error={errors.address?.postalCode?.message}
                      attribute="postal-code"
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="address.locality"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('locality.required'),
                  },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('locality.title')}
                    error={errors.address?.locality?.message as any}
                    required
                  >
                    <Input
                      {...props}
                      placeholder={t('locality.placeholder')}
                      error={errors.address?.locality?.message}
                    />
                  </InputGroup>
                )}
              />
            </div>
            <div className="flex flex-col gap-3 md:flex-row">
              <Controller
                name="address.region"
                control={control}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('region.title')}
                    error={errors.address?.region?.message as any}
                  >
                    <Input
                      {...props}
                      placeholder={t('region.placeholder')}
                      error={errors.address?.region?.message}
                      attribute="address-level1"
                    />
                  </InputGroup>
                )}
              />
              <Controller
                name="address.country"
                control={control}
                rules={{
                  required: {
                    value: true,
                    message: t('country.required'),
                  },
                }}
                render={({ field: { ref: _, ...props } }) => (
                  <InputGroup
                    label={t('country.title')}
                    error={errors?.address?.country?.message as any}
                    required
                  >
                    <SelectComponent
                      {...props}
                      type="single"
                      options={mappedCountryOptions}
                      placeholder={t('country.placeholder')}
                      error={errors?.address?.country?.message}
                      testId="select-country"
                    />
                  </InputGroup>
                )}
              />
            </div>
          </div>
        </InputGroup>

        <Controller
          name="mpan"
          control={control}
          rules={{
            maxLength: { value: 13, message: t('mpan.minLength') },
            minLength: { value: 13, message: t('mpan.maxLength') },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('mpan.title')}
              error={errors.mpan?.message}
              infoLabel={t('mpan.infoLabel')}
            >
              <Input
                type="text"
                {...props}
                placeholder={t('mpan.placeholder')}
                error={errors.mpan?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="profileClass"
          control={control}
          rules={{
            min: { value: 1, message: t('profileClass.infoMessage') },
            max: { value: 8, message: t('profileClass.infoMessage') },
            validate: {
              isInteger: (value) =>
                Number.isInteger(Number(value)) ||
                t('profileClass.mustBeInteger'),
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('profileClass.title')}
              error={errors.profileClass?.message}
            >
              <Input
                type="number"
                {...props}
                placeholder="0"
                error={errors.profileClass?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="gridConnectionSize"
          control={control}
          rules={{
            validate: {
              isValidDecimal: validateDecimalNumbers,
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('gridConnectionSize.title')}
              error={errors.gridConnectionSize?.message}
            >
              <Input
                type="text"
                {...props}
                placeholder="0.0"
                suffix="kVA"
                error={errors.gridConnectionSize?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="meterOperator"
          control={control}
          rules={{
            validate: {
              isValidDecimal: validateDecimalNumbers,
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup
              label={t('meterOperator.title')}
              error={errors.meterOperator?.message}
            >
              <Input
                type="text"
                {...props}
                placeholder="0.0"
                error={errors.meterOperator?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="dcda"
          control={control}
          rules={{
            validate: {
              isValidDecimal: validateDecimalNumbers,
            },
          }}
          render={({ field: { ...props } }) => (
            <InputGroup label={t('dcda.title')} error={errors.dcda?.message}>
              <Input
                type="text"
                {...props}
                placeholder={t('dcda.placeholder')}
                error={errors.dcda?.message}
              />
            </InputGroup>
          )}
        />

        <Controller
          name="existingSupplier"
          control={control}
          render={({ field: props }) => (
            <InputGroup
              label={t('existingSupplier.title')}
              error={errors.existingSupplier?.message}
            >
              <Input
                {...props}
                placeholder={t('existingSupplier.placeholder')}
                error={errors.existingSupplier?.message}
              />
            </InputGroup>
          )}
        />

        <div>
          <RequiredField />
        </div>

        <div className="w-full flex items-center justify-end gap-x-4">
          <div className="max-w-[150px] w-full">
            <Button
              type="button"
              variant="quaternary"
              fullWidth
              onClick={onCancel}
            >
              {tUtils('form.buttons.cancel')}
            </Button>
          </div>
          <div className="max-w-[150px] w-full">
            <Button
              type="submit"
              variant="primary"
              fullWidth
              loading={isLoading}
            >
              {tUtils('form.buttons.submit')}
            </Button>
          </div>
        </div>
      </form>
      {drawer}
    </>
  )
}

export default MeterForm
