import { useEffect, useCallback, useState, useMemo } from 'react'
import { useNavigate } from 'react-router-dom'
import { useTranslation } from 'react-i18next'

import { PlusIcon, CheckIcon } from '@heroicons/react/solid'

import usePPAGetList, {
  Filters,
} from '../../../../hooks/use-ppa/use-ppa-get-list'

import { parseSnippetAddress, capitalizeText } from '../../../../utils'

import CreateSite from './components/create-site'
import SearchFilterSites from './components/search-filter-site'

import Table from '../../../../components/atoms/table'
import Button from '../../../../components/atoms/button'
import PageTitle from '../../../../components/atoms/page-title'

import { Site as SiteDTO, Company } from '../../../../types'

export type Site = SiteDTO & { company: Company }

const Sites: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const navigate = useNavigate()

  const [filters, setFilters] = useState<Filters>([])

  const { isLoading, loadMore, applyFilters, throwFeedbackError, data, error } =
    usePPAGetList<Site>({
      dataKey: 'sites',
      path: '/core/private/site/list',
      filters,
    })

  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const sites = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const fetchWithFilters = useCallback(
    async (newFilters: Filters): Promise<void> => {
      if (isLoading || error || !data) return

      try {
        await applyFilters(newFilters)
        setFilters(newFilters)
      } catch (err) {
        throwFeedbackError({
          err,
        })
      }
    },
    [isLoading, error, data, applyFilters],
  )

  const handleCleanFilters = async () => {
    const cleanedFilters = filters.filter((item) => item.field === 'search')
    setFilters(cleanedFilters)
    applyFilters(cleanedFilters)
  }

  useEffect(() => {
    if (error) {
      throwFeedbackError({
        err: error,
      })
    }
  }, [error])

  const onSuccess = async () => {
    setIsDrawerOpen(false)
    try {
      await applyFilters(filters)
    } catch (err) {
      throwFeedbackError({
        err,
      })
    }
  }

  return (
    <div className="flex flex-col gap-y-8">
      <div className="flex items-center">
        <PageTitle title={t('title.text')} />
        <div className="ml-7">
          <Button
            variant="primary"
            icon={<PlusIcon />}
            onClick={() => setIsDrawerOpen(true)}
          >
            {t('title.button')}
          </Button>
        </div>
      </div>

      <div className="flex flex-col gap-y-5">
        <SearchFilterSites
          onSubmit={fetchWithFilters}
          cleanFilters={handleCleanFilters}
        />

        <div className="relative z-20">
          <Table
            headers={[
              t('table.headers.site'),
              t('table.headers.mpan'),
              t('table.headers.msid'),
              t('table.headers.company'),
              t('table.headers.technology'),
              t('table.headers.capacity'),
              t('table.headers.voltage'),
              t('table.headers.rocBand'),
              // t('table.headers.hhd'),
            ]}
            rowKeys={[
              {
                keyName: 'name',
                renderCustomEl: (item: Site) => {
                  return (
                    <div className="flex flex-col">
                      <span className="font-light">{item.name}</span>
                      {item.address ? (
                        <span className="font-extralight text-ellipsis overflow-hidden w-full max-w-40">
                          {parseSnippetAddress(item?.address)}
                        </span>
                      ) : (
                        <span className="font-extralight">
                          {item?.location}
                        </span>
                      )}
                    </div>
                  )
                },
              },
              { keyName: 'mpan' },
              { keyName: 'msid' },
              { keyName: 'company.name' },
              {
                keyName: 'technology',
                renderCustomEl: (item: Site) => {
                  return capitalizeText(
                    tUtils(`technology.${item.technology}.abbreviation`),
                  )
                },
              },
              { keyName: 'capacity', suffix: 'MW' },
              { keyName: 'voltage', suffix: 'KV' },
              { keyName: 'rocBand' },
              // {
              //   keyName: 'hhdKey',
              //   renderCustomEl: (item: any) => {
              //     return item?.hhdKey ? (
              //       <CheckIcon className="w-4 h-4 stroke-lime-400" />
              //     ) : undefined
              //   },
              // },
            ]}
            onRowClick={(site) => navigate(`/sites/${site.id}`)}
            isLoading={isLoading}
            error={error}
            data={sites}
            loadMore={loadMore}
          />
        </div>
      </div>

      <CreateSite
        onSuccess={onSuccess}
        isOpen={isDrawerOpen}
        handleClose={() => setIsDrawerOpen(false)}
      />
    </div>
  )
}

export default Sites
