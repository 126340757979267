import React, { InputHTMLAttributes, forwardRef } from 'react'
import classNames from 'classnames'

import { defaultFocusAnimation, erroredFocusAnimation } from '../../../styles'

export interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  suffix?: string
  error?: string
  onChange?: (value?: any) => void
  [x: string]: any
  smallInput?: boolean
}

/**
 * @TODO Create configurations for handling number fields and currency fields
 */
const Input = forwardRef<any, InputProps>(
  (
    {
      suffix,
      type = 'text',
      error,
      onChange,
      value,
      disabledInput,
      smallInput = false,
      ...rest
    },
    ref,
  ) => {
    return (
      <div
        className={classNames(
          'flex rounded-md text-sm font-normal',
          disabledInput && 'bg-gray-100',
          !error ? defaultFocusAnimation : erroredFocusAnimation,
        )}
      >
        <input
          className={classNames(
            'w-full rounded-md self-center text-ppa/formText placeholder-ppa/placeholder placeholder:font-normal hover:appearance-none focus:appearance-none disabled:opacity-80 disabled:bg-gray-100',
            !smallInput ? 'py-1.5 px-3' : 'px-1 py-[0.5px]',
          )}
          type={type}
          onChange={onChange}
          value={value}
          {...rest}
          ref={ref}
        />
        {suffix && (
          <span
            className={classNames(
              'text-ppa/placeholder text-sm font-light mx-1.5 self-center',
            )}
          >
            {suffix}
          </span>
        )}
      </div>
    )
  },
)

export default Input
