import React, { useState, useMemo, forwardRef } from 'react'
import { countries } from 'country-data-list'
import classNames from 'classnames'

import { defaultFocusAnimation, erroredFocusAnimation } from '../../../styles'

export interface Country {
  name: string
  code: string
  dialCode: string
  flag: string
}

interface PhoneNumberInputProps {
  onChange?: (value: string) => void
  error?: string

  [x: string]: any
}

const PhoneNumberInput = forwardRef<any, PhoneNumberInputProps>(
  ({ onChange, value, error, disabledInput, ...rest }, ref) => {
    const countryOptions = useMemo(() => {
      const dialCodes = new Set<string>()

      const uniqueCountries = Object.values(countries)
        .map((country: any) => {
          const dialCode =
            country.countryCallingCodes && country.countryCallingCodes?.[0]
          if (!dialCode || dialCodes.has(dialCode)) return null
          dialCodes.add(dialCode)

          return {
            name: country.name,
            code: country.alpha2,
            dialCode,
            flag: country.emoji,
          }
        })
        .filter(Boolean)

      return uniqueCountries
    }, [])

    const defaultCountry = countryOptions.find(
      (country) => country?.code === 'GB',
    )

    const [selectedCountry, setSelectedCountry] = useState<Country | undefined>(
      () => {
        if (value) {
          const matchingCountry = countryOptions.find((country) =>
            value.startsWith(country?.dialCode),
          )
          return matchingCountry || defaultCountry || undefined
        }
        return defaultCountry || undefined
      },
    )

    const [phoneNumber, setPhoneNumber] = useState(() => {
      if (value && selectedCountry?.dialCode) {
        return value.replace(selectedCountry.dialCode, '').trim()
      }
      return ''
    })

    const handleCountryChange = (
      event: React.ChangeEvent<HTMLSelectElement>,
    ) => {
      const selectedDialCode = event.target.value
      const country = countryOptions.find(
        (c) => c?.dialCode === selectedDialCode,
      )

      if (country) {
        setSelectedCountry(country)
        if (onChange) {
          onChange(`${country.dialCode}${phoneNumber}`)
        }
      }
    }

    const handlePhoneNumberChange = (
      event: React.ChangeEvent<HTMLInputElement>,
    ) => {
      const newPhoneNumber = event.target.value
      setPhoneNumber(newPhoneNumber)

      if (onChange && selectedCountry) {
        onChange(`${selectedCountry.dialCode}${newPhoneNumber}`)
      }
    }

    return (
      <div
        className={classNames(
          'flex items-center justify-center rounded-md',
          'text-sm font-normal text-ppa/formText',
          disabledInput && 'bg-gray-100',
          !error ? defaultFocusAnimation : erroredFocusAnimation,
        )}
      >
        <select
          value={selectedCountry?.dialCode}
          onChange={handleCountryChange}
          className="rounded-l-md p-2"
        >
          {countryOptions.map((country) => (
            <option key={country?.code} value={country?.dialCode}>
              {country?.flag} {country?.dialCode}
            </option>
          ))}
        </select>
        <div className="w-px h-6 bg-gray-300 mx-1" />
        <input
          type="tel"
          value={phoneNumber}
          onChange={handlePhoneNumberChange}
          className="w-full rounded-md self-center p-2 text-ppa/formText placeholder-ppa/placeholder placeholder:font-normal hover:appearance-none focus:appearance-none disabled:opacity-80 disabled:bg-gray-100"
          ref={ref}
          {...rest}
        />
      </div>
    )
  },
)

export default PhoneNumberInput
