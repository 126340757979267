import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { TenderDetailsSupplierProps } from '../details'

import TenderDetails from '../components/details'
import BidDetails, { ButtonList } from '../components/details-bid'
import BidCreateOrUpdate from '../components/bid-create-update'

const TendersActiveDetails: React.FC<TenderDetailsSupplierProps> = ({
  tenderInvitation,
  refetch,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details',
  })

  const [isBidDrawerOpen, setIsBidDrawerOpen] = useState(false)

  const handleCloseDrawer = () => setIsBidDrawerOpen(false)
  const handleOnSuccessDrawer = () => {
    setIsBidDrawerOpen(false)
    refetch()
  }

  const buttons = useMemo((): ButtonList[] => {
    if (!tenderInvitation?.tender) return []
    let buttonText: string
    let variant: 'primary' | 'secondary' = 'primary'

    if (tenderInvitation?.bid) {
      if (tenderInvitation.bid.status === 'ACTIVE') {
        buttonText = t('actions.updateBid.button')
        variant = 'secondary'
      } else if (tenderInvitation.bid.status === 'REJECTED') {
        buttonText = t('actions.editBid.button')
        variant = 'secondary'
      } else {
        return []
      }
    } else {
      buttonText = t('actions.placeBid.button')
    }

    const { tender } = tenderInvitation

    let isBiddingDisabled = false
    if (
      !tender?.deadline ||
      (!tender.site.brokerFee &&
        !['0', '0.0', 0].includes(tender.site.brokerFee))
    ) {
      isBiddingDisabled = true
    }

    return [
      {
        onClick: () => setIsBidDrawerOpen(true),
        props: {
          variant,
          disabled: isBiddingDisabled,
        },
        text: buttonText,
      },
    ]
  }, [tenderInvitation])

  return (
    <div className="flex flex-col 2xl:flex-row">
      <div className="flex-1">
        <TenderDetails tenderInvitation={tenderInvitation} />
      </div>
      <div
        className={classNames(
          'border-ppa/grayBorder',
          'border-t mt-10 pt-10',
          '2xl:border-t-0 2xl:border-l 2xl:mt-10 2xl:pt-0 2xl:ml-10 2xl:pl-10',
          'flex-1',
        )}
      >
        <BidDetails
          tender={tenderInvitation}
          buttons={buttons}
          refetchBidList={refetch}
        />
      </div>
      {tenderInvitation && (
        <BidCreateOrUpdate
          isDrawerOpen={isBidDrawerOpen}
          tenderInvitation={tenderInvitation}
          onSuccess={handleOnSuccessDrawer}
          closeDrawer={handleCloseDrawer}
        />
      )}
    </div>
  )
}

export default TendersActiveDetails
