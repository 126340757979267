import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import RequiredField from '../../../../../../components/atoms/required-form-field'
import InputGroup from '../../../../../../components/molecules/input-group'
import InputFile from '../../../../../../components/atoms/input-file'
import Button from '../../../../../../components/atoms/button'

import SelectComponent, {
  SelectOptions,
} from '../../../../../../components/atoms/select'

export type BillContractFormValues = {
  documentType?: SelectOptions
  month: SelectOptions
  year: SelectOptions
  billFile?: File
  contractFile?: File
}

interface BillFormProps {
  onSubmit: (data: BillContractFormValues) => void
  onSkip?: () => void
  isLoading?: boolean
  onPreviousStep?: () => void
}

export type BillContractFormRef = {
  resetForm: () => void
}

const FormBillContract: React.FC<BillFormProps> = ({
  onSubmit,
  isLoading,
  onSkip,
  onPreviousStep,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.billDrawer',
  })

  const {
    control,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm<BillContractFormValues>({
    defaultValues: {
      month: undefined,
      year: undefined,
      documentType: undefined,
    },
  })

  const documentType = watch('documentType')

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-5 min-w-[510px]"
    >
      <Controller
        name="documentType"
        control={control}
        rules={{ required: true }}
        render={({ field: props }) => (
          <InputGroup label={t('documentType.title')} required>
            <SelectComponent
              {...props}
              type="single"
              options={[
                { value: 'Bill', label: t('documentType.options.bill') },
                {
                  value: 'Contract',
                  label: t('documentType.options.contract'),
                },
              ]}
            />
          </InputGroup>
        )}
      />

      {documentType && (
        <>
          {documentType.value === 'Bill' && (
            <div className="flex flex-col md:flex-row gap-5">
              <Controller
                name="month"
                control={control}
                rules={{
                  required: { value: true, message: t('month.required') },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('month.title')}
                    error={errors.month?.message}
                    required
                  >
                    <SelectComponent
                      {...props}
                      type="single"
                      options={[
                        { value: '1', label: '1' },
                        { value: '2', label: '2' },
                        { value: '3', label: '3' },
                        { value: '4', label: '4' },
                        { value: '5', label: '5' },
                        { value: '6', label: '6' },
                        { value: '7', label: '7' },
                        { value: '8', label: '8' },
                        { value: '9', label: '9' },
                        { value: '10', label: '10' },
                        { value: '11', label: '11' },
                        { value: '12', label: '12' },
                      ]}
                      placeholder={t('month.placeholder')}
                      error={errors.month?.message}
                    />
                  </InputGroup>
                )}
              />

              <Controller
                name="year"
                control={control}
                rules={{
                  required: { value: true, message: t('year.required') },
                }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('year.title')}
                    error={errors.year?.message}
                    required
                  >
                    <SelectComponent
                      {...props}
                      type="single"
                      options={[
                        { value: '2024', label: '2024' },
                        { value: '2023', label: '2023' },
                        { value: '2022', label: '2022' },
                      ]}
                      placeholder={t('year.placeholder')}
                      error={errors.year?.message}
                    />
                  </InputGroup>
                )}
              />
            </div>
          )}

          {documentType && (
            <Controller
              name={documentType.value === 'Bill' ? 'billFile' : 'contractFile'}
              control={control}
              rules={{
                required: { value: true, message: t('file.required') },
              }}
              render={({ field: { name } }) => (
                <InputGroup
                  label={t('file.title')}
                  error={errors[name]?.message}
                  infoLabel={t('file.infoLabel')}
                  required
                >
                  <InputFile
                    name={name}
                    setValue={setValue}
                    error={errors[name]?.message}
                    acceptFiles="pdf"
                  />
                </InputGroup>
              )}
            />
          )}
        </>
      )}

      <div>
        <RequiredField />
      </div>

      <div className="flex items-center justify-end gap-x-5">
        {onPreviousStep && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              variant="tertiary"
              onClick={onPreviousStep}
              fullWidth
              disabled={isLoading}
            >
              {t('back')}
            </Button>
          </div>
        )}
        {onSkip && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              variant="quaternary"
              onClick={onSkip}
              fullWidth
              disabled={isLoading}
            >
              {t('skip')}
            </Button>
          </div>
        )}
        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default FormBillContract
