import React, { useEffect, useMemo } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import { v4 } from 'uuid'

import {
  TrashIcon,
  PlusIcon,
  MenuIcon,
  ExclamationIcon,
} from '@heroicons/react/solid'
import classNames from 'classnames'

import { validateDecimalNumbers } from '../../../../../../utils'

import Input from '../../../../../../components/atoms/input'
import Button from '../../../../../../components/atoms/button'

import InputGroup from '../../../../../../components/molecules/input-group'
import Select, {
  SelectOptions,
} from '../../../../../../components/atoms/select'

import { technologiesList } from '../../../../../../utils/data'

import {
  Technology,
  ConditionOperator,
  ConditionField,
} from '../../../../../../types'

export type ConditionTechnology = {
  type: SelectOptions<'technology'> | string
  comparison:
    | SelectOptions<
        Omit<ConditionOperator, '>' | '<'>,
        Omit<ConditionOperator, '>' | '<'>
      >
    | string
  option?: SelectOptions<Technology> | string
  value?: undefined
}

export type ConditionNumeric = {
  type: SelectOptions<'capacity' | 'voltage' | 'roc_band'> | string
  comparison: SelectOptions<ConditionOperator, ConditionOperator> | string
  option?: undefined
  value?: string
}

export type ConditionString = {
  type: SelectOptions<'mpan' | 'msid'> | string
  comparison:
    | SelectOptions<
        Omit<ConditionOperator, '>' | '<'>,
        Omit<ConditionOperator, '>' | '<'>
      >
    | string
  option?: undefined
  value?: string
}
export type Condition =
  | { id: string } & (ConditionTechnology | ConditionNumeric | ConditionString)

interface Rules {
  id: string
  conditions: Array<Condition>
  brokerFee?: string
}

export type FormValues = {
  rules: Array<Rules>
}

export interface BrokerFeeFormProps {
  onCancel: () => void
  onSubmit: (data: any) => void
  formDefaultValues?: FormValues
  isLoading?: boolean
}

const defaultValues: FormValues = {
  rules: [
    {
      id: v4(),
      conditions: [
        {
          id: v4(),
          comparison: '=',
          type: 'technology',
          option: 'SOLAR',
        },
      ],
      brokerFee: '',
    },
  ],
}

const comparisonOptions: SelectOptions<ConditionOperator, ConditionOperator>[] =
  [
    { label: '>', value: '>' },
    { label: '<', value: '<' },
    { label: '=', value: '=' },
  ]

const comparisonEqualityOptions: SelectOptions<
  ConditionOperator,
  ConditionOperator
>[] = [{ label: '=', value: '=' }]

const BrokerFeeForm: React.FC<BrokerFeeFormProps> = ({
  onSubmit,
  formDefaultValues,
  onCancel,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'staffAdmins.generators.details.brokerFeeDrawer.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const {
    control,
    handleSubmit,
    watch,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  useEffect(() => {
    if (!formDefaultValues?.rules || formDefaultValues.rules.length === 0) {
      reset(defaultValues)
      return
    }

    reset(formDefaultValues)
  }, [defaultValues])

  const handleAddCondition = (ruleId: string) => {
    const oldRules = watch('rules')
    const newRules: Rules[] = oldRules.map((item) => {
      if (item.id !== ruleId) return item

      return {
        ...item,
        conditions: [
          ...item.conditions,
          {
            id: v4(),
            type: 'Capacity',
            comparison: '=',
            value: '0',
          },
        ],
      }
    })

    setValue('rules', newRules)
  }

  const handleAddRule = () => {
    const oldRules = watch('rules')
    setValue('rules', [
      ...oldRules,
      {
        id: v4(),
        conditions: [
          {
            id: v4(),
            comparison: '=',
            type: 'capacity',
            value: '0',
          },
        ],
        brokerFee: '0',
      },
    ])
  }

  const handleDeleteRule = (idToRemove: string) => {
    setValue(
      'rules',
      watch('rules').filter(({ id }) => id !== idToRemove),
    )
  }

  const handleDeleteCondition = (idCondition: string) => {
    const filteredRules = watch('rules').map((item) => {
      return {
        ...item,
        conditions: item.conditions.filter(
          (condition) => condition.id !== idCondition,
        ),
      }
    })
    setValue(
      'rules',
      filteredRules.filter((item) => item.conditions.length !== 0),
    )
  }

  const conditionTypeOptions = useMemo((): Array<
    SelectOptions<ConditionField>
  > => {
    return [
      { value: 'technology', label: 'Technology' },
      { value: 'capacity', label: 'Capacity' },
      { value: 'voltage', label: 'Voltage' },
    ]
  }, [])

  const technologyOptions = useMemo((): Array<SelectOptions<Technology>> => {
    return technologiesList.map((item) => {
      return { label: tUtils(`technology.${item}.abbreviation`), value: item }
    })
  }, [])

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col w-full gap-y-5 w-100"
    >
      {watch('rules') &&
        watch('rules').length > 0 &&
        watch('rules').map((rule, idx) => (
          <div
            key={rule.id}
            className={classNames(
              'fade-in',
              'flex items-start gap-x-4 w-full',
              'z-10 relative',
            )}
          >
            <MenuIcon className="text-ppa/placeholder w-5 h-5" />
            <div className="flex flex-col gap-y-3">
              <div className="flex items-center justify-between">
                <h3 className="font-semibold text-ppa/title text-base">
                  Rule {idx + 1}
                </h3>
                {idx !== 0 && (
                  <Button
                    variant="text"
                    icon={<TrashIcon />}
                    type="button"
                    onClick={() => handleDeleteRule(rule.id)}
                  >
                    <span className="underline">Delete rule</span>
                  </Button>
                )}
              </div>

              <section
                className={classNames(
                  'flex flex-col gap-y-8 w-full',
                  'pl-5 border-l border-l-ppa/grayBorder',
                )}
              >
                {rule.conditions.length &&
                  rule.conditions.map((condition, idxCondition) => {
                    if (
                      condition.type === 'mpan' ||
                      condition.type === 'msid'
                    ) {
                      return (
                        <div
                          key={condition.id}
                          className="flex gap-x-5 items-center w-100"
                        >
                          <Controller
                            name={`rules.${idx}.conditions.${idxCondition}.type`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                /**
                                 * @DO_NOT_REMOVE
                                 * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                                 */
                                message: ' ',
                              },
                            }}
                            render={({ field: { onChange, value, name } }) => (
                              <div className="w-[175px] flex items-center">
                                <Select
                                  disableClean
                                  placeholder="Select a source"
                                  name={name}
                                  options={conditionTypeOptions}
                                  onChange={onChange}
                                  value={value}
                                  testId="select-source"
                                />
                              </div>
                            )}
                          />
                          <Controller
                            name={`rules.${idx}.conditions.${idxCondition}.comparison`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                /**
                                 * @DO_NOT_REMOVE
                                 * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                                 */
                                message: ' ',
                              },
                            }}
                            render={({ field: { onChange, value, name } }) => (
                              <div className="w-[100px] flex items-center">
                                <Select
                                  disableClean
                                  disablePlaceholder
                                  name={name}
                                  options={comparisonEqualityOptions}
                                  onChange={onChange}
                                  value={value}
                                  testId="select-comparison"
                                />
                              </div>
                            )}
                          />
                          <Controller
                            name={`rules.${idx}.conditions.${idxCondition}.value`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                /**
                                 * @DO_NOT_REMOVE
                                 * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                                 */
                                message: ' ',
                              },
                            }}
                            render={({ field: props }) => (
                              <div className="w-[150px] flex items-center">
                                <Input
                                  {...props}
                                  placeholder="Example: 2100041707899"
                                />
                              </div>
                            )}
                          />
                          <TrashIcon
                            className={classNames(
                              'w-5 h-5 text-ppa/title',
                              'hover:brightness-75 cursor-pointer',
                            )}
                            onClick={() => handleDeleteCondition(condition.id)}
                          />
                        </div>
                      )
                    }

                    if (condition.type === 'technology') {
                      return (
                        <div
                          key={condition.id}
                          className="flex gap-x-5 items-center w-100"
                        >
                          <Controller
                            name={`rules.${idx}.conditions.${idxCondition}.type`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                /**
                                 * @DO_NOT_REMOVE
                                 * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                                 */
                                message: ' ',
                              },
                            }}
                            render={({ field: { onChange, value, name } }) => (
                              <div className="w-[175px] flex items-center">
                                <Select
                                  disableClean
                                  placeholder="Select a source"
                                  name={name}
                                  options={conditionTypeOptions}
                                  onChange={onChange}
                                  value={value}
                                  testId="select-source"
                                />
                              </div>
                            )}
                          />
                          <Controller
                            name={`rules.${idx}.conditions.${idxCondition}.comparison`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                /**
                                 * @DO_NOT_REMOVE
                                 * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                                 */
                                message: ' ',
                              },
                            }}
                            render={({ field: { onChange, value, name } }) => (
                              <div className="w-[100px] flex items-center">
                                <Select
                                  disableClean
                                  disablePlaceholder
                                  name={name}
                                  options={comparisonEqualityOptions}
                                  onChange={onChange}
                                  value={value}
                                  testId="select-comparison"
                                />
                              </div>
                            )}
                          />
                          <Controller
                            name={`rules.${idx}.conditions.${idxCondition}.option`}
                            control={control}
                            rules={{
                              required: {
                                value: true,
                                /**
                                 * @DO_NOT_REMOVE
                                 * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                                 */
                                message: ' ',
                              },
                            }}
                            render={({ field: { name, onChange, value } }) => (
                              <div className="w-[150px] flex items-center">
                                <Select
                                  disableClean
                                  disablePlaceholder
                                  name={name}
                                  options={technologyOptions}
                                  onChange={onChange}
                                  value={value}
                                  testId="select-technology"
                                />
                              </div>
                            )}
                          />
                          <TrashIcon
                            className={classNames(
                              'w-5 h-5 text-ppa/title',
                              'hover:brightness-75 cursor-pointer',
                            )}
                            onClick={() => handleDeleteCondition(condition.id)}
                          />
                        </div>
                      )
                    }

                    return (
                      <div
                        key={condition.id}
                        className="flex gap-x-5 items-center w-100"
                      >
                        <Controller
                          name={`rules.${idx}.conditions.${idxCondition}.type`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              /**
                               * @DO_NOT_REMOVE
                               * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                               */
                              message: ' ',
                            },
                          }}
                          render={({ field: { onChange, value, name } }) => (
                            <div className="w-[175px] flex items-center">
                              <Select
                                disableClean
                                placeholder="Select a source"
                                name={name}
                                options={conditionTypeOptions}
                                onChange={onChange}
                                value={value}
                                testId="select-source"
                              />
                            </div>
                          )}
                        />
                        <Controller
                          name={`rules.${idx}.conditions.${idxCondition}.comparison`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              /**
                               * @DO_NOT_REMOVE
                               * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                               */
                              message: ' ',
                            },
                          }}
                          render={({ field: { onChange, value, name } }) => (
                            <div className="w-[100px] flex items-center">
                              <Select
                                disableClean
                                disablePlaceholder
                                name={name}
                                options={comparisonOptions}
                                onChange={onChange}
                                value={value}
                                testId="select-comparison"
                              />
                            </div>
                          )}
                        />
                        <Controller
                          name={`rules.${idx}.conditions.${idxCondition}.value`}
                          control={control}
                          rules={{
                            required: {
                              value: true,
                              /**
                               * @DO_NOT_REMOVE
                               * This is hardcoded to not show a message, it's being shown at the bottom of the container.
                               */
                              message: ' ',
                            },
                            validate: {
                              isValidDecimal: validateDecimalNumbers,
                            },
                          }}
                          render={({ field: props }) => (
                            <div
                              className={classNames(
                                'flex items-center',
                                !errors?.rules?.[idx]?.conditions?.[
                                  idxCondition
                                ]?.message
                                  ? 'w-[150px]'
                                  : 'w-[300px]',
                              )}
                            >
                              <Input {...props} type="text" placeholder="0.0" />
                              {errors?.rules?.[idx]?.conditions?.[idxCondition]
                                ?.message && (
                                <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in flex-wrap ml-2">
                                  <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                                  <span className="text-xs font-light text-ppa/warning">
                                    {
                                      errors?.rules?.[idx]?.conditions?.[
                                        idxCondition
                                      ]?.message
                                    }
                                  </span>
                                </span>
                              )}
                            </div>
                          )}
                        />
                        <TrashIcon
                          className={classNames(
                            'w-5 h-5 text-ppa/title',
                            'hover:brightness-75 cursor-pointer',
                          )}
                          onClick={() => handleDeleteCondition(condition.id)}
                        />
                      </div>
                    )
                  })}

                <div className="flex items-center justify-start">
                  <Button
                    variant="text"
                    icon={<PlusIcon />}
                    onClick={() => handleAddCondition(rule.id)}
                  >
                    <span className="underline">{t('addCondition')}</span>
                  </Button>
                </div>

                <Controller
                  name={`rules.${idx}.brokerFee`}
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('this.is.required'),
                    },
                    validate: {
                      isValidDecimal: validateDecimalNumbers,
                    },
                  }}
                  render={({ field: { name, ...props } }) => (
                    <InputGroup
                      label={t('brokerFee.title')}
                      error={(errors?.rules || {})[idx]?.brokerFee?.message}
                      required
                    >
                      <Input
                        {...props}
                        name={name}
                        type="text"
                        error={(errors?.rules || {})[idx]?.brokerFee?.message}
                      />
                    </InputGroup>
                  )}
                />
              </section>
            </div>
          </div>
        ))}

      <div className="flex relative z-0">
        <Button variant="tertiary" icon={<PlusIcon />} onClick={handleAddRule}>
          {t('addRule')}
        </Button>
      </div>

      <div className="flex justify-end w-full gap-x-5">
        <div className="max-w-[100px] w-full">
          <Button variant="quaternary" fullWidth onClick={onCancel}>
            {tUtils('form.buttons.cancel')}
          </Button>
        </div>

        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {tUtils('form.buttons.submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default BrokerFeeForm
