import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useFormContext, Controller } from 'react-hook-form'

import { getCountryOptionsData } from '../../../../../../utils'

import Input from '../../../../../../components/atoms/input'
import SelectComponent from '../../../../../../components/atoms/select'
import InputGroup from '../../../../../../components/molecules/input-group'

export interface Address {
  addressLine1?: string
  addressLine2?: string | null
  postalCode?: string
  locality?: string
  region?: string | null
  country?: string
}

export type FormValues = {
  address?: Address
}

const SiteAddressForm: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.siteDrawer.form.addressForm',
  })

  const countryOptions = useMemo(() => getCountryOptionsData(), [])

  const mappedCountryOptions = useMemo(() => {
    return (
      countryOptions.map((country) => ({
        value: country.alpha3,
        label: country.country,
        key: country.alpha3,
      })) || []
    )
  }, [countryOptions])

  const {
    control,
    formState: { errors },
  } = useFormContext<FormValues>()

  return (
    <InputGroup label={t('title')} required>
      <div className="border-l">
        <div className="flex flex-col gap-y-3 mx-5">
          <Controller
            name="address.addressLine1"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('addressLine1.required'),
              },
            }}
            render={({ field: props }) => (
              <InputGroup
                label={t('addressLine1.title')}
                error={errors.address?.addressLine1?.message as any}
                required
              >
                <Input
                  {...props}
                  placeholder={t('addressLine1.placeholder')}
                  error={errors.address?.addressLine1?.message}
                  attribute="address-line1"
                />
              </InputGroup>
            )}
          />
          <Controller
            name="address.addressLine2"
            control={control}
            render={({ field: props }) => (
              <InputGroup label={t('addressLine2.title')}>
                <Input
                  {...props}
                  placeholder={t('addressLine2.placeholder')}
                  attribute="address-line2"
                />
              </InputGroup>
            )}
          />
          <div className="flex flex-col gap-3 md:flex-row">
            <Controller
              name="address.postalCode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('postalCode.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('postalCode.title')}
                  error={errors.address?.postalCode?.message as any}
                  required
                >
                  <Input
                    {...props}
                    placeholder={t('postalCode.placeholder')}
                    error={errors.address?.postalCode?.message}
                    attribute="postal-code"
                  />
                </InputGroup>
              )}
            />
            <Controller
              name="address.locality"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('locality.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('locality.title')}
                  error={errors.address?.locality?.message as any}
                  required
                >
                  <Input
                    {...props}
                    placeholder={t('locality.placeholder')}
                    error={errors.address?.locality?.message}
                  />
                </InputGroup>
              )}
            />
          </div>
          <div className="flex flex-col gap-3 md:flex-row">
            <Controller
              name="address.region"
              control={control}
              render={({ field: props }) => (
                <InputGroup
                  label={t('county.title')}
                  error={errors.address?.region?.message as any}
                >
                  <Input
                    {...props}
                    placeholder={t('county.placeholder')}
                    error={errors.address?.region?.message}
                    attribute="country-name"
                  />
                </InputGroup>
              )}
            />
            <Controller
              name="address.country"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('country.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('country.title')}
                  error={errors.address?.country?.message as any}
                  required
                >
                  <SelectComponent
                    {...props}
                    type="single"
                    options={mappedCountryOptions}
                    placeholder={t('country.placeholder')}
                    error={errors.address?.country?.message}
                    testId="select-country"
                  />
                </InputGroup>
              )}
            />
          </div>
        </div>
      </div>
    </InputGroup>
  )
}

export default SiteAddressForm
