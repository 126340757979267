import React, { useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams } from 'react-router-dom'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'
import Stepper from '../../../../../../components/atoms/stepper'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import FormBillContract, {
  BillContractFormRef,
  BillContractFormValues,
} from '../form-bill-contract'
import MeterForm, { FormValues } from '../form-meter'

export interface CreateMeterProps {
  handleClose: () => void
  onSuccess: () => void
  isOpen?: boolean
}

const CreateMeter: React.FC<CreateMeterProps> = ({
  onSuccess,
  handleClose,
  isOpen = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'consumers.meters.meterDrawer',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { consumerId } = useParams<{ consumerId?: string }>()

  const { fetchData, throwFeedbackError } = usePPA()

  const billContractForm = useRef<BillContractFormRef>(null)

  const [isLoading, setIsLoading] = useState(false)
  const [currentStep, setCurrentStep] = useState(0)

  const [meterId, setMeterId] = useState<string | undefined>()

  const handleCreateMeter = async (formData: FormValues) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      if (
        formData.gridConnectionSize &&
        Number.isNaN(Number(formData.gridConnectionSize))
      ) {
        feedbackMessage(
          {
            title: t(
              'create.onSubmit.error.gridConnectionSizeNotANumber.title',
            ),
            description: t(
              'create.onSubmit.error.gridConnectionSizerNotANumber.description',
            ),
          },
          'error',
        )
        setIsLoading(false)
        return
      }

      if (
        formData.meterOperator &&
        Number.isNaN(Number(formData.meterOperator))
      ) {
        feedbackMessage(
          {
            title: t('create.onSubmit.error.meterOperatorNotANumber.title'),
            description: t(
              'create.onSubmit.error.meterOperatorNotANumber.description',
            ),
          },
          'error',
        )
        setIsLoading(false)
        return
      }

      if (formData.dcda && Number.isNaN(Number(formData.dcda))) {
        feedbackMessage(
          {
            title: t('create.onSubmit.error.dcdaNotANumber.title'),
            description: t('create.onSubmit.error.dcdaNotANumber.description'),
          },
          'error',
        )
        setIsLoading(false)
        return
      }

      const parsedCompany = formData.company && JSON.parse(formData.company)
      const { response, error } = await fetchData({
        method: 'POST',
        url: '/core/private/consumer-meter/consumer',
        body: {
          consumerId,
          companyId: parsedCompany.id,
          name: formData.name,
          address: {
            ...formData.address,
            country: formData?.address?.country,
            region: formData?.address?.region || null,
            addressLine2: formData.address?.addressLine2 || null,
          },
          existingSupplier: formData.existingSupplier || null,
          mpan: formData?.mpan || null,
          gridConnectionSize: formData?.gridConnectionSize || null,
          meterOperator: formData?.meterOperator || null,
          dataCollectorDataAggregator: formData?.dcda || null,
          profileClass: formData?.profileClass
            ? parseFloat(`${formData?.profileClass}`)
            : null,
        },
      })

      if (error || !response) throw error

      feedbackMessage(
        {
          title: t('create.onSubmit.success.title', {
            meterName: formData.name,
          }),
          description: t('create.onSubmit.success.description'),
        },
        'success',
      )

      setMeterId(response.data.meter.id)

      setCurrentStep(1)
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'consumerMeter',
        SERVER_ERROR: ({ message }) => {
          if (
            message.includes('The company') &&
            message.includes('must be active')
          ) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.consumerMeter.SERVER_ERROR.companyMustBeActive',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const handleUploadBillOrContract = async (data: BillContractFormValues) => {
    if (isLoading) return
    setIsLoading(true)

    try {
      if (!meterId) throw Error('Missing meter ID')

      const formData = new FormData()
      formData.append('meterId', meterId)

      const isBill = data?.documentType?.value === 'Bill'

      const file = isBill ? data.billFile : data.contractFile
      if (file) {
        formData.append('file', file)
      } else {
        throw new Error('File is missing')
      }

      if (isBill) {
        formData.append('month', data.month.value)
        formData.append('year', data.year.value)
      }

      const { error } = await fetchData({
        method: 'POST',
        url: isBill
          ? '/core/private/consumer-meter/consumer/bill'
          : '/core/private/consumer-meter/consumer/contract',
        body: formData,
        headers: {
          'Content-type': 'multipart/form-data',
        },
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('file.onUpload.success.description'),
        },
        'success',
      )

      onSuccess()
      setCurrentStep(0)
      billContractForm.current?.resetForm()
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsLoading(false)
    }
  }

  const onSkipBillContract = () => {
    setMeterId(undefined)
    onSuccess()
  }

  const handleCancel = () => {
    setCurrentStep(0)
    setMeterId(undefined)
    handleClose()
  }

  const stepElements = useMemo(() => {
    return [
      <MeterForm
        isLoading={isLoading}
        onSubmit={handleCreateMeter}
        onCancel={handleCancel}
      />,
      <FormBillContract
        isLoading={isLoading}
        onSubmit={handleUploadBillOrContract}
        onSkip={onSkipBillContract}
      />,
    ]
  }, [isLoading])

  return (
    <SlidingDrawer isOpen={isOpen} handleClose={handleClose}>
      <div className="flex flex-col gap-y-5">
        <h1 className="text-xl font-bold text-ppa/title">
          {t('create.title')}
        </h1>

        {isOpen && (
          <>
            <Stepper
              steps={[t('steps.addDetails'), t('steps.uploadBillOrContract')]}
              activeStep={currentStep}
              setActiveStep={setCurrentStep}
              disabledStep={currentStep === 0 || currentStep === 2}
            />
            {stepElements[currentStep]}
          </>
        )}
      </div>
    </SlidingDrawer>
  )
}

export default CreateMeter
