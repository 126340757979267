import React from 'react'
import classNames from 'classnames'

import { ReactComponent as CheckIcon } from './assets/CheckIcon.svg'
import { ReactComponent as ChevronRight } from './assets/ChevronRight.svg'

import { transition } from '../../../styles/index'

interface StepProps {
  steps: string[]
  activeStep: number
  setActiveStep: (step: number) => void
  disabledStep?: boolean
}

const Stepper: React.FC<StepProps> = ({
  steps,
  activeStep,
  setActiveStep,
  disabledStep,
}) => {
  return (
    <div className="flex">
      <nav className="flex">
        {steps.map((currentStep, index) => (
          <React.Fragment key={currentStep}>
            {index !== 0 && (
              <div className="m-2.5">
                <ChevronRight />
              </div>
            )}
            <button
              type="button"
              className={classNames(
                'font-semibold text-base',
                !disabledStep && 'cursor-pointer',
                transition,
                activeStep === index ? 'text-ppa/title' : 'text-ppa/grayText',
              )}
              onClick={() => {
                if (index < activeStep) setActiveStep(index)
              }}
              disabled={disabledStep}
            >
              <span className="flex items-center justify-center">
                {activeStep > index ? (
                  <div className="text-ppa/placeholder rounded-full mr-2">
                    <CheckIcon className="w-6 h-6" />
                  </div>
                ) : (
                  <div className="flex items-center justify-center content-center">
                    <span
                      className={classNames(
                        'border-2 rounded-full mr-2',
                        index === activeStep
                          ? 'border-ppa/primaryActive text-ppa/primaryActive'
                          : 'border-ppa/placeholder text-ppa/placeholder',
                      )}
                    >
                      <div className="text-sm font-semibold mx-auto px-1.5">
                        {index + 1}
                      </div>
                    </span>
                  </div>
                )}
                {currentStep}
              </span>
            </button>
          </React.Fragment>
        ))}
      </nav>
    </div>
  )
}

export default Stepper
