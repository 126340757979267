import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import timezone from 'dayjs/plugin/timezone'
import { v4 } from 'uuid'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import { dateFormats, priceTypeList } from '../../../../../../utils/data'
import { PowerPriceName } from '../../../../../../types'

import PlaceBidForm, { FormData } from './form-place-bid'

import { TenderInvitation } from '../details'

dayjs.extend(utc)
dayjs.extend(timezone)

interface FormProps {
  tenderInvitation: TenderInvitation
  isDrawerOpen: boolean
  onSuccess: () => void
  closeDrawer: () => void
}

const CreateOrUpdateBid: React.FC<FormProps> = ({
  tenderInvitation,
  onSuccess,
  isDrawerOpen,
  closeDrawer,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.bids.placeBid',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitPlaceBidForm = async (data: any) => {
    if (isLoading) return
    setIsLoading(true)

    let subsidies: any[] = []

    if (data.subsidies) {
      subsidies = Object.keys(data.subsidies).map((name) => {
        const subsidy = data.subsidies[name]
        return {
          name,
          type: subsidy.type,
          value: parseFloat(subsidy?.value || 0),
        }
      })
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PUT',
        url: '/core/private/bid',
        body: {
          tenderId: tenderInvitation.tender.id,
          expiresAt: data.expiresAt,
          periods: data.periods.map((period: any, index: number) => {
            let type = 'FIXED'
            if (['DAY_AHEAD', 'SPILL'].includes(period.priceType)) {
              type = 'FLEXIBLE'
            }
            let { priceType } = period
            if (data.contractType === 'MATCHED_UNMATCHED') {
              priceType = 'MATCHED_UNMATCHED'
            }
            if (priceType === 'MATCHED_UNMATCHED') {
              type = index === 0 ? 'MATCHED' : 'UNMATCHED'
            }

            return {
              startDate: period.startDate,
              endDate: period.endDate,
              value: parseFloat(period.value),
              name: priceType,
              type,
            }
          }),
          benefits: Object.keys(data.embeddedBenefits).map((name) => {
            const benefit = data.embeddedBenefits[name]
            return {
              name,
              type: benefit.type,
              value: parseFloat(benefit?.value || 0),
            }
          }),
          subsidies,

          charges: Object.keys(data.charges).map((name) => {
            const charge = data.charges[name]
            return {
              name,
              type: charge.type,
              value: parseFloat(charge?.value || 0),
            }
          }),
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handlePlaceBid.success.description'),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'bid',
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Bid found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          if (message.includes('Site brokerFee not found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.NOT_FOUND_ERROR.reason.CHARGE_BROKER_FEE',
              ),
              type: 'error',
            }
          }
          return undefined
        },
        PAYLOAD_ERROR: ({ message }) => {
          if (message.includes('Bid.checkForInactiveBenefits()')) {
            const [_, benefits] = message.split('Some Benefits are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.reason.INACTIVE_BENEFITS',
              )}\n${benefits}.`,
              type: 'error',
            }
          }

          if (message.includes('Bid.checkForInactiveSubsidies()')) {
            const [_, sub] = message.split('Some Subsidies are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.reason.INACTIVE_SUBSIDIES',
              )}\n${sub}.`,
              type: 'error',
            }
          }

          if (message.includes('Bid.checkForInactiveCharges()')) {
            const [_, charges] = message.split('Some Charges are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.bid.PAYLOAD_ERROR.reason.INACTIVE_CHARGES',
              )}\n${charges}.`,
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const defaultFormValues = useMemo((): FormData => {
    if (!tenderInvitation?.bid) {
      const { tender } = tenderInvitation
      return {
        contractType: priceTypeList[0].value,
        expiresAt: dayjs().hour(17).minute(30).format(dateFormats.dateInput),
        periods: [
          {
            id: v4(),
            periodId: v4(),
            startDate: dayjs(tender.startDate).format(dateFormats.aws),
            endDate: dayjs(tender.endDate).format(dateFormats.aws),
            priceType: priceTypeList[1].value,
            value: '0',
          },
          {
            id: v4(),
            periodId: v4(),
            startDate: dayjs(tender.startDate)
              .add(1, 'day')
              .format(dateFormats.aws),
            endDate: dayjs(tender.endDate)
              .add(1, 'day')
              .format(dateFormats.aws),
            priceType: priceTypeList[2].value,
            value: '0',
          },
        ],
      }
    }

    const { bid } = tenderInvitation

    const contractType = bid.periods
      .map((period) => {
        const bidPriceType = priceTypeList.find(
          (item) => item.value === period.name,
        )

        return bidPriceType ? bidPriceType.value : 'MULTIPLE_TYPES'
      })
      .filter((type, index, self) => self.indexOf(type) === index)

    const finalContractType =
      contractType.length > 0 ? contractType[0] : 'MULTIPLE_TYPES'

    return {
      periods: bid.periods.map((period) => {
        const bidPriceType = priceTypeList.filter(
          (item) => item.value === period.name,
        )[0]

        const result = {
          id: v4(),
          periodId: v4(),
          startDate: dayjs(period.startDate).format(dateFormats.aws),
          endDate: dayjs(period.endDate).format(dateFormats.aws),
          priceType: bidPriceType.value as PowerPriceName,
          value: `${period.value || 0}`,
          title: '',
        }

        return result
      }),

      contractType: finalContractType,
      expiresAt: dayjs
        .utc(bid.expiresAt)
        .tz('Europe/London')
        .format('YYYY-MM-DDTHH:mm:ss'),
    }
  }, [tenderInvitation, isDrawerOpen, tenderInvitation.bid])

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={closeDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        {isDrawerOpen && (
          <PlaceBidForm
            isLoading={isLoading}
            defaultValues={defaultFormValues}
            onSubmit={handleSubmitPlaceBidForm}
            brokerFee={tenderInvitation.tender.site.brokerFee}
            tender={tenderInvitation.tender}
            bid={tenderInvitation?.bid}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default CreateOrUpdateBid
