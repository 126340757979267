import { useState, useEffect, forwardRef } from 'react'

import usePPA from '../../../hooks/use-ppa'

import { capitalizeText } from '../../../utils'

import { MemberType } from '../../../types/user'

import Select, { SelectProps, SelectOptions } from '../../atoms/select'

interface SelectMemeberProps
  extends Omit<SelectProps, 'type' | 'onCreateOption' | 'options'> {
  memberType?: MemberType[]
}

const SelectMemberType = forwardRef<any, SelectMemeberProps>(
  ({ memberType, ...props }, ref) => {
    const [memberTypeOptions, setMemberTypeOptions] = useState<SelectOptions[]>(
      [],
    )
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const { fetchData } = usePPA()

    const getMemberTypeList = async () => {
      if (isLoading || memberTypeOptions.length > 0) return
      setIsLoading(true)

      try {
        const { error, response } = await fetchData({
          method: 'GET',
          url: '/core/private/default-values/generator/member-type',
        })

        if (response?.data && !error) {
          const options = response.data.memberTypes.map(
            (typeMember: string): SelectOptions => ({
              value: typeMember,
              label: capitalizeText(typeMember),
              key: typeMember,
            }),
          )
          setMemberTypeOptions(options)
        }
      } catch (error) {
        console.error(error)
      } finally {
        setIsLoading(false)
      }
    }

    useEffect(() => {
      if (!isLoading && memberTypeOptions.length === 0) {
        getMemberTypeList()
      }
    }, [])

    return (
      <Select
        {...props}
        ref={ref}
        type="single"
        options={memberTypeOptions}
        testId="select-memberType"
      />
    )
  },
)

export default SelectMemberType
