import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { useAuth0 } from '@auth0/auth0-react'
import { Toaster } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { UserTypes } from '../../../types/user'

import GeneratorOnboarding from './generator'
import SupplierOnBoarding from './supplier'
import ConsumerOnboarding from './consumer'

import SelectComponent, {
  SelectOptions,
} from '../../../components/atoms/select'
import CompanyLogo from '../../../components/atoms/company-logo'

import { UserAuth0 } from '../../../types/auth0'

export type parsedUserType = [
  {
    value: 'GENERATOR'
    label: 'Generator'
  },
  {
    value: 'SUPPLIER'
    label: 'Supplier'
  },
  {
    value: 'CONSUMER'
    label: 'Consumer'
  },
]

const Onboarding: React.FC = () => {
  const { t } = useTranslation('private/index', { keyPrefix: 'onboarding' })

  const { user, logout } = useAuth0<UserAuth0>()

  const handleLogout = useCallback(async () => {
    logout({
      logoutParams: {
        returnTo: window.location.origin,
        federated: false,
      },
    })
  }, [logout])

  const [userType, setUserType] = useState<UserTypes | undefined>()

  useEffect(() => {
    if (process.env.REACT_APP_COMPANY !== 'PPAYA') {
      setUserType('GENERATOR')
    } else if (
      process.env.REACT_APP_COMPANY === 'PPAYA' &&
      !userType &&
      user?.app_metadata?.userType
    ) {
      setUserType(user?.app_metadata.userType)
    }
  }, [user])

  const userTypeOptions = useMemo((): SelectOptions[] => {
    if (process.env.REACT_APP_COMPANY === 'PPAYA') {
      return [
        {
          value: 'GENERATOR',
          label: t('select.options.generator'),
        },
        {
          value: 'SUPPLIER',
          label: t('select.options.supplier'),
        },
        {
          value: 'CONSUMER',
          label: t('select.options.consumer'),
        },
      ]
    }

    return [
      {
        value: 'GENERATOR',
        label: t('select.options.generator'),
      },
    ]
  }, [])

  return (
    <div className="w-full h-full overflow-auto">
      <div className="flex flex-col items-center justify-start">
        <div className="mt-12">
          <CompanyLogo variant="dark" />
        </div>
        <div className="mt-12">
          {!userType && (
            <div className="font-medium text-ppa/title">
              <h1 className="text-3xl mb-8">{t('common.welcome')}</h1>
              <div className="font-normal text-base min-w-[480px] max-w-[520px] flex flex-col gap-y-2 my-8">
                <p>{t('common.title')}</p>
                <p>{t('common.info')}</p>
              </div>
              <h2 className="text-2xl mb-5">{t('select.label')}</h2>
              <div className="font-normal text-sm min-w-[480px] max-w-[600px]">
                <SelectComponent
                  name="userType"
                  onChange={(newUserType) => {
                    setUserType(newUserType)
                  }}
                  value={userType}
                  type="single"
                  options={userTypeOptions}
                  placeholder={t('select.placeholder')}
                  aria-label="userType"
                />
              </div>
            </div>
          )}
        </div>

        <div className="mt-1">
          {userType && userType === 'GENERATOR' && <GeneratorOnboarding />}

          {userType && userType === 'CONSUMER' && <ConsumerOnboarding />}

          {process.env.REACT_APP_COMPANY === 'PPAYA' &&
            userType &&
            userType === 'SUPPLIER' && <SupplierOnBoarding />}
        </div>

        <div className={classNames(!userType ? 'fixed bottom-10' : 'my-10')}>
          <button type="button" onClick={handleLogout}>
            <span className="text-ppa/placeholder text-xs font-normal underline">
              {t('common.signOut')}&nbsp;
              {user?.email || ''}
            </span>
          </button>
        </div>
      </div>

      <Toaster />
    </div>
  )
}

export default Onboarding
