import React, { useState, useEffect } from 'react'
import { useForm, Controller } from 'react-hook-form'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'

import classNames from 'classnames'

import { ReactComponent as ExclamationIcon } from './assets/Exclamation.svg'

import {
  dateFormats,
  priceTypeConsumerTenderList,
} from '../../../../../../../utils/data'

import { validateDecimalNumbers } from '../../../../../../../utils'

import {
  ConsumerBid,
  ConsumerStandingChargePowerPriceType,
  ConsumerCapacityChargePowerPriceType,
} from '../../../../../../../types'

import Input from '../../../../../../../components/atoms/input'
import Button from '../../../../../../../components/atoms/button'
import Message from '../../../../../../../components/atoms/message'
import SelectComponent, {
  SelectOptions,
} from '../../../../../../../components/atoms/select'
import Checkbox from '../../../../../../../components/atoms/checkbox'
import RequiredField from '../../../../../../../components/atoms/required-form-field'
import InputGroup from '../../../../../../../components/molecules/input-group'

export const targetMatchOptions = [
  {
    value: '0.25',
    label: '0% - 25%',
  },
  {
    value: '0.5',
    label: '25% - 50%',
  },
  {
    value: '0.75',
    label: '50% - 75%',
  },
  {
    value: '1',
    label: '75% - 100%',
  },
]

interface FormCharge {
  type:
    | ConsumerStandingChargePowerPriceType
    | ConsumerCapacityChargePowerPriceType
  value: string
}

export interface FormCharges {
  capacity: FormCharge
  standing: FormCharge
}

export type FormData = {
  contractType?: string
  dayRate: {
    value?: string | number
    matchedPowerPrice?: string | number
    unmatchedPowerPrice?: string | number
  }
  nightRate: {
    value?: string | number
    matchedPowerPrice?: string | number
    unmatchedPowerPrice?: string | number
  }
  forecastMatch?: string
  charges: FormCharges
  greenContract?: boolean
  expiresAt: string
}

interface FormProps {
  bid?: ConsumerBid
  brokerFee?: number
  defaultValues: FormData
  onSubmit: (data: any) => void
  isLoading: boolean
}

const BidForm: React.FC<FormProps> = ({
  bid,
  brokerFee,
  defaultValues,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.bids.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors: formErrors, isSubmitting },
  } = useForm<FormData>({
    defaultValues,
  })

  const contractType = watch('contractType')

  const [showSleevedFields, setShowSleevedFields] = useState(false)

  useEffect(() => {
    if (contractType === 'SLEEVED') {
      setShowSleevedFields(true)
    } else {
      setShowSleevedFields(false)
    }
  }, [contractType])

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="sm:w-[510px]">
      <h1 className="text-xl text-ppa/title font-bold mb-5">
        {bid ? t('UpdateBidTitle') : t('placeBidTitle')}
      </h1>

      <div className="flex flex-col gap-y-4 ml-1">
        <Controller
          name="contractType"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('fields.contractType.required'),
            },
          }}
          render={({ field: { ...fieldProps } }) => (
            <InputGroup
              label={t('fields.contractType.label')}
              error={formErrors.contractType?.message}
              required
            >
              <div className="w-full max-w-[230px]">
                <SelectComponent
                  {...fieldProps}
                  error={formErrors.contractType?.message}
                  type="single"
                  options={priceTypeConsumerTenderList.map((item) => ({
                    ...item,
                    label: tUtils(`priceTypeConsumer.${item.label}`),
                  }))}
                  placeholder={t('fields.contractType.placeholder')}
                  testId="select-priceTypeConsumer"
                />
              </div>
            </InputGroup>
          )}
        />

        <Controller
          name="dayRate.value"
          control={control}
          rules={{
            required: {
              value: !showSleevedFields && true,
              message: t('fields.dayRate.required'),
            },
          }}
          render={({ field: { ...fieldProps } }) => (
            <InputGroup
              label={t('fields.dayRate.label')}
              error={formErrors.dayRate?.value?.message}
              required
            >
              {showSleevedFields ? (
                <div className="flex flex-col gap-y-1.5 w-[380px]">
                  <div className="flex items-center justify-between">
                    <span
                      className={classNames(
                        'text-ppa/subtitle text-sm font-medium',
                        formErrors.dayRate?.matchedPowerPrice?.message &&
                          '-mt-10',
                      )}
                    >
                      {t('fields.matchedPowerPrice.label')}
                    </span>
                    <div className="w-full max-w-[180px] my-1">
                      <Controller
                        name="dayRate.matchedPowerPrice"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t('fields.matchedPowerPrice.required'),
                          },
                          validate: {
                            isValidDecimal: validateDecimalNumbers,
                          },
                        }}
                        render={({ field: { ...nestedFieldProps } }) => (
                          <>
                            <Input
                              {...nestedFieldProps}
                              type="text"
                              placeholder="0,00"
                              suffix="p/kWh"
                              error={
                                formErrors.dayRate?.matchedPowerPrice?.message
                              }
                            />
                            {formErrors.dayRate?.matchedPowerPrice?.message && (
                              <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                                <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                                <span className="text-xs font-light text-ppa/warning">
                                  {
                                    formErrors.dayRate?.matchedPowerPrice
                                      ?.message
                                  }
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <span
                      className={classNames(
                        'text-ppa/subtitle text-sm font-medium',
                        formErrors.dayRate?.unmatchedPowerPrice?.message &&
                          '-mt-10',
                      )}
                    >
                      {t('fields.unmatchedPowerPrice.label')}
                    </span>
                    <div className="w-full max-w-[180px] my-1">
                      <Controller
                        name="dayRate.unmatchedPowerPrice"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t('fields.unmatchedPowerPrice.required'),
                          },
                          validate: {
                            isValidDecimal: validateDecimalNumbers,
                          },
                        }}
                        render={({ field: { ...nestedFieldProps } }) => (
                          <>
                            <Input
                              {...nestedFieldProps}
                              type="text"
                              placeholder="0,00"
                              suffix="p/kWh"
                              error={
                                formErrors.dayRate?.unmatchedPowerPrice?.message
                              }
                            />
                            {formErrors.dayRate?.unmatchedPowerPrice
                              ?.message && (
                              <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                                <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                                <span className="text-xs font-light text-ppa/warning">
                                  {
                                    formErrors.dayRate?.unmatchedPowerPrice
                                      ?.message
                                  }
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full max-w-[230px] my-1">
                  <Input
                    {...fieldProps}
                    type="text"
                    placeholder="0.0"
                    suffix="p/kWh"
                    error={formErrors.dayRate?.value?.message}
                  />
                </div>
              )}
            </InputGroup>
          )}
        />

        <Controller
          name="nightRate.value"
          control={control}
          rules={{
            required: {
              value: !showSleevedFields && true,
              message: t('fields.nightRate.required'),
            },
          }}
          render={({ field: { ...fieldProps } }) => (
            <InputGroup
              label={t('fields.nightRate.label')}
              error={formErrors.nightRate?.value?.message}
              required
            >
              <Message variant="info" size="text-xs self-center">
                {t('fields.nightRate.infoMessage')}
              </Message>
              {showSleevedFields ? (
                <div className="flex flex-col gap-y-1.5 w-[380px]">
                  <div className="flex items-center justify-between">
                    <span
                      className={classNames(
                        'text-ppa/subtitle text-sm font-medium',
                        formErrors.nightRate?.matchedPowerPrice?.message &&
                          '-mt-10',
                      )}
                    >
                      {t('fields.matchedPowerPrice.label')}
                    </span>
                    <div className="w-full max-w-[180px] my-1">
                      <Controller
                        name="nightRate.matchedPowerPrice"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t('fields.matchedPowerPrice.required'),
                          },
                          validate: {
                            isValidDecimal: validateDecimalNumbers,
                          },
                        }}
                        render={({ field: { ...nestedFieldProps } }) => (
                          <>
                            <Input
                              {...nestedFieldProps}
                              type="text"
                              placeholder="0,00"
                              suffix="p/kWh"
                              error={
                                formErrors.nightRate?.matchedPowerPrice?.message
                              }
                            />
                            {formErrors.nightRate?.matchedPowerPrice
                              ?.message && (
                              <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                                <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                                <span className="text-xs font-light text-ppa/warning">
                                  {
                                    formErrors.nightRate?.matchedPowerPrice
                                      ?.message
                                  }
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>

                  <div className="flex items-center justify-between">
                    <span
                      className={classNames(
                        'text-ppa/subtitle text-sm font-medium',
                        formErrors.nightRate?.unmatchedPowerPrice?.message &&
                          '-mt-10',
                      )}
                    >
                      {t('fields.unmatchedPowerPrice.label')}
                    </span>
                    <div className="w-full max-w-[180px] my-1">
                      <Controller
                        name="nightRate.unmatchedPowerPrice"
                        control={control}
                        rules={{
                          required: {
                            value: true,
                            message: t('fields.unmatchedPowerPrice.required'),
                          },
                          validate: {
                            isValidDecimal: validateDecimalNumbers,
                          },
                        }}
                        render={({ field: { ...nestedFieldProps } }) => (
                          <>
                            <Input
                              {...nestedFieldProps}
                              type="text"
                              placeholder="0,00"
                              suffix="p/kWh"
                              error={
                                formErrors.nightRate?.unmatchedPowerPrice
                                  ?.message
                              }
                            />
                            {formErrors.nightRate?.unmatchedPowerPrice
                              ?.message && (
                              <span className="text-ppa/placeholder mt-1.5 flex items-start fade-in">
                                <ExclamationIcon className="w-3.5 h-3.5 mr-1" />
                                <span className="text-xs font-light text-ppa/warning">
                                  {
                                    formErrors.nightRate?.unmatchedPowerPrice
                                      ?.message
                                  }
                                </span>
                              </span>
                            )}
                          </>
                        )}
                      />
                    </div>
                  </div>
                </div>
              ) : (
                <div className="w-full max-w-[230px] my-1">
                  <Input
                    {...fieldProps}
                    type="text"
                    placeholder="0.0"
                    suffix="p/kWh"
                    error={formErrors.nightRate?.value?.message}
                  />
                </div>
              )}
            </InputGroup>
          )}
        />

        {contractType === 'SLEEVED' && (
          <Controller
            name="forecastMatch"
            control={control}
            rules={{ required: t('fields.matchPercentage.required') }}
            render={({ field: { ...fieldProps } }) => (
              <InputGroup
                label={t('fields.matchPercentage.label')}
                error={formErrors.forecastMatch?.message}
                required
              >
                <div className="w-full max-w-[230px]">
                  <SelectComponent
                    {...fieldProps}
                    error={formErrors.forecastMatch?.message}
                    type="single"
                    options={targetMatchOptions}
                    placeholder={t('fields.matchPercentage.placeholder')}
                    testId="select-matchPercentage"
                  />
                </div>
              </InputGroup>
            )}
          />
        )}

        <Controller
          name="charges.capacity.value"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('fields.charges.capacity.required'),
            },
            validate: {
              isValidDecimal: validateDecimalNumbers,
            },
          }}
          render={({ field: { ...fieldProps } }) => (
            <InputGroup
              label={t('fields.charges.capacity.label')}
              error={formErrors.charges?.capacity?.value?.message}
              required
            >
              <Message variant="info" size="text-xs self-center">
                {t('fields.charges.capacity.infoMessage')}
              </Message>
              <div className="w-full max-w-[230px] my-1">
                <Input
                  {...fieldProps}
                  type="text"
                  step="0.01"
                  placeholder="0,00"
                  suffix={
                    <Controller
                      name="charges.capacity.type"
                      control={control}
                      render={({ field: { ...nestedFieldProps } }) => (
                        <select
                          {...nestedFieldProps}
                          className="text-right items-center pr-1"
                          data-testid="charges.capacity.chargeType"
                        >
                          <option value="pence_kva_day">p/kVA/day</option>
                          <option value="pound_kva_day">£/kVA/day</option>
                          <option value="pound_kva_month">£/kVA/month</option>
                        </select>
                      )}
                    />
                  }
                  error={formErrors.charges?.capacity?.value?.message}
                />
              </div>
            </InputGroup>
          )}
        />

        <Controller
          name="charges.standing.value"
          control={control}
          rules={{
            required: {
              value: true,
              message: t('fields.charges.standing.required'),
            },
            validate: {
              isValidDecimal: validateDecimalNumbers,
            },
          }}
          render={({ field: { ...fieldProps } }) => (
            <InputGroup
              label={t('fields.charges.standing.label')}
              error={formErrors.charges?.standing?.value?.message}
              required
            >
              <Message variant="info" size="text-xs self-center">
                {t('fields.charges.standing.infoMessage')}
              </Message>
              <div className="w-full max-w-[230px] my-1">
                <Input
                  {...fieldProps}
                  type="text"
                  step="0.01"
                  placeholder="0,00"
                  suffix={
                    <Controller
                      name="charges.standing.type"
                      control={control}
                      render={({ field: { ...nestedFieldProps } }) => (
                        <select
                          {...nestedFieldProps}
                          className="text-right items-center pr-1"
                          data-testid="charges.standing.chargeType"
                        >
                          <option value="pence_day">p/day</option>
                          <option value="pound_day">£/day</option>
                          <option value="pound_month">£/month</option>
                        </select>
                      )}
                    />
                  }
                  error={formErrors.charges?.standing?.value?.message}
                />
              </div>
            </InputGroup>
          )}
        />

        <div className="self-start">
          <Controller
            name="greenContract"
            control={control}
            render={({ field: { ...fieldProps } }) => (
              <div className="flex items-center gap-x-3">
                <Checkbox {...fieldProps} />
                <div>
                  <span className="text-ppa/subtitle text-sm font-medium">
                    {t('fields.greenContract.label')}
                  </span>
                  <Message variant="info" size="text-xs self-center">
                    {t('fields.greenContract.infoMessage')}
                  </Message>
                </div>
              </div>
            )}
          />
        </div>

        <div className="border border-b border-ppa/grayBorder w-full my-3" />

        <div className="flex items-start gap-x-3">
          <Controller
            name="expiresAt"
            control={control}
            rules={{ required: t('fields.expiresAt.required') }}
            render={({ field: { ...fieldProps } }) => (
              <InputGroup
                label={t('fields.expiresAt.label')}
                error={formErrors.expiresAt?.message}
                required
              >
                <Message variant="info" size="text-xs self-center">
                  {t('fields.expiresAt.infoMessage')}
                </Message>
                <div className="w-full max-w-[230px] my-1">
                  <Input
                    type="datetime-local"
                    {...fieldProps}
                    min={dayjs().format(dateFormats.dateInput)}
                    error={formErrors.expiresAt?.message}
                  />
                </div>
              </InputGroup>
            )}
          />
        </div>

        <RequiredField />
      </div>

      <div className="border border-b border-ppa/grayBorder w-full my-7" />

      <div className="flex justify-end mt-5">
        <div>
          <InputGroup label={t('fields.charges.brokerFee.label')}>
            <Message variant="info" size="text-xs self-center">
              {t('fields.charges.brokerFee.infoMessage')}
            </Message>
            <div className="w-60 mt-1 ml-[0.2px]">
              <Input
                readOnly
                disabled
                type="text"
                suffix="£/MWh"
                disabledInput
                value={Number(brokerFee).toFixed(2)}
              />
            </div>
          </InputGroup>
        </div>
      </div>

      <div className="border border-b border-ppa/grayBorder w-full my-7" />

      <div className="flex justify-start">
        <Message variant="warn" size="text-xs mt-1">
          {t('fields.submit.infoMessage')}
        </Message>
      </div>
      <div className="flex justify-end mt-2">
        <Button
          variant="primary"
          type="submit"
          loading={isLoading}
          disabled={isSubmitting}
          formWidth
        >
          {t('fields.submit.button')}
        </Button>
      </div>
    </form>
  )
}

export default BidForm
