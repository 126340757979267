import React, { useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import { dateFormats } from '../../../../../../utils/data'

import PlaceBidForm, { FormData } from './form-place-bid'
import { FormValues as BenefitsFormValues } from './form-benefits'
import { FormValues as ChargesFormValues } from './form-charges'
import { FormValues as GreenCertificatesFormValues } from './form-green-certificates'

import {
  SupplierTenderInvitation,
  SupplierTenderBid,
} from '../../../../../../types'

dayjs.extend(utc)

export type BidFormData = FormData &
  BenefitsFormValues &
  ChargesFormValues &
  GreenCertificatesFormValues & {
    zeroValueGreenCertificatesNames?: string
  }

interface FormProps {
  tenderInvitation: SupplierTenderInvitation
  bid?: SupplierTenderBid
  isDrawerOpen: boolean
  onSuccess: () => void
  closeDrawer: () => void
}

const CreateOrUpdateBid: React.FC<FormProps> = ({
  tenderInvitation,
  bid,
  onSuccess,
  isDrawerOpen,
  closeDrawer,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.sleevedTenders.details.placeBid',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleSubmitPlaceBidForm = async (data: BidFormData) => {
    if (isLoading) return
    setIsLoading(true)

    const powerPrices = () => {
      const { contractType } = data
      switch (contractType) {
        case 'MATCH_AND_UNMATCHED':
          return [
            {
              name: 'MATCHED',
              type: 'FIXED',
              value: data.matchedPowerPrice || 0,
            },
            {
              name: 'UNMATCHED',
              type: 'FIXED',
              value: data.unmatchedPowerPrice || 0,
            },
          ]
        case 'FIXED':
          return [
            {
              name: 'FIXED',
              type: 'FIXED',
              value: data.fixedPowerPrice || 0,
            },
          ]
        case 'PASSTHROUGH':
          return [
            {
              name: 'PASSTHROUGH',
              type: 'FLEXIBLE',
              value: data.passthroughPowerPrice || 0,
            },
          ]
        default:
          return []
      }
    }

    const mapToBidArray = (items: any) => {
      if (!items) return []
      return Object.keys(items).map((name) => {
        const item = items[name]
        return {
          name,
          type: item.type,
          value: parseFloat(item?.value || 0),
        }
      })
    }

    try {
      const { response, error: err } = await fetchData({
        method: 'PUT',
        url: '/core/private/supplier-tender-bid/supplier/',
        body: {
          supplierTenderId: tenderInvitation.supplierTender.id,
          expiresAt: data.expiresAt,
          forecastMatch:
            data?.contractType === 'MATCH_AND_UNMATCHED'
              ? data.matchPercentage
              : null,
          contractType: data.contractType,
          supplierTenderBidPowerPrices: powerPrices(),
          supplierTenderBidBenefits: mapToBidArray(data.embeddedBenefits),
          supplierTenderBidGreenCertificates: mapToBidArray(
            data.greenCertificates,
          ),
          supplierTenderBidCharges: mapToBidArray(data.charges),
        },
      })

      if (err || !response) throw err

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handlePlaceBid.success.description'),
        },
        'success',
      )
      onSuccess()
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'supplierTenderBid',
        NOT_FOUND_ERROR: ({ message }) => {
          if (message.includes('No Bid found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.NOT_FOUND_ERROR.update.notFound',
              ),
              type: 'error',
            }
          }

          if (message.includes('Site brokerFee not found')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.NOT_FOUND_ERROR.reason.CHARGE_BROKER_FEE',
              ),
              type: 'error',
            }
          }
          return undefined
        },
        PAYLOAD_ERROR: ({ message }) => {
          if (message.includes('Bid.checkForInactiveBenefits()')) {
            const [_, benefits] = message.split('Some Benefits are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.PAYLOAD_ERROR.reason.INACTIVE_BENEFITS',
              )}\n${benefits}.`,
              type: 'error',
            }
          }

          if (message.includes('Bid.checkForInactiveSubsidies()')) {
            const [_, sub] = message.split('Some Subsidies are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.PAYLOAD_ERROR.reason.INACTIVE_SUBSIDIES',
              )}\n${sub}.`,
              type: 'error',
            }
          }

          if (message.includes('Bid.checkForInactiveCharges()')) {
            const [_, charges] = message.split('Some Charges are inactive')
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.PAYLOAD_ERROR.title',
              ),
              description: `${tUtils(
                'throwFeedbackError.errorCodes.supplierTenderBid.PAYLOAD_ERROR.reason.INACTIVE_CHARGES',
              )}\n${charges}.`,
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const defaultFormValues = useMemo((): FormData => {
    if (!bid) {
      return {
        contractType: 'MATCH_AND_UNMATCHED',
        matchPercentage: undefined,
        expiresAt: dayjs().hour(17).minute(30).format(dateFormats.dateInput),
      }
    }

    return {
      contractType: bid.contractTypeName,
      matchedPowerPrice:
        bid.supplierTenderBidPowerPrices.find(
          (price) => price.name === 'MATCHED',
        )?.value || '',
      unmatchedPowerPrice:
        bid.supplierTenderBidPowerPrices.find(
          (price) => price.name === 'UNMATCHED',
        )?.value || '',
      fixedPowerPrice:
        bid.contractTypeName === 'FIXED'
          ? bid.supplierTenderBidPowerPrices[0]?.value || ''
          : '',
      passthroughPowerPrice:
        bid.contractTypeName === 'PASSTHROUGH'
          ? bid.supplierTenderBidPowerPrices[0]?.value || ''
          : '',
      matchPercentage: bid.forecastMatch,

      /**
       * @TODO fix format when BE starts saving the dates as UTC
       */
      expiresAt: dayjs
        .utc(bid.expiresAt)
        .tz('Europe/London')
        .format('YYYY-MM-DDTHH:mm:ss'),
    }
  }, [tenderInvitation, isDrawerOpen, bid])

  return (
    <SlidingDrawer isOpen={isDrawerOpen} handleClose={closeDrawer}>
      <div className="flex flex-col gap-y-5 sm:w-[640px]">
        {isDrawerOpen && (
          <PlaceBidForm
            isLoading={isLoading}
            defaultValues={defaultFormValues}
            onSubmit={handleSubmitPlaceBidForm}
            brokerFee={tenderInvitation.supplierTender.site.brokerFee}
            tender={tenderInvitation.supplierTender}
            bid={bid}
          />
        )}
      </div>
    </SlidingDrawer>
  )
}

export default CreateOrUpdateBid
