import React from 'react'

import classNames from 'classnames'

import { InformationCircleIcon } from '@heroicons/react/outline'

import { transition } from '../../../styles'

interface TooltipInfoProps {
  message: string
}

const TooltipInfo: React.FC<TooltipInfoProps> = ({ message }) => (
  <div className="relative group ">
    <InformationCircleIcon className="w-full h-full max-w-[16px] max-h-[16px] stroke-blue-600" />

    <div
      className={classNames(
        transition,
        'w-96 h-12 max-h-14 break-words',
        'absolute top-[10px] left-[45px] p-2 z-10',
        'bg-white rounded-md shadow-md opacity-0 group-hover:opacity-100',
        'text-ppa/sidebarBackground text-xs font-light font-lexend leading-4',
      )}
    >
      {message}
    </div>
  </div>
)

export default TooltipInfo
