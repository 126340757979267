import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import Message from '../../../../../../components/atoms/message'
import RequiredField from '../../../../../../components/atoms/required-form-field'
import Button from '../../../../../../components/atoms/button'
import InputFile from '../../../../../../components/atoms/input-file'
import InputGroup from '../../../../../../components/molecules/input-group'

type FormValues = {
  hhdFile?: File
}

interface HHDFormProps {
  onSubmit: (data: any) => void
  onSkip?: () => void
  onCancel?: () => void
  isLoading?: boolean
}
export type HHDFormRef = {
  resetForm: () => void
}

const HHDForm: React.FC<HHDFormProps> = ({
  onSubmit,
  isLoading,
  onSkip,
  onCancel,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.hhdDrawer',
  })

  const {
    control,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<FormValues>({})

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="flex flex-col gap-y-5 min-w-[510px]"
    >
      <Message variant="info">{t('infoMessage')}</Message>
      <Controller
        name="hhdFile"
        control={control}
        rules={{
          required: { value: true, message: t('file.required') },
        }}
        render={({ field: { name } }) => (
          <InputGroup
            label={t('file.title')}
            error={errors.hhdFile?.message}
            infoLabel={t('file.infoLabel')}
            required
          >
            <InputFile
              name={name}
              setValue={setValue}
              error={errors.hhdFile?.message}
              acceptFiles="dataText"
            />
          </InputGroup>
        )}
      />

      <div>
        <RequiredField />
      </div>

      <div className="flex items-center justify-end gap-x-5">
        {onCancel && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              variant="quaternary"
              onClick={onCancel}
              fullWidth
              disabled={isLoading}
            >
              {t('cancel')}
            </Button>
          </div>
        )}
        {onSkip && (
          <div className="max-w-[100px] w-full">
            <Button
              type="button"
              variant="quaternary"
              onClick={onSkip}
              fullWidth
              disabled={isLoading}
            >
              {t('skip')}
            </Button>
          </div>
        )}
        <div className="max-w-[150px] w-full">
          <Button type="submit" variant="primary" fullWidth loading={isLoading}>
            {t('submit')}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default HHDForm
