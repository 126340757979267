import { useMemo, useImperativeHandle, forwardRef } from 'react'

import useUser from '../../../hooks/use-user'
import usePPAGetList from '../../../hooks/use-ppa/use-ppa-get-list'

import Select, { SelectOptions, SelectProps } from '../../atoms/select'

import { Company } from '../../../types'

type SelectPPACompaniesProps = Omit<SelectProps, 'options'>

export const parseCompanyToSelect = (company: Company): SelectOptions => {
  const label = (
    <div className="leading-tight text-sm">
      <strong className="font-medium">
        {company.name}&nbsp;({company.number})
      </strong>
      <span className="text-xs block truncate">
        {company?.address?.addressLine1}
      </span>
    </div>
  )

  return {
    label,
    value: JSON.stringify(company),
  }
}

export interface SelectCompaniesRef {
  refetch: () => void
}

const SelectPPACompanies = forwardRef<
  SelectCompaniesRef,
  SelectPPACompaniesProps
>(({ ...props }, ref) => {
  const { user } = useUser()

  const { data, isLoading, error, reset } = usePPAGetList<Company>({
    dataKey: 'companies',
    path: `/core/private/${user.appMetadata.userType.toLowerCase()}/company/list`,
    pagination: {
      offset: 0,
      limit: 100,
    },
  })

  const options = useMemo((): SelectOptions[] => {
    if (!data || isLoading || error) return []

    return data.map((company: Company) => {
      return {
        ...parseCompanyToSelect(company),
        key: company.id,
      }
    })
  }, [data, isLoading, error])

  useImperativeHandle(ref, () => ({ refetch: () => reset() }), [])

  return (
    <Select
      {...props}
      options={options}
      type="single"
      testId="select-ppa-company"
    />
  )
})

export default SelectPPACompanies
