import React, { useEffect, useMemo } from 'react'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { ConsumerBid } from '../../../../../types'

import TableConsumerBids from '../../../../../components/organisms/consumer-bids-table'

import TenderDetails from '../components/details'

import { TenderStatusProps } from '../details'

const ConsumerWithdrawnTenderDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
  refetch,
}) => {
  const { isLoading, throwFeedbackError, data, error } =
    usePPAGetList<ConsumerBid>({
      dataKey: 'consumerTenderBids',
      path: `/core/private/consumer-tender-bid/admin/${tenderDetails.id}`,
    })

  const bidList = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [bidList])
  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} />
      <TableConsumerBids
        bids={bidList}
        tenderStatus={tenderDetails.status}
        refetch={refetch}
      />
    </section>
  )
}

export default ConsumerWithdrawnTenderDetails
