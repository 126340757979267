import React, { useMemo, useEffect } from 'react'
import { useTranslation } from 'react-i18next'

import usePPAGetList from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import Select, {
  SelectProps,
  SelectOptions,
} from '../../../../../../components/atoms/select'

import { capitalizeText } from '../../../../../../utils'

type TechnologyResponse = { name: string; active: true }

type SelectTechnologyProps = Omit<
  SelectProps,
  'type' | 'onCreateOption' | 'options'
>

const SelectTechnologies: React.FC<SelectTechnologyProps> = ({ ...props }) => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { isLoading, throwFeedbackError, data, error } =
    usePPAGetList<TechnologyResponse>({
      dataKey: 'technologies',
      path: '/core/private/default-values/technology',
    })

  const technologiesList = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const options = useMemo((): SelectOptions[] => {
    if (!technologiesList) return []

    return technologiesList.map((technology) => {
      const tech = capitalizeText(
        tUtils(`technology.${technology.name}.fullName`),
      )

      return {
        value: technology.name,
        label: tech,
        key: technology.name,
      }
    })
  }, [technologiesList])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return (
    <Select
      {...props}
      options={options}
      type="single"
      testId="select-technology"
    />
  )
}

export default SelectTechnologies
