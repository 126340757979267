import { useEffect, useMemo } from 'react'
import { useTranslation } from 'react-i18next'
import { useForm, Controller } from 'react-hook-form'

import classNames from 'classnames'

import { getCountryOptionsData } from '../../../utils'

import Button from '../../../components/atoms/button'
import Input from '../../../components/atoms/input'
import Checkbox from '../../../components/atoms/checkbox'
import RequiredField from '../../../components/atoms/required-form-field'
import SelectComponent, {
  SelectOptions,
} from '../../../components/atoms/select'

import InputGroup from '../../../components/molecules/input-group'
import SelectCompaniesHouse from '../../../components/molecules/select-companies-house'

import { transition } from '../../../styles'

interface Address {
  addressLine1?: string
  addressLine2?: string
  postalCode?: string
  region?: string
  locality?: string
  country?: string
}

export interface Company {
  name: string
  number: string
  type?: string
  address: Address
  enableBillingAddress?: boolean
  billingAddress?: Address
}

export type FormValues = {
  company?: SelectOptions
  name?: string
  number?: string
  type?: string
  address?: Address
  enableBillingAddress?: boolean
  billingAddress?: Address
}

const formDefaultValues: FormValues = {
  company: undefined,
  number: undefined,
  name: undefined,
  type: undefined,
  address: {
    addressLine1: undefined,
    addressLine2: undefined,
    postalCode: undefined,
    region: undefined,
    locality: undefined,
  },
  enableBillingAddress: false,
  billingAddress: {
    addressLine1: undefined,
    addressLine2: undefined,
    postalCode: undefined,
    region: undefined,
    locality: undefined,
  },
}

interface RegisterCompanyProps {
  isLoading?: boolean
  handleClose: () => void
  handleSubmitCompany: (company: Company) => Promise<void>
  defaultValues?: FormValues
}

const RegisterNewCompanyForm: React.FC<RegisterCompanyProps> = ({
  handleSubmitCompany,
  handleClose,
  isLoading = false,
  defaultValues,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'registerCompany',
  })

  const countryOptions = useMemo(() => getCountryOptionsData(), [])

  const mappedCountryOptions = useMemo(() => {
    return (
      countryOptions.map((country) => ({
        value: country.alpha3,
        label: country.country,
        key: country.alpha3,
      })) || []
    )
  }, [countryOptions])

  const {
    control,
    watch,
    handleSubmit,
    setValue,
    reset,
    formState: { errors },
  } = useForm<FormValues>({
    defaultValues: formDefaultValues,
  })

  const handleSubmitForm = async (formData: any) => {
    const {
      name,
      number,
      type,
      address,
      enableBillingAddress,
      billingAddress,
    } = formData

    const companyPayload: Company = {
      name,
      number: number || undefined,
      type: type || undefined,
      address,
      billingAddress: address,
    }

    if (enableBillingAddress) companyPayload.billingAddress = billingAddress

    handleSubmitCompany(companyPayload)
  }

  useEffect(() => {
    if (watch('company.value')) {
      const {
        name,
        number,
        companyNumber,
        type,
        companyType,
        address,
        completeAddress,
      } = JSON.parse(watch('company.value')) || {}

      /**
       * @TODO this type will be changes after select async component is rewied
       */
      let country: SelectOptions | undefined | any

      if (address?.country && !completeAddress?.country) {
        const filteredCoutries = mappedCountryOptions.filter(
          (item) => item.value === address.country,
        )
        if (filteredCoutries.length > 0) {
          country = filteredCoutries[0]
        }
      } else if (completeAddress?.country && !address?.country) {
        const filteredCoutries = mappedCountryOptions.filter(
          (item) => item.label === completeAddress.country,
        )
        if (filteredCoutries.length > 0) {
          country = filteredCoutries[0]
        }
      }

      setValue('name', name)
      setValue('number', number || companyNumber)
      setValue('type', type || companyType)
      setValue('address', {
        addressLine1: address?.addressLine1 || completeAddress?.address_line_1,
        addressLine2: address?.addressLine2 || completeAddress?.address_line_2,
        postalCode: address?.postalCode || completeAddress?.postal_code,
        region: address?.region || completeAddress?.region,
        locality: address?.locality || completeAddress?.locality,
        country,
      })
      setValue('address.country', country)
    }
  }, [watch('company')])

  useEffect(() => {
    reset(defaultValues || formDefaultValues)
  }, [defaultValues])

  return (
    <div>
      <section className="flex flex-col text-ppa/title gap-y-3">
        <form
          className={classNames(
            transition,
            'fade-in',
            'flex flex-col gap-y-3 w-full min-w-[500px] max-w-[510px]',
          )}
          onSubmit={handleSubmit(handleSubmitForm)}
        >
          <div className="flex flex-col items-start justify-start">
            <h1 className="text-xl font-bold mb-3">
              {defaultValues ? t('editCompanyTitle') : t('addCompanyTitle')}
            </h1>
          </div>

          <Controller
            name="company"
            control={control}
            render={({ field: { ref: _, onChange, ...props } }) => (
              <InputGroup
                label={t('company.title')}
                error={errors.company?.message}
              >
                <SelectCompaniesHouse
                  {...props}
                  onChange={onChange}
                  placeholder={t('company.placeholder')}
                />
              </InputGroup>
            )}
          />
          <div className="w-full border" />
          <Controller
            name="name"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('name.required'),
              },
            }}
            render={({ field: props }) => (
              <InputGroup
                label={t('name.title')}
                error={errors.name?.message}
                required
              >
                <Input
                  {...props}
                  placeholder={t('name.placeholder')}
                  error={errors.name?.message}
                  attribute="organization"
                />
              </InputGroup>
            )}
          />
          <div className="flex flex-col gap-3 md:flex-row">
            <Controller
              name="number"
              control={control}
              render={({ field: props }) => (
                <InputGroup
                  label={t('number.title')}
                  error={errors.number?.message}
                >
                  <Input
                    {...props}
                    placeholder={t('number.placeholder')}
                    error={errors.number?.message}
                  />
                </InputGroup>
              )}
            />

            <Controller
              name="type"
              control={control}
              render={({ field: props }) => (
                <InputGroup
                  label={t('type.title')}
                  error={errors.type?.message}
                >
                  <Input
                    {...props}
                    placeholder={t('type.placeholder')}
                    error={errors.type?.message}
                  />
                </InputGroup>
              )}
            />
          </div>

          <Controller
            name="address.addressLine1"
            control={control}
            rules={{
              required: {
                value: true,
                message: t('addressLine1.required'),
              },
            }}
            render={({ field: props }) => (
              <InputGroup
                label={t('addressLine1.title')}
                error={errors.address?.addressLine1?.message as any}
                required
              >
                <Input
                  {...props}
                  placeholder={t('addressLine1.placeholder')}
                  error={errors.address?.addressLine1?.message}
                  attribute="address-line1"
                />
              </InputGroup>
            )}
          />
          <Controller
            name="address.addressLine2"
            control={control}
            render={({ field: props }) => (
              <InputGroup label={t('addressLine2.title')}>
                <Input
                  {...props}
                  placeholder={t('addressLine2.placeholder')}
                  attribute="address-line2"
                />
              </InputGroup>
            )}
          />
          <div className="flex flex-col gap-3 md:flex-row">
            <Controller
              name="address.postalCode"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('postalCode.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('postalCode.title')}
                  error={errors.address?.postalCode?.message as any}
                  required
                >
                  <Input
                    {...props}
                    placeholder={t('postalCode.placeholder')}
                    error={errors.address?.postalCode?.message}
                    attribute="postal-code"
                  />
                </InputGroup>
              )}
            />
            <Controller
              name="address.locality"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('locality.required'),
                },
              }}
              render={({ field: props }) => (
                <InputGroup
                  label={t('locality.title')}
                  error={errors.address?.locality?.message as any}
                  required
                >
                  <Input
                    {...props}
                    placeholder={t('locality.placeholder')}
                    error={errors.address?.locality?.message}
                  />
                </InputGroup>
              )}
            />
          </div>
          <div className="flex flex-col gap-3 md:flex-row">
            <Controller
              name="address.region"
              control={control}
              render={({ field: props }) => (
                <InputGroup
                  label={t('region.title')}
                  error={errors.address?.region?.message as any}
                >
                  <Input
                    {...props}
                    placeholder={t('region.placeholder')}
                    error={errors.address?.region?.message}
                    attribute="address-level1"
                  />
                </InputGroup>
              )}
            />
            <Controller
              name="address.country"
              control={control}
              rules={{
                required: {
                  value: true,
                  message: t('country.required'),
                },
              }}
              render={({ field: { ref: _, ...props } }) => (
                <InputGroup
                  label={t('country.title')}
                  error={errors?.address?.country?.message as any}
                  required
                >
                  <SelectComponent
                    {...props}
                    type="single"
                    options={mappedCountryOptions}
                    placeholder={t('country.placeholder')}
                    error={errors?.address?.country?.message}
                    testId="select-country"
                  />
                </InputGroup>
              )}
            />
          </div>
          <div className="flex flex-col">
            <Controller
              name="enableBillingAddress"
              control={control}
              render={({ field: props }) => (
                <div className="flex items-center justify-start">
                  <Checkbox
                    label={t('enableBillingAddress.title')}
                    {...props}
                  />
                </div>
              )}
            />
            {watch('enableBillingAddress') && (
              <div
                className={classNames(
                  'flex flex-col',
                  'pt-5 mt-3 gap-y-3',
                  'border-t border-ppa/grayBorder',
                  'md:border-t-0 md:border-l md:pt-5 md:pl-5',
                )}
              >
                <Controller
                  name="billingAddress.addressLine1"
                  control={control}
                  rules={{
                    required: {
                      value: true,
                      message: t('addressLine1.required'),
                    },
                  }}
                  render={({ field: props }) => (
                    <InputGroup
                      label={t('addressLine1.title')}
                      error={
                        errors.billingAddress?.addressLine1?.message as any
                      }
                      required
                    >
                      <Input
                        {...props}
                        placeholder={t('addressLine1.placeholder')}
                        error={errors.address?.addressLine1?.message}
                        attribute="address-line1"
                      />
                    </InputGroup>
                  )}
                />
                <Controller
                  name="billingAddress.addressLine2"
                  control={control}
                  render={({ field: props }) => (
                    <InputGroup label={t('addressLine2.title')}>
                      <Input
                        {...props}
                        placeholder={t('addressLine2.placeholder')}
                        error={errors.billingAddress?.addressLine2?.message}
                        attribute="address-line2"
                      />
                    </InputGroup>
                  )}
                />
                <div className="flex flex-col gap-3 md:flex-row">
                  <Controller
                    name="billingAddress.postalCode"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('postalCode.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('postalCode.title')}
                        error={
                          errors.billingAddress?.postalCode?.message as any
                        }
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t('postalCode.placeholder')}
                          error={errors.billingAddress?.postalCode?.message}
                          attribute="postal-code"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="billingAddress.locality"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('locality.required'),
                      },
                    }}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('locality.title')}
                        error={errors.billingAddress?.locality?.message as any}
                        required
                      >
                        <Input
                          {...props}
                          placeholder={t('locality.placeholder')}
                          error={errors.billingAddress?.locality?.message}
                        />
                      </InputGroup>
                    )}
                  />
                </div>
                <div className="flex flex-col gap-3 md:flex-row">
                  <Controller
                    name="billingAddress.region"
                    control={control}
                    render={({ field: props }) => (
                      <InputGroup
                        label={t('region.title')}
                        error={errors.billingAddress?.region?.message as any}
                      >
                        <Input
                          {...props}
                          placeholder={t('region.placeholder')}
                          error={errors.billingAddress?.region?.message}
                          attribute="address-level1"
                        />
                      </InputGroup>
                    )}
                  />
                  <Controller
                    name="billingAddress.country"
                    control={control}
                    rules={{
                      required: {
                        value: true,
                        message: t('country.required'),
                      },
                    }}
                    render={({ field: { ref: _, ...props } }) => (
                      <InputGroup
                        label={t('country.title')}
                        error={errors.billingAddress?.country?.message as any}
                        required
                      >
                        <SelectComponent
                          {...props}
                          type="single"
                          options={mappedCountryOptions}
                          placeholder={t('country.placeholder')}
                          error={errors.billingAddress?.country?.message}
                        />
                      </InputGroup>
                    )}
                  />
                </div>
              </div>
            )}
          </div>
          <RequiredField label="required fields" />
          <div className="flex items-center justify-end w-full gap-x-5 mt-5">
            <div className="w-[100px]">
              <Button
                type="button"
                variant="quaternary"
                fullWidth
                onClick={handleClose}
                loading={isLoading}
                disabled={isLoading}
              >
                {t('back')}
              </Button>
            </div>
            <div className="w-[150px] ">
              <Button
                type="submit"
                variant="primary"
                fullWidth
                loading={isLoading}
                disabled={isLoading}
              >
                {t('submit')}
              </Button>
            </div>
          </div>
        </form>
      </section>
    </div>
  )
}

export default RegisterNewCompanyForm
