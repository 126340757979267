import React, { useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import usePPAGetList from '../../../../../../hooks/use-ppa/use-ppa-get-list'

import Select, {
  SelectProps,
  SelectOptions,
} from '../../../../../../components/atoms/select'

import { MeterCompany } from '../../../../../../types'

type SelectMeterProps = Omit<
  SelectProps,
  'type' | 'onCreateOption' | 'options'
> & {
  type?: 'multi' | 'single'
}

const SelectMeter: React.FC<SelectMeterProps> = ({
  value,
  type = 'multi',
  ...props
}) => {
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { isLoading, data, error } = usePPAGetList<MeterCompany>({
    dataKey: 'meters',
    path: '/core/private/consumer-meter/consumer/list',
    pagination: {
      limit: 50,
      offset: 0,
    },
  })

  const meterList = useMemo((): MeterCompany[] | undefined => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, error, tUtils])

  const options = useMemo((): SelectOptions[] => {
    if (!meterList) return []

    let selectedLabel: string | undefined
    if (value && value.length > 0) selectedLabel = value[0].label

    return meterList.map((meter) => {
      return {
        value: JSON.stringify(meter),
        label: meter.name,
        key: meter.name,
      }
    })
  }, [meterList, value])

  return (
    <Select
      {...props}
      value={value}
      options={options}
      type={type}
      testId="select-meters"
    />
  )
}

export default SelectMeter
