import { useEffect, useMemo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import classNames from 'classnames'
import {
  RefreshIcon,
  TableIcon,
  ChartSquareBarIcon,
} from '@heroicons/react/outline'

import usePPA from '../../../../../hooks/use-ppa/index'
import { parseQuery } from '../../../../../hooks/use-ppa/use-ppa-get-list'
import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'

import { feedbackMessage } from '../../../../../components/atoms/feedback'
import Select, { SelectOptions } from '../../../../../components/atoms/select'

import { transition } from '../../../../../styles'

import { ReactComponent as Spinner } from '../../../../../assets/spinner/spinner.svg'

import { ReactComponent as PowerIcon } from './assets/powerIcon.svg'

import SpillDayAheadChart from '../dayahead-spill-chart'
import ForecastTable, {
  convertPeriodToSortableValue,
  PowerListData,
} from './forecast-table'
import ForecastChart from './forecast-chart'
import { ChartData } from './types'

const parseChartData = (pricesChartData: any[]): ChartData => {
  const dates: string[] = []
  const prices: number[] = []

  pricesChartData
    .filter((item) => {
      if (!item?.createdAtDate) return false

      return item?.tradePrice || item?.openPrice || item?.historicalClose
    })
    .forEach((item) => {
      dates.push(item.createdAtDate)
      if (item?.tradePrice) prices.push(item.tradePrice)
      else if (item?.openPrice) prices.push(item.openPrice)
      else if (item?.historicalClose) prices.push(item.historicalClose)
    })

  return { dates, prices }
}

const ForecastPower: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'market.forecast',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData } = usePPA()
  const [selectedPeriod, setSelectedPeriod] = useState<
    SelectOptions | undefined
  >(undefined)
  const [isChartShowing, setIsChartShowing] = useState(false)
  const [isRequestingChartData, setIsRequestingChartData] = useState(false)
  const [chartData, setChartData] = useState<undefined | ChartData>()

  const { isLoading, throwFeedbackError, data, error, refetch } =
    usePPAGetDetails<PowerListData>({
      dataKey: 'marketDataResult',
      path: '/market/private/power/list',
    })

  const requestChartData = async (periodType: string): Promise<void> => {
    if (isRequestingChartData) return
    setIsRequestingChartData(true)

    let periodUrl: string
    let queryUrl: any

    if (periodType === 'Day') {
      periodUrl = 'day'
      queryUrl = {}
    } else if (periodType.includes('Q-')) {
      const [quarter, year] = periodType.split('-')
      periodUrl = 'quarter'
      queryUrl = {
        filters: [
          {
            field: 'priceType',
            operator: 'in',
            value: [quarter],
          },
          {
            field: 'year',
            operator: 'equals',
            value: year,
          },
        ],
      }
    } else if (periodType.includes('Summer') || periodType.includes('Winter')) {
      const [season, year] = periodType.split('-')
      periodUrl = 'season'
      queryUrl = {
        filters: [
          {
            field: 'priceType',
            operator: 'in',
            value: [season],
          },
          {
            field: 'year',
            operator: 'equals',
            value: year,
          },
        ],
      }
    } else {
      const [month, year] = periodType.split('-')
      periodUrl = 'month'
      queryUrl = {
        filters: [
          {
            field: 'priceType',
            operator: 'in',
            value: [month],
          },
          {
            field: 'year',
            operator: 'equals',
            value: year,
          },
        ],
      }
    }

    try {
      const { error: err, response } = await fetchData({
        method: 'GET',
        url: `/market/private/power/${periodUrl}?${parseQuery({
          ...queryUrl,
          pagination: {
            offset: 0,
            limit: 50,
          },
        })}`,
      })
      setIsRequestingChartData(false)

      if (!response || err) throw err

      const parsedChartData = parseChartData(response.data.marketDataResult)
      setChartData(parsedChartData)
      setIsChartShowing(true)
    } catch (err: any) {
      setIsRequestingChartData(false)
      feedbackMessage(
        {
          title: tUtils('feedbackMessage.error.title'),
          description: tUtils('feedbackMessage.error.description'),
        },
        'error',
      )
    }
  }

  const periodOptions = useMemo(() => {
    if (error || !data || isLoading) return []

    return Object.keys(data)
      .map((item) => {
        let label: string

        if (item === 'Day') label = t('table.dayAhead')
        else if (item.includes('Summer') || item.includes('Winter')) {
          const [season, year] = item.split('-')
          label = `${t(`table.seasons.${season}`)} ${year}`
        } else if (item.includes('Q')) {
          const [quarter, year] = item.split('Q-')
          label = `${t('table.quarter')}${quarter} ${year}`
        } else {
          const [month, year] = item.split('-')
          label = `${t(`table.months.${month}`)} ${year}`
        }
        return {
          value: item,
          label,
        }
      })
      .sort((a, b) => {
        const periodA = Object.keys(a)[0]
        const periodB = Object.keys(b)[0]

        const orderA = convertPeriodToSortableValue(periodA)
        const orderB = convertPeriodToSortableValue(periodB)

        return orderA - orderB
      })
  }, [error, data, isLoading])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  return (
    <div className="flex flex-col">
      <div className="flex items-center gap-x-2">
        <PowerIcon />
        <h1 className="text-ppa/title text-2xl font-semibold" id="POWER">
          {t('label.power')}
        </h1>

        {!isLoading && error && (
          <RefreshIcon
            className="w-5 h-5 text-ppa/primary cursor-pointer hover:brightness-75"
            onClick={refetch}
          />
        )}
      </div>

      <div className="flex flex-col">
        <h2 className="text-ppa/title text-xl font-semibold my-7">
          {t('label.periodType')}
        </h2>

        <SpillDayAheadChart isLoading={isLoading} />
      </div>

      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoading && data && (
        <>
          <div className="flex items-center justify-between">
            <div className="flex items-center gap-x-4">
              <h2 className="text-ppa/title text-xl font-semibold my-7">
                {t('label.forecast')}
              </h2>

              {isChartShowing && (
                <Select
                  options={periodOptions}
                  name="select-powerChartData"
                  onChange={(newOption) => {
                    if (!newOption) {
                      setSelectedPeriod(undefined)
                      return
                    }
                    setSelectedPeriod(newOption)
                    requestChartData(newOption)
                  }}
                  value={selectedPeriod}
                />
              )}
            </div>

            <div className="flex items-center">
              <div
                className={classNames(
                  'flex border-r-2 border-r-ppa/grayPlaceholder pr-2',
                )}
              >
                <TableIcon
                  className={classNames(
                    transition,
                    !isChartShowing ? 'text-ppa/primary' : 'text-ppa/secondary',
                    'w-7 h-7 my-1 border-b border-transparent',
                    'hover:brightness-25 cursor-pointer',
                    'border-b border-b-transparent',
                  )}
                  onClick={() => setIsChartShowing(false)}
                />
              </div>
              <div className="flex pl-2">
                <ChartSquareBarIcon
                  className={classNames(
                    transition,
                    isChartShowing ? 'text-ppa/primary' : 'text-ppa/secondary',
                    'w-7 h-7 my-1 border-b border-transparent',
                    'hover:brightness-20 cursor-pointer',
                    'border-b border-b-transparent',
                    isChartShowing && ' border-b-ppa/orange',
                  )}
                  onClick={() => {
                    setIsChartShowing(true)
                    if (selectedPeriod?.value) {
                      return
                    }

                    setSelectedPeriod(periodOptions[0])
                    requestChartData(periodOptions[0].value)
                  }}
                />
              </div>
            </div>
          </div>

          {isChartShowing ? (
            <ForecastChart
              commodity="POWER"
              data={chartData}
              title={selectedPeriod?.label as string}
              isLoading={isRequestingChartData}
            />
          ) : (
            <div className="w-full flex fade-in">
              <ForecastTable
                commodity="POWER"
                data={data}
                onClickRow={(row) => {
                  const [key] = Object.keys(row)
                  const keyInPeriods = periodOptions.filter(
                    (item) => item.value === key,
                  )

                  if (keyInPeriods.length === 0) return
                  setSelectedPeriod(keyInPeriods[0])
                  requestChartData(key)
                }}
              />
            </div>
          )}
        </>
      )}
    </div>
  )
}

export default ForecastPower
