import React, { useEffect } from 'react'

import usePPAGetList from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { TenderStatusProps } from '../details'

import TenderDetails from '../components/details'
import BidsTable, { Bid } from '../../../../../components/organisms/bids-table'

const TendersWithdrawnDetails: React.FC<TenderStatusProps> = ({
  tenderDetails,
}) => {
  const bidGetList = usePPAGetList<Bid>({
    dataKey: 'bids',
    path: `/core/private/bid/by-admin/${tenderDetails.id}`,
  })

  useEffect(() => {
    if (bidGetList?.error) {
      bidGetList.throwFeedbackError({ err: bidGetList?.error })
    }
  }, [bidGetList])

  return (
    <section className="flex flex-col gap-y-8">
      <TenderDetails tender={tenderDetails} />
      <BidsTable
        tender={tenderDetails}
        bids={bidGetList?.data}
        error={bidGetList.error}
        isLoading={bidGetList.isLoading}
        refetch={bidGetList.reset}
      />
    </section>
  )
}

export default TendersWithdrawnDetails
