/* eslint-disable dot-notation */
/* eslint-disable no-restricted-syntax */
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import classNames from 'classnames'

import { TrashIcon } from '@heroicons/react/outline'

import usePPA from '../../../../../../hooks/use-ppa'

import { transition } from '../../../../../../styles'

import { targetMatchOptions } from '../create-edit-bid/form-place-bid'

import Table from '../../../../../../components/atoms/table'
import BidPill from '../../../../components/bid-pill'
import Button, {
  Props as ButtonProps,
} from '../../../../../../components/atoms/button'
import DefList from '../../../../../../components/atoms/def-list'
import { feedbackMessage } from '../../../../../../components/atoms/feedback'

import {
  ConsumerBid,
  ConsumerChargeType,
  ConsumerChargeName,
} from '../../../../../../types'

const transformChargesToSet = (charges: ConsumerChargeType[]) => {
  const chargesSet: { [key in ConsumerChargeName]?: ConsumerChargeType } = {}
  for (const charge of charges) {
    chargesSet[charge.name] = charge
  }
  return chargesSet
}
export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: () => void
}

export interface BidTableProps {
  bid: ConsumerBid
  refetchBidList: () => void
  buttons?: ButtonList[]
}

const BidTable: React.FC<BidTableProps> = ({
  bid,
  buttons,
  refetchBidList,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.consumerTenders.details.bidTable',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)

  const handleDeleteBid = async () => {
    if (isLoading) return
    setIsLoading(true)

    try {
      const { error } = await fetchData({
        method: 'DELETE',
        url: `/core/private/consumer-tender-bid/supplier/${bid.id}`,
      })

      if (error) throw error

      refetchBidList()

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleDeleteBid.success.description'),
        },
        'success',
      )
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'bid',
        AUTHORIZATION_ERROR: ({ message }) => {
          if (
            message?.includes(
              'You do not have permission to change the Bid to this status',
            )
          ) {
            return {
              title: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.title',
              ),
              description: tUtils(
                'throwFeedbackError.errorCodes.bid.ERR_NOT_AUTHORIZED.reason.STATUS_PERMISSION',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  const rowKeys = useMemo(() => {
    const details = [
      {
        keyName: 'contractType',
        title: t('list.titles.contractType.title'),
        bidTable: true,
        renderCustomEl: (item: ConsumerBid) => {
          if (!item.contractType) return undefined
          return t(`list.titles.contractType.options.${item.contractType}`)
        },
      },
      {
        keyName: 'dayRate',
        title: t('list.titles.dayRate.title'),
        smallTitle: t('list.titles.dayRate.smallTitle'),
        ...(bid.contractType === 'SLEEVED' && {
          additionalTitles: [
            { text: t('list.titles.matched') },
            { text: t('list.titles.unmatched') },
          ],
        }),
        bidTable: true,
        renderCustomEl: (item: ConsumerBid) => {
          let matchedDayRate = null
          let unmatchedDayRate = null

          if (item.matchedRates?.length) {
            matchedDayRate = item.matchedRates.find(
              (rate) =>
                rate.rateType === 'DAY_RATE' && rate.matchType === 'MATCHED',
            )?.value
            unmatchedDayRate = item.matchedRates.find(
              (rate) =>
                rate.rateType === 'DAY_RATE' && rate.matchType === 'UNMATCHED',
            )?.value
          }

          return (
            <>
              {matchedDayRate && unmatchedDayRate && (
                <div className="mt-12">
                  {matchedDayRate && <div>{matchedDayRate} p/kWh</div>}
                  {unmatchedDayRate && <div>{unmatchedDayRate} p/kWh</div>}
                </div>
              )}
              {!matchedDayRate && !unmatchedDayRate && item.dayRate && (
                <div className="mt-2.5">{item.dayRate} p/kWh</div>
              )}
            </>
          )
        },
      },
      {
        keyName: 'nightRate',
        title: t('list.titles.nightRate.title'),
        smallTitle: t('list.titles.nightRate.smallTitle'),
        ...(bid.contractType === 'SLEEVED' && {
          additionalTitles: [
            { text: t('list.titles.matched') },
            { text: t('list.titles.unmatched') },
          ],
        }),
        bidTable: true,
        renderCustomEl: (item: ConsumerBid) => {
          let matchedNightRate = null
          let unmatchedNightRate = null

          if (item.matchedRates?.length) {
            matchedNightRate = item.matchedRates.find(
              (rate) =>
                rate.rateType === 'NIGHT_RATE' && rate.matchType === 'MATCHED',
            )?.value
            unmatchedNightRate = item.matchedRates.find(
              (rate) =>
                rate.rateType === 'NIGHT_RATE' &&
                rate.matchType === 'UNMATCHED',
            )?.value
          }

          return (
            <>
              {matchedNightRate && unmatchedNightRate && (
                <div className="mt-12">
                  {matchedNightRate && <div>{matchedNightRate} p/kWh</div>}
                  {unmatchedNightRate && <div>{unmatchedNightRate} p/kWh</div>}
                </div>
              )}
              {!matchedNightRate && !unmatchedNightRate && item.nightRate && (
                <div className="mt-2.5">{item.nightRate} p/kWh</div>
              )}
            </>
          )
        },
      },
      ...(bid?.contractType === 'SLEEVED'
        ? [
            {
              keyName: 'forecastMatch',
              title: t('list.titles.forecastMatch'),
              bidTable: true,
              renderCustomEl: (item: ConsumerBid) => {
                if (!item.forecastMatch) return undefined
                const matchOption = targetMatchOptions.find(
                  (option) => option.value === item.forecastMatch,
                ) || { label: '' }
                return matchOption.label
              },
            },
          ]
        : []),
      {
        keyName: 'capacity',
        title: t('list.titles.capacity'),
        suffix: 'p/kWh',
        bidTable: true,
        renderCustomEl: (item: ConsumerBid) => {
          if (!item.consumerBidCharges) return undefined
          const chargesSet = transformChargesToSet(item.consumerBidCharges)
          const capacityCharge = chargesSet['CAPACITY_CHARGE']
          return capacityCharge && capacityCharge.value
        },
      },
      {
        keyName: 'standing',
        title: t('list.titles.standing'),
        suffix: 'p/day',
        bidTable: true,
        renderCustomEl: (item: ConsumerBid) => {
          if (!item.consumerBidCharges) return undefined
          const chargesSet = transformChargesToSet(item.consumerBidCharges)
          const standingCharge = chargesSet['STANDING_CHARGE']
          return standingCharge && standingCharge.value
        },
      },
      {
        keyName: 'greenContract',
        title: t('list.titles.greenContract'),
        bidTable: true,
        renderCustomEl: (item: ConsumerBid) => {
          if (item.greenContract === undefined || item.greenContract === null)
            return undefined
          return item.greenContract ? 'Yes' : 'No'
        },
      },
    ]

    return details
  }, [bid])

  return (
    <div className="min-w-[400px] max-w-[520px]">
      <Table
        data={[bid]}
        headers={[t('headers.status'), '']}
        rowKeys={[
          {
            keyName: 'bid.status',
            renderCustomEl: (item: ConsumerBid) => {
              return <BidPill status={item.status} />
            },
          },
          {
            keyName: 'actions',
            renderCustomEl: (item) => {
              if (buttons) {
                if (['REJECTED', 'ACTIVE'].includes(item.status)) {
                  return (
                    <div className="ml-auto flex items-center justify-end gap-x-3">
                      {buttons.map((button) => (
                        <Button
                          {...button.props}
                          key={button.text}
                          onClick={() => button.onClick()}
                        >
                          {button.text}
                        </Button>
                      ))}

                      <TrashIcon
                        className={classNames(
                          transition,
                          'w-[18px] hover:brightness-75 text-ppa/sidebarButtonText',
                        )}
                        onClick={handleDeleteBid}
                      />
                    </div>
                  )
                }

                return (
                  <div className="flex items-center justify-end gap-x-3">
                    {buttons.map((button) => (
                      <Button
                        {...button.props}
                        key={button.text}
                        onClick={() => button.onClick()}
                      >
                        {button.text}
                      </Button>
                    ))}
                  </div>
                )
              }

              return undefined
            },
          },
        ]}
        readOnly
      />
      <DefList rowKeys={rowKeys} data={bid} />
    </div>
  )
}

export default BidTable
