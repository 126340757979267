import React, { useMemo, useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'
import timezone from 'dayjs/plugin/timezone'

import classNames from 'classnames'

import { ExclamationIcon, ChevronRightIcon } from '@heroicons/react/outline'

import { ReactComponent as Badge1SVG } from './assets/badge-1.svg'
import { ReactComponent as Badge2SVG } from './assets/badge-2.svg'
import { ReactComponent as Badge3SVG } from './assets/badge-3.svg'
import { ReactComponent as DoneIcon } from './assets/doneIcon.svg'
import { ReactComponent as ReloadIcon } from './assets/reload.svg'

import Checkbox from '../../atoms/checkbox'
import Button, { Props as ButtonProps } from '../../atoms/button'
import Message from '../../atoms/message'
import TooltipInfo from '../../atoms/tooltip-info-message'

import { dateFormats } from '../../../utils/data'

import {
  EmbeddedBenefitName,
  EmbeddedBenefitType,
  SubsidyName,
  SubsidyType,
  ChargeName,
  ChargeType,
  Period,
  BidWithRank as BidData,
  BidStatus,
  TenderSiteWithInvitation,
  PowerPriceName,
  ChargePowerPriceType,
  PowerPriceType,
} from '../../../types'

import { ReactComponent as Spinner } from '../../../assets/spinner/spinner.svg'
import { transition } from '../../../styles'
import { feedbackMessage } from '../../atoms/feedback'

dayjs.extend(timezone)

export type Bid = BidData & {
  tenderInvitation: {
    supplier: { name: string }
  }
}

export const priceTypeOptions: Record<PowerPriceName, string> = {
  MATCHED_UNMATCHED: 'matchedUnmatched',
  MATCHED: 'matched',
  UNMATCHED: 'unmatched',
  ANNUAL: 'fixedAveraged',
  SEASONAL: 'fixedSeasonal',
  DAY_AHEAD: 'dayAhead',
  SPILL: 'spill',
}

type ParsedPeriods =
  | (Period & {
      id: string
      bidLabel: string
    })
  | {
      id: string
      bidLabel: string
    }

interface ParsedBid {
  id: Bid['id']
  tenderInvitation: Bid['tenderInvitation']
  periods: ParsedPeriods[]
  embeddedBenefits: Omit<
    Record<EmbeddedBenefitName, string>,
    'BSUOS' | 'AAHEDC' | 'OTHER'
  >
  subsidies: Omit<Record<SubsidyName, string>, 'ROC' | 'FIT_EXPORT'>
  charges: Record<ChargeName, string>
  expiresAt: Bid['expiresAt']
  rank: Bid['rank']
  rankWithEstimations: Bid['rankWithEstimations']
  status: Bid['status']
}

interface ParsedBidMemo {
  bids: ParsedBid[]
  status: BidStatus[]
  uniquePeriods: string[]
}

/**
 * 30 minutes in milliseconds = 1.800.000
 */
const warnMinutesValue = 1800000

function padTo2Digits(num: number) {
  return num.toString().padStart(2, '0')
}

const parseTime = (milliseconds: number) => {
  let seconds = Math.floor(milliseconds / 1000)
  let minutes = Math.floor(seconds / 60)
  const hours = Math.floor(minutes / 60)
  seconds %= 60
  minutes %= 60

  return `${padTo2Digits(hours)}:${padTo2Digits(minutes)}:${padTo2Digits(
    seconds,
  )}`
}

const ExpireTime: React.FC<{ expireAt: string }> = ({ expireAt }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'components.organisms.bidsTable',
  })

  const expireAtDate = dayjs(expireAt).tz('Europe/London')
  const [countDown, setCountDown] = useState(
    expireAtDate.valueOf() - dayjs().tz('Europe/London').valueOf(),
  )

  useEffect(() => {
    const interval = setInterval(() => {
      setCountDown(
        expireAtDate.valueOf() - dayjs().tz('Europe/London').valueOf(),
      )
    }, 1000)

    return () => clearInterval(interval)
  }, [])

  return (
    <>
      <ExclamationIcon
        className={classNames(
          transition,
          'w-5 h-5 mr-2 text-ppa/yellowWarning',
          countDown <= 0 && 'text-ppa/redError',
          countDown < warnMinutesValue ? 'opacity-1' : 'opacity-0',
        )}
      />

      {countDown <= 0 ? t('expired') : parseTime(countDown)}
    </>
  )
}

const defaultStyles = {
  eachRow:
    'flex min-h-[45px] min-w-[235px] px-1.5 text-ppa/grayText border-b border-b-ppa/grayBorder bg-white',
  titleRow: 'bg-ppa/grayBackground',
  selectedColumn:
    'border-l-2 border-l-ppa/secondarySelected border-r-2 border-r-ppa/secondarySelected',
}

const mappedPriceTypes = {
  MWH: 'pricePerMWh',
  FIXED: 'pricePerMWh',
  FLEXIBLE: 'pricePercentage',
  MONTHLY: 'pricePerMonth',
  DAILY: 'pricePerDay',
  MATCHED: 'pricePerMWh',
  UNMATCHED: 'pricePerMWh',
}

interface ParseObjectToStringPayload {
  id: string
  name: string
  type: PowerPriceType | ChargePowerPriceType
  active: boolean
  order: number
  value?: number
}

export interface ButtonList {
  props: ButtonProps
  text: string
  onClick: (selectedBidId: string) => Promise<void>
}

interface TableBidsProps {
  tender?: TenderSiteWithInvitation
  bids?: Bid[]
  error?: any
  refetch: () => void
  isLoading?: boolean
  buttons?: ButtonList[]
  enableSelectingBids?: boolean
  disableExpired?: boolean
}

const TableBids: React.FC<TableBidsProps> = ({
  tender,
  bids,
  error,
  isLoading,
  refetch,
  buttons,
  enableSelectingBids = false,
  disableExpired = false,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'components.organisms.bidsTable',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const [selectedBids, setSelectedBids] = useState<string[]>([])

  const [collapseRows, setCollapseRows] = useState({
    subsidies: true,
    embeddedBenefits: true,
    charges: true,
  })

  const parsedBids = useMemo((): ParsedBidMemo | undefined => {
    const parseObjectToString = (
      object: ParseObjectToStringPayload,
      estimated?: number,
    ): string => {
      if (!object?.value) return `0.00 ${tUtils(mappedPriceTypes.FIXED)}`
      const value = Number(object.value).toFixed(2)

      if (object.type === 'FLEXIBLE') {
        if (!estimated) return `${value} ${tUtils(mappedPriceTypes.FLEXIBLE)}`

        const estimatedValue = Number(estimated).toFixed(2)
        return `${value} ${tUtils(
          mappedPriceTypes.FLEXIBLE,
        )} (${estimatedValue} ${tUtils(mappedPriceTypes.FIXED)} ${t(
          'estimation',
        )})`
      }

      return `${value} ${tUtils(mappedPriceTypes[object.type])}`
    }

    const parsePeriods = (
      thisBids: Bid[],
    ): {
      bids: ParsedBid[]
      uniquePeriods: string[]
    } => {
      const allPeriods: string[] = []

      // Create a unique ID for each period based on startDate, name, endDate, and type because MATCHED_UNMATCHED needs a type since everythine elese will remind the same for each period
      thisBids.forEach((bid) => {
        bid.periods.forEach((period) => {
          const periodId = `${period.startDate.substring(0, 10)}|${
            period.name
          }|${period.endDate.substring(0, 10)}|${period.type}`
          if (allPeriods.includes(periodId)) return

          allPeriods.push(periodId)
        })
      })

      let uniquePeriods: string[]

      if (thisBids.length === 1) {
        uniquePeriods = allPeriods
      } else {
        uniquePeriods = [...new Set(allPeriods)].sort((a, b) => {
          const startDateA = a.split('|')[0]
          const startDateB = b.split('|')[0]
          const endDateB = b.split('|')[1]

          if (dayjs(startDateA).isSame(dayjs(startDateB))) return 0
          if (dayjs(startDateA).isSame(dayjs(endDateB))) return -1
          return dayjs(startDateA).isBefore(dayjs(startDateB)) ? -1 : 1
        })
      }

      const result: ParsedBid[] = thisBids.map((bid) => {
        const newPeriods = bid.periods.map((period) => {
          const periodId = `${period.startDate.substring(0, 10)}|${
            period.name
          }|${period.endDate.substring(0, 10)}|${period.type}`
          if (period.name === 'DAY_AHEAD' || period.name === 'SPILL') {
            const findPeriodEstimation = Object.keys(
              bid.estimations.periods,
            ).find((item) => item === periodId)
            if (findPeriodEstimation) {
              const bidLabel = `${Number(period.value).toFixed(2)} ${tUtils(
                mappedPriceTypes[period.type],
              )} (${bid.estimations.periods[findPeriodEstimation]} ${tUtils(
                mappedPriceTypes.FIXED,
              )} ${t('estimation')})`

              return {
                ...period,
                id: periodId,
                bidLabel,
              }
            }
          }
          const bidLabel = `${Number(period.value).toFixed(2)} ${tUtils(
            mappedPriceTypes[period.type],
          )}`

          return {
            ...period,
            id: periodId,
            bidLabel,
          }
        })

        const { benefits, subsidies, charges } = bid

        const objectBenefits: Record<EmbeddedBenefitName, EmbeddedBenefitType> =
          {} as any
        benefits.forEach((benefit) => {
          objectBenefits[benefit.name] = benefit
        })

        const newBenefits: ParsedBid['embeddedBenefits'] = {
          GDUOS: parseObjectToString(
            objectBenefits.GDUOS,
            bid.estimations?.gduos,
          ),
          DLOSS: parseObjectToString(
            objectBenefits.DLOSS,
            bid.estimations?.losses?.dloss,
          ),
          TLOSS: parseObjectToString(
            objectBenefits.TLOSS,
            bid.estimations?.losses?.tloss,
          ),
          RCRC: parseObjectToString(
            objectBenefits.RCRC,
            bid.estimations?.other?.rcrc,
          ),
          ELEXON: parseObjectToString(
            objectBenefits.ELEXON,
            bid.estimations?.other?.elexon,
          ),
        }
        const objectSubsidies: Record<SubsidyName, SubsidyType> = {} as any
        subsidies.forEach((subsidy) => {
          objectSubsidies[subsidy.name] = subsidy
        })
        const newSubsidies: ParsedBid['subsidies'] = {
          REGO: parseObjectToString(
            objectSubsidies.REGO,
            bid.estimations?.subsidies?.rego,
          ),
          ROC_BUYOUT: parseObjectToString(
            objectSubsidies.ROC_BUYOUT,
            bid.estimations?.subsidies?.rocBuyout,
          ),
          ROC_RECYCLE: parseObjectToString(
            objectSubsidies.ROC_RECYCLE,
            bid.estimations?.subsidies?.rocRecycle,
          ),
          FIT: parseObjectToString(objectSubsidies.FIT),
        }

        const objectCharges: Record<ChargeName, ChargeType> = {} as any
        charges.forEach((charge) => {
          objectCharges[charge.name] = charge
        })

        const newCharges: ParsedBid['charges'] = {
          RISK_PREMIUM: parseObjectToString(objectCharges.RISK_PREMIUM),
          DA_DC: parseObjectToString(objectCharges.DA_DC),
          THIRD_PARTY: parseObjectToString(objectCharges.THIRD_PARTY),
          OTHER: parseObjectToString(objectCharges.OTHER),
          MANAGEMENT_FEE: parseObjectToString(objectCharges.MANAGEMENT_FEE),
          BROKER_FEE: parseObjectToString(objectCharges.BROKER_FEE),
        }

        return {
          ...bid,
          embeddedBenefits: newBenefits,
          subsidies: newSubsidies,
          charges: newCharges,
          periods: uniquePeriods.map((periodId) => {
            const periodsFound = newPeriods.filter(
              (newPeriod) => newPeriod.id === periodId,
            )

            if (periodsFound.length === 0) {
              return { id: periodId, bidLabel: '' }
            }

            return periodsFound[0]
          }),
        }
      })

      return { bids: result, uniquePeriods }
    }

    if (isLoading || error || !bids || !tender) return undefined

    let statusToSearch: BidStatus[] = ['ACTIVE']

    if (tender.status === 'UNDER_OFFER') {
      statusToSearch = ['ACCEPTED']
    } else if (tender.status === 'EXECUTED') {
      statusToSearch = ['EXECUTED', 'NOT_ACCEPTED']
    } else if (tender.status === 'WITHDRAWN') {
      statusToSearch = ['REJECTED']
    }

    const result = bids.filter((bid) => statusToSearch.includes(bid.status))

    if (result.length === 0) return undefined

    return {
      ...parsePeriods(result),
      status: result.map((bid) => bid.status),
    }
  }, [bids, isLoading, error, tender])

  const isOneBidWithNoRank = useMemo(() => {
    if (!parsedBids || parsedBids.bids.length === 0) return false

    return parsedBids.bids.find((item) => item.rank === 0)
  }, [parsedBids])

  const canCompareBids = useMemo(() => {
    if (
      parsedBids &&
      parsedBids.status.includes('ACTIVE') &&
      parsedBids.bids.length > 1 &&
      enableSelectingBids
    )
      return true
    return false
  }, [parsedBids])

  const canSelectBids = useMemo(() => {
    return !!(
      enableSelectingBids &&
      (canCompareBids || (buttons && buttons?.length > 0))
    )
  }, [canCompareBids, parsedBids])

  const [isComparingBids, setIsComparingBids] = useState(false)
  const [bidsBeingCompared, setBidsBeingCompared] = useState<
    ParsedBidMemo | undefined
  >()

  const handleCompareBids = () => {
    if (!parsedBids || selectedBids.length <= 1) return
    if (isComparingBids) {
      setBidsBeingCompared(undefined)
      setIsComparingBids(false)
      return
    }
    setIsComparingBids(true)

    const periodsWithValue: string[] = []
    const filteredBids = parsedBids.bids.filter((item) => {
      const { id, periods } = item
      if (selectedBids.includes(id)) {
        periods.forEach((period) => {
          if (period.bidLabel) {
            periodsWithValue.push(period.id)
          }
        })
        return true
      }

      return false
    })

    const uniquePeriodsToUse = [...new Set(periodsWithValue)].sort((a, b) => {
      const startDateA = a.split('|')[0]
      const startDateB = b.split('|')[0]

      if (dayjs(startDateA).isSame(dayjs(startDateB))) return 0
      return dayjs(startDateA).isBefore(dayjs(startDateB)) ? -1 : 1
    })

    setBidsBeingCompared({
      bids: filteredBids.map((item) => {
        const { periods } = item
        const newPeriods = periods.filter((period) =>
          uniquePeriodsToUse.includes(period.id),
        )

        return { ...item, periods: newPeriods }
      }),
      uniquePeriods: uniquePeriodsToUse,
      status: parsedBids.status,
    })
  }

  return (
    <div className="flex flex-col w-full lg:min-w-[850px] overflow-x-auto gap-y-6 pb-4">
      <div className="flex flex-col gap-y-3">
        <div className="flex">
          <h1 className="text-2xl font-medium text-ppa/title">{t('title')}</h1>
          <button
            type="button"
            onClick={() => {
              refetch()
              setSelectedBids([])
            }}
            className="ml-2"
          >
            <ReloadIcon />
          </button>
        </div>

        {tender && tender.status === 'ACTIVE' && (
          <Message variant="info" size="text-sm">
            {t('info.0')}
            <br />
            {t('info.1')}
          </Message>
        )}
        {isOneBidWithNoRank && (
          <Message variant="warn" size="text-sm">
            {t('warn.bidsWithNoRank')}
          </Message>
        )}
      </div>

      {isLoading && (
        <div className="w-full">
          <Spinner className="mx-auto animate-spin w-5 h-5" />
        </div>
      )}

      {!isLoading && !parsedBids && (
        <strong className="text-lg font-medium text-ppa/title ml-6">
          {t('noBidsFound')}
        </strong>
      )}

      {!isLoading && tender && parsedBids && (
        <div className="flex flex-col gap-y-5 fade-in">
          <section className="flex flex-col gap-y-5">
            <div className="fade-in w-full overflow-auto">
              <div className="min-w-[850px]">
                <article
                  className={classNames(
                    'flex overflow-x-auto',
                    isComparingBids && 'hidden',
                  )}
                >
                  <div className="flex flex-col fixed z-50 bg-white w-[280px]">
                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        defaultStyles.titleRow,
                      )}
                    />
                    {!disableExpired && (
                      <div
                        className={classNames(
                          defaultStyles.eachRow,
                          'items-center justify-start',
                        )}
                      >
                        {t('bidsExpiresIn')}
                      </div>
                    )}
                    {parsedBids.uniquePeriods.map((period) => {
                      const [startDate, priceType, endDate, type] =
                        period.split('|') as [
                          string,
                          keyof typeof priceTypeOptions,
                          string,
                          string,
                        ]

                      const label = `${dayjs(startDate).format(
                        dateFormats.user,
                      )} - ${dayjs(endDate).format(dateFormats.user)}`

                      const translationKey =
                        priceType === 'MATCHED_UNMATCHED' &&
                        type in priceTypeOptions
                          ? `bids.priceType.${
                              priceTypeOptions[
                                type as keyof typeof priceTypeOptions
                              ]
                            }`
                          : `bids.priceType.${priceTypeOptions[priceType]}`

                      return (
                        <div
                          key={period}
                          className={classNames(
                            defaultStyles.eachRow,
                            'flex-col items-start justify-center text-sm',
                          )}
                        >
                          <span className="font-normal">
                            {tUtils(translationKey)}{' '}
                            {/* Translate using the conditional key */}
                          </span>
                          <span className="font-light">{label}</span>
                        </div>
                      )
                    })}

                    <div
                      className={classNames(
                        'text-ppa/grayText text-xs font-normal py-2',
                        'flex items-center justify-start gap-y-1',
                        'border-t border-t-ppa/grayBorder w-full',
                      )}
                    >
                      <ChevronRightIcon
                        onClick={() =>
                          setCollapseRows({
                            ...collapseRows,
                            embeddedBenefits: !collapseRows.embeddedBenefits,
                          })
                        }
                        className={classNames(
                          transition,
                          'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                          collapseRows.embeddedBenefits && 'rotate-90',
                        )}
                      />
                      <span>{t('embeddedBenefits.title')}</span>
                    </div>
                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-xs font-normal',
                        'flex flex-col justify-center items-start pl-8',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder w-full',
                        !collapseRows.embeddedBenefits &&
                          'max-h-[0px] invisible opacity-0',
                      )}
                    >
                      <span>GDUoS</span>
                      <span>D-loss</span>
                      <span>T-loss</span>
                      <span>RCRC</span>
                      <span>ELEXON</span>
                    </div>
                    <div
                      className={classNames(
                        'text-ppa/grayText text-xs font-normal py-2',
                        'flex items-center justify-start gap-y-1',
                      )}
                    >
                      <ChevronRightIcon
                        onClick={() =>
                          setCollapseRows({
                            ...collapseRows,
                            subsidies: !collapseRows.subsidies,
                          })
                        }
                        className={classNames(
                          transition,
                          'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                          collapseRows.subsidies && 'rotate-90',
                        )}
                      />
                      <span>{t('subsidies.title')}</span>
                    </div>
                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-xs font-normal',
                        'flex flex-col justify-center items-start ml-8',
                        'gap-y-1 min-w-[235px]',
                        !collapseRows.subsidies &&
                          'max-h-[0px] invisible opacity-0',
                      )}
                    >
                      <span>ROC ({t('subsidies.buyout')})</span>
                      <span>ROC ({t('subsidies.recycle')})</span>
                      <span>FIT</span>
                      <span>REGO</span>
                    </div>
                    <div className="border-b border-b-ppa/grayBorder w-full" />
                    <div
                      className={classNames(
                        'text-ppa/grayText text-xs font-normal py-2',
                        'flex items-center justify-start gap-y-1',
                      )}
                    >
                      <ChevronRightIcon
                        onClick={() =>
                          setCollapseRows({
                            ...collapseRows,
                            charges: !collapseRows.charges,
                          })
                        }
                        className={classNames(
                          transition,
                          'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                          collapseRows.charges && 'rotate-90',
                        )}
                      />
                      <span>{t('charges.title')}</span>
                    </div>
                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-xs font-normal',
                        'flex flex-col justify-center items-start pl-8',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder w-full',
                        !collapseRows.charges &&
                          'max-h-[0px] invisible opacity-0',
                      )}
                    >
                      <span>{t('charges.riskPremium')}</span>
                      <span>{t('charges.daDc')}</span>
                      <span>{t('charges.thirdParty')}</span>
                      <span>{t('charges.other')}</span>
                      <span className="font-medium">
                        {t('charges.brokerFee')}
                      </span>
                    </div>
                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-sm font-normal',
                        'flex flex-col justify-center items-start',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder w-full',
                      )}
                    >
                      <span className="font-medium">{t('total.label')}</span>
                      <span>{t('total.rank')}</span>
                    </div>
                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-sm font-normal',
                        'flex flex-col justify-center items-start',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder w-full',
                      )}
                    >
                      <span className="font-medium">{t('total.label')}</span>
                      <span>{t('total.rankWithEstimations')}</span>
                    </div>
                  </div>

                  {parsedBids.bids.map((bid, bidIdx) => (
                    <div
                      key={bid.id}
                      className={classNames(
                        'flex flex-col',
                        bidIdx === 0 && 'ml-[280px]',
                        !disableExpired &&
                          dayjs(bid.expiresAt).isBefore(dayjs()) &&
                          'bg-ppa/statusNotAccepted',
                      )}
                    >
                      <div
                        className={classNames(
                          defaultStyles.eachRow,
                          'items-center justify-end gap-y-3',
                          selectedBids.includes(bid.id) &&
                            `${defaultStyles.selectedColumn} border-t-2 border-t-ppa/secondarySelected rounded`,
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          ['ACTIVE', 'ACCEPTED', 'REJECTED'].includes(
                            bid.status,
                          ) && defaultStyles.titleRow,
                        )}
                      >
                        {['ACTIVE', 'ACCEPTED'].includes(bid.status) &&
                        bid.rank !== 0 ? (
                          <>
                            {bidIdx === 0 && <Badge1SVG />}
                            {bidIdx === 1 && <Badge2SVG />}
                            {bidIdx === 2 && <Badge3SVG />}
                          </>
                        ) : (
                          bid.status === 'EXECUTED' && (
                            <DoneIcon className="mr-2" />
                          )
                        )}

                        <span
                          className={classNames(
                            'font-normal text-sm text-ppa/grayText',
                          )}
                        >
                          {bid.tenderInvitation.supplier.name}
                        </span>
                      </div>

                      {!disableExpired && (
                        <div
                          className={classNames(
                            defaultStyles.eachRow,
                            'justify-end items-center text-sm font-light',
                            selectedBids.includes(bid.id) &&
                              defaultStyles.selectedColumn,
                          )}
                        >
                          <ExpireTime expireAt={bid.expiresAt} />
                        </div>
                      )}

                      {bid.periods.map((period) => {
                        return (
                          <div
                            key={period.id}
                            className={classNames(
                              defaultStyles.eachRow,
                              'items-center justify-end',
                              'text-sm font-light',
                              bid.status === 'NOT_ACCEPTED' &&
                                'bg-ppa/statusNotAccepted',
                              bid.status === 'EXECUTED' &&
                                'bg-ppa/statusExecuted',
                              selectedBids.includes(bid.id) &&
                                defaultStyles.selectedColumn,
                            )}
                          >
                            {period.bidLabel}
                          </div>
                        )
                      })}

                      <div
                        className={classNames(
                          'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                          'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                          'border-t border-t-ppa/grayBorder w-full',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <span>Embedded benefits</span>
                      </div>

                      <div
                        className={classNames(
                          transition,
                          'text-ppa/grayText text-xs font-normal',
                          'flex flex-col items-end justify-center px-1.5',
                          'gap-y-1 min-w-[235px]',
                          'border-b border-b-ppa/grayBorder',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          !collapseRows.embeddedBenefits &&
                            'max-h-[0px] text-transparent',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <span>{bid.embeddedBenefits?.GDUOS || '-'}</span>
                        <span>{bid.embeddedBenefits?.DLOSS || '-'}</span>
                        <span>{bid.embeddedBenefits?.TLOSS || '-'}</span>
                        <span>{bid.embeddedBenefits?.RCRC || '-'}</span>
                        <span>{bid.embeddedBenefits?.ELEXON || '-'}</span>
                      </div>

                      <div
                        className={classNames(
                          'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                          'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <span>Subsidies</span>
                      </div>

                      <div
                        className={classNames(
                          'text-ppa/grayText text-xs font-normal',
                          'flex flex-col items-end justify-center px-1.5',
                          'gap-y-1 min-w-[235px]',
                          'border-b border-b-ppa/grayBorder',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          !collapseRows.subsidies &&
                            'max-h-[0px] text-transparent',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <span>{bid.subsidies?.ROC_BUYOUT || '-'}</span>
                        <span>{bid.subsidies?.ROC_RECYCLE || '-'}</span>
                        <span>{bid.subsidies?.FIT || '-'}</span>
                        <span>{bid.subsidies?.REGO || '-'}</span>
                      </div>

                      <div
                        className={classNames(
                          'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                          'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <span>Charges</span>
                      </div>

                      <div
                        className={classNames(
                          transition,
                          'text-ppa/grayText text-xs font-normal',
                          'flex flex-col items-end justify-center px-1.5',
                          'gap-y-1 min-w-[235px]',
                          'border-b border-b-ppa/grayBorder',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          !collapseRows.charges &&
                            'max-h-[0px] text-transparent',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <span>{bid.charges?.RISK_PREMIUM || '-'}</span>
                        <span>{bid.charges?.DA_DC || '-'}</span>
                        <span>{bid.charges?.THIRD_PARTY || '-'}</span>
                        <span>{bid.charges?.OTHER || '-'}</span>
                        <span>{bid.charges?.BROKER_FEE || '-'}</span>
                      </div>

                      <div
                        className={classNames(
                          defaultStyles.eachRow,
                          'font-semibold text-sm',
                          'items-center justify-end',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        {/* @SEE This function will not add thousand separators.
                              Check the `new Intl.NumberFormat` in this file,
                              it may be a refactor for the `penceToPounds` function.
                          */}
                        {bid?.rank ? (
                          <div className="flex gap-x-3 items-center">
                            {bid.periods.some(
                              (period: any) =>
                                period.name === 'MATCHED' ||
                                period.name === 'UNMATCHED',
                            ) && <TooltipInfo message={t('tooltipText')} />}
                            <span>{bid.rank}&nbsp;£/MWh</span>
                          </div>
                        ) : (
                          <span>{t('bidWithNoRank')}</span>
                        )}
                      </div>

                      <div
                        className={classNames(
                          defaultStyles.eachRow,
                          'font-semibold text-sm',
                          'items-center justify-end',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        {/* @SEE This function will not add thousand separators.
                              Check the `new Intl.NumberFormat` in this file,
                              it may be a refactor for the `penceToPounds` function.
                          */}
                        {bid?.rankWithEstimations ? (
                          <div className="flex gap-x-3 items-center">
                            {bid.periods.some(
                              (period: any) =>
                                period.name === 'MATCHED' ||
                                period.name === 'UNMATCHED',
                            ) && <TooltipInfo message={t('tooltipText')} />}
                            <span>{bid.rankWithEstimations}&nbsp;£/MWh</span>
                          </div>
                        ) : (
                          <span>{t('bidWithNoRank')}</span>
                        )}
                      </div>

                      <div
                        className={classNames(
                          defaultStyles.eachRow,
                          'items-center justify-center',
                          bid.status === 'NOT_ACCEPTED' &&
                            'bg-ppa/statusNotAccepted',
                          bid.status === 'EXECUTED' && 'bg-ppa/statusExecuted',
                          selectedBids.includes(bid.id) &&
                            `${defaultStyles.selectedColumn} border-b-2 border-b-ppa/secondarySelected rounded`,
                        )}
                      >
                        {canSelectBids && (
                          <Checkbox
                            value={selectedBids.includes(bid.id)}
                            data-testid={bid.tenderInvitation.supplier.name}
                            onChange={(e: any) => {
                              if (e.target.checked) {
                                setSelectedBids([...selectedBids, bid.id])
                                return
                              }
                              setSelectedBids(
                                selectedBids.filter((item) => item !== bid.id),
                              )
                            }}
                          />
                        )}
                      </div>
                    </div>
                  ))}
                </article>
              </div>
            </div>

            {isComparingBids && bidsBeingCompared && (
              <article className={classNames('flex overflow-x-auto fade-in')}>
                <div className="flex flex-col fixed z-50 bg-white w-[280px]">
                  <div
                    className={classNames(
                      defaultStyles.eachRow,
                      defaultStyles.titleRow,
                    )}
                  />
                  {!disableExpired && (
                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        'items-center justify-start',
                      )}
                    >
                      {t('bidsExpiresIn')}
                    </div>
                  )}
                  {bidsBeingCompared.uniquePeriods.map((period) => {
                    const [startDate, priceType, endDate] = period.split(
                      '|',
                    ) as [string, keyof typeof priceTypeOptions, string]

                    const label = `${dayjs(startDate).format(
                      dateFormats.user,
                    )} - ${dayjs(endDate).format(dateFormats.user)}`

                    return (
                      <div
                        key={period}
                        className={classNames(
                          defaultStyles.eachRow,
                          'flex-col items-start justify-center text-sm',
                        )}
                      >
                        <span className="font-normal">
                          {tUtils(
                            `bids.priceType.${priceTypeOptions[priceType]}`,
                          )}
                        </span>
                        <span className="font-light">{label}</span>
                      </div>
                    )
                  })}

                  <div
                    className={classNames(
                      'text-ppa/grayText text-xs font-normal py-2',
                      'flex items-center justify-start gap-y-1',
                      'border-t border-t-ppa/grayBorder w-full',
                    )}
                  >
                    <ChevronRightIcon
                      onClick={() =>
                        setCollapseRows({
                          ...collapseRows,
                          embeddedBenefits: !collapseRows.embeddedBenefits,
                        })
                      }
                      className={classNames(
                        transition,
                        'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                        collapseRows.embeddedBenefits && 'rotate-90',
                      )}
                    />
                    <span>{t('embeddedBenefits.title')}</span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-xs font-normal',
                      'flex flex-col justify-center items-start pl-8',
                      'gap-y-1 min-w-[235px]',
                      !collapseRows.embeddedBenefits &&
                        'max-h-[0px] invisible opacity-0',
                    )}
                  >
                    <span>GDUoS</span>
                    <span>D-loss</span>
                    <span>T-loss</span>
                    <span>RCRC</span>
                    <span>ELEXON</span>
                  </div>
                  <div className="border-b border-b-ppa/grayBorder w-full" />
                  <div
                    className={classNames(
                      'text-ppa/grayText text-xs font-normal py-2',
                      'flex items-center justify-start gap-y-1',
                    )}
                  >
                    <ChevronRightIcon
                      onClick={() =>
                        setCollapseRows({
                          ...collapseRows,
                          subsidies: !collapseRows.subsidies,
                        })
                      }
                      className={classNames(
                        transition,
                        'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                        collapseRows.subsidies && 'rotate-90',
                      )}
                    />
                    <span>{t('subsidies.title')}</span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-xs font-normal',
                      'flex flex-col justify-center items-start pl-8',
                      'gap-y-1 min-w-[235px]',
                      !collapseRows.subsidies &&
                        'max-h-[0px] invisible opacity-0',
                    )}
                  >
                    <span>ROC ({t('subsidies.buyout')})</span>
                    <span>ROC ({t('subsidies.recycle')})</span>
                    <span>FIT</span>
                    <span>REGO</span>
                  </div>
                  <div className="border-b border-b-ppa/grayBorder w-full" />
                  <div
                    className={classNames(
                      'text-ppa/grayText text-xs font-normal py-2',
                      'flex items-center justify-start gap-y-1',
                    )}
                  >
                    <ChevronRightIcon
                      onClick={() =>
                        setCollapseRows({
                          ...collapseRows,
                          charges: !collapseRows.charges,
                        })
                      }
                      className={classNames(
                        transition,
                        'w-4 h-4 text-ppa/grayText cursor-pointer hover:brightness-75',
                        collapseRows.charges && 'rotate-90',
                      )}
                    />
                    <span>{t('charges.title')}</span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-xs font-normal',
                      'flex flex-col justify-center items-start pl-8',
                      'gap-y-1 min-w-[235px]',
                      'border-b border-b-ppa/grayBorder w-full',

                      !collapseRows.charges &&
                        'max-h-[0px] invisible opacity-0',
                    )}
                  >
                    <span>{t('charges.riskPremium')}</span>
                    <span>{t('charges.daDc')}</span>
                    <span>{t('charges.thirdParty')}</span>
                    <span>{t('charges.other')}</span>
                    <span className="font-medium">
                      {t('charges.brokerFee')}
                    </span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-sm font-normal',
                      'flex flex-col justify-center items-start',
                      'gap-y-1 min-w-[235px]',
                      'border-b border-b-ppa/grayBorder w-full',
                    )}
                  >
                    <span className="font-medium">{t('total.label')}</span>
                    <span>{t('total.rank')}</span>
                  </div>
                  <div
                    className={classNames(
                      transition,
                      'text-ppa/grayText text-sm font-normal',
                      'flex flex-col justify-center items-start',
                      'gap-y-1 min-w-[235px]',
                      'border-b border-b-ppa/grayBorder w-full',
                    )}
                  >
                    <span className="font-medium">{t('total.label')}</span>
                    <span>{t('total.rankWithEstimations')}</span>
                  </div>
                </div>

                {bidsBeingCompared.bids.map((bid, bidIdx) => (
                  <div
                    key={bid.id}
                    className={classNames(
                      'flex flex-col',
                      bidIdx === 0 && 'ml-[280px]',
                      !disableExpired &&
                        dayjs(bid.expiresAt).isBefore(dayjs()) &&
                        'bg-ppa/statusNotAccepted',
                    )}
                  >
                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        'items-center justify-end gap-y-3',
                        selectedBids.includes(bid.id) &&
                          `${defaultStyles.selectedColumn} border-t-2 border-t-ppa/primarySelected`,
                      )}
                    >
                      {bid.rank !== 0 && (
                        <>
                          {bidIdx === 0 && <Badge1SVG />}
                          {bidIdx === 1 && <Badge2SVG />}
                          {bidIdx === 2 && <Badge3SVG />}
                        </>
                      )}

                      <span
                        className={classNames(
                          'font-normal text-sm text-ppa/grayText',
                        )}
                      >
                        {bid.tenderInvitation.supplier.name}
                      </span>
                    </div>

                    {!disableExpired && (
                      <div
                        className={classNames(
                          defaultStyles.eachRow,
                          'justify-end items-center text-sm font-normal',
                          selectedBids.includes(bid.id) &&
                            defaultStyles.selectedColumn,
                        )}
                      >
                        <ExpireTime expireAt={bid.expiresAt} />
                      </div>
                    )}

                    {bid.periods.map((period) => {
                      return (
                        <div
                          key={period.id}
                          className={classNames(
                            defaultStyles.eachRow,
                            'items-center justify-end',
                            'text-sm font-normal',
                            selectedBids.includes(bid.id) &&
                              defaultStyles.selectedColumn,
                          )}
                        >
                          {period.bidLabel}
                        </div>
                      )
                    })}

                    <div
                      className={classNames(
                        'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                        'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                        'border-t border-t-ppa/grayBorder w-full',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <span>{t('embeddedBenefits.title')}</span>
                    </div>

                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-xs font-normal',
                        'flex flex-col items-end justify-center px-1.5',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder w-full',
                        !collapseRows.embeddedBenefits &&
                          'max-h-[0px] text-transparent',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <span>{bid.embeddedBenefits?.GDUOS || '-'}</span>
                      <span>{bid.embeddedBenefits?.DLOSS || '-'}</span>
                      <span>{bid.embeddedBenefits?.TLOSS || '-'}</span>
                      <span>{bid.embeddedBenefits?.RCRC || '-'}</span>
                      <span>{bid.embeddedBenefits?.ELEXON || '-'}</span>
                    </div>

                    <div
                      className={classNames(
                        'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                        'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <span>Subsidies</span>
                    </div>

                    <div
                      className={classNames(
                        'text-ppa/grayText text-xs font-normal',
                        'flex flex-col items-end justify-center px-1.5',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder',
                        !collapseRows.subsidies &&
                          'max-h-[0px] text-transparent',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <span>{bid.subsidies?.ROC_BUYOUT || '-'}</span>
                      <span>{bid.subsidies?.ROC_RECYCLE || '-'}</span>
                      <span>{bid.subsidies?.FIT || '-'}</span>
                      <span>{bid.subsidies?.REGO || '-'}</span>
                    </div>

                    <div
                      className={classNames(
                        'text-ppa/grayTextTable text-xs font-normal py-2 text-transparent',
                        'flex items-center justify-start gap-y-1 select-none pointer-events-none',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <span>Charges</span>
                    </div>

                    <div
                      className={classNames(
                        transition,
                        'text-ppa/grayText text-xs font-normal',
                        'flex flex-col items-end justify-center px-1.5',
                        'gap-y-1 min-w-[235px]',
                        'border-b border-b-ppa/grayBorder',
                        !collapseRows.charges && 'max-h-[0px] text-transparent',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      <span>{bid.charges?.RISK_PREMIUM || '-'}</span>
                      <span>{bid.charges?.DA_DC || '-'}</span>
                      <span>{bid.charges?.THIRD_PARTY || '-'}</span>
                      <span>{bid.charges?.OTHER || '-'}</span>
                      <span>{bid.charges?.BROKER_FEE || '-'}</span>
                    </div>

                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        'font-semibold text-sm',
                        'items-center justify-end',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      {/* @SEE This function will not add thousand separators.
                                Check the `new Intl.NumberFormat` in this file,
                                it may be a refactor for the `penceToPounds` function.
                            */}
                      {bid?.rank ? (
                        <span>{bid.rank}&nbsp;£/MWh</span>
                      ) : (
                        <span>{t('bidWithNoRank')}</span>
                      )}
                    </div>
                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        'font-semibold text-sm',
                        'items-center justify-end',
                        selectedBids.includes(bid.id) &&
                          defaultStyles.selectedColumn,
                      )}
                    >
                      {/* @SEE This function will not add thousand separators.
                                Check the `new Intl.NumberFormat` in this file,
                                it may be a refactor for the `penceToPounds` function.
                            */}
                      {bid?.rankWithEstimations ? (
                        <span>{bid.rankWithEstimations}&nbsp;£/MWh</span>
                      ) : (
                        <span>{t('bidWithNoRank')}</span>
                      )}
                    </div>

                    <div
                      className={classNames(
                        defaultStyles.eachRow,
                        'items-center justify-center',
                        selectedBids.includes(bid.id) &&
                          `${defaultStyles.selectedColumn} border-b-2 border-b-ppa/primarySelected`,
                      )}
                    >
                      {canSelectBids && (
                        <Checkbox
                          value={selectedBids.includes(bid.id)}
                          data-testid={bid.tenderInvitation.supplier.name}
                          onChange={(e: any) => {
                            if (e.target.checked) {
                              setSelectedBids([...selectedBids, bid.id])
                              return
                            }
                            setSelectedBids(
                              selectedBids.filter((item) => item !== bid.id),
                            )
                          }}
                        />
                      )}
                    </div>
                  </div>
                ))}
              </article>
            )}

            <div className="flex items-center justify-start gap-x-5 w-full mt-5">
              {buttons &&
                buttons.map((item) => (
                  <Button
                    {...item.props}
                    key={item.text}
                    disabled={
                      selectedBids.length === 0 || selectedBids.length > 1
                    }
                    onClick={async () => {
                      if (selectedBids.length === 0) {
                        feedbackMessage(
                          {
                            title: tUtils('feedbackMessage.warn.title'),
                            description: t('noBidsSelected'),
                          },
                          'warn',
                        )
                        return
                      }
                      if (selectedBids.length > 1) {
                        feedbackMessage(
                          {
                            title: tUtils('feedbackMessage.warn.title'),
                            description: t('moreThanOneBidSelected'),
                          },
                          'warn',
                        )
                        return
                      }
                      await item.onClick(selectedBids[0])
                    }}
                  >
                    {item.text}
                  </Button>
                ))}

              {canCompareBids && (
                <Button
                  variant="tertiary"
                  disabled={selectedBids.length <= 1}
                  onClick={handleCompareBids}
                >
                  {isComparingBids
                    ? t('buttons.stopComparing')
                    : t('buttons.compare')}
                </Button>
              )}
            </div>
          </section>
        </div>
      )}
    </div>
  )
}

export default TableBids
