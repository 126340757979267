import React, { useMemo, useState, useEffect } from 'react'
import { useTranslation } from 'react-i18next'
import { useParams, useNavigate } from 'react-router-dom'

import {
  PencilIcon,
  TrashIcon,
  DownloadIcon,
  ChevronLeftIcon,
  UploadIcon,
} from '@heroicons/react/outline'
import classNames from 'classnames'

import usePPA from '../../../../../hooks/use-ppa'
import usePPAGetDetails from '../../../../../hooks/use-ppa/use-ppa-get-details'
import { parseQuery } from '../../../../../hooks/use-ppa/use-ppa-get-list'

import { Company, Address } from '../../../../../types'

import { parseSnippetAddress } from '../../../../../utils'

import { container, breakPointsContainer } from '../../../../../styles'
import { ReactComponent as Spinner } from '../../../../../assets/spinner/spinner.svg'

import { feedbackMessage } from '../../../../../components/atoms/feedback'

import DefList from '../../../../../components/atoms/def-list'
import Dropdown from '../../../../../components/atoms/dropdown'
import Button from '../../../../../components/atoms/button'

import SlidingDrawer from '../../../../../components/molecules/sliding-drawer'
import CollapseList from '../../../../../components/molecules/collapse-list'

import EditCompany from './edit-company'
import UploadLoa from './upload-loa'

const SiteCompanyDetails: React.FC = () => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sites.companyList.details',
  })

  const navigate = useNavigate()

  const { fetchData } = usePPA()

  const { companyId } = useParams<{ companyId: string }>()

  const [isEditDrawerOpen, setIsEditDrawerOpen] = useState(false)
  const [isLoaDrawerOpen, setIsLoaDrawerOpen] = useState(false)

  const [isLoadingFiles, setIsLoadingFiles] = useState(false)
  const [fileName, setFileName] = useState<string | null>(null)
  const [fetchedBlob, setFetchedBlob] = useState<Blob | null>(null)

  const [isDeletingFiles, setIsDeletingFiles] = useState(false)
  const [isDownloadingFiles, setIsDownloadingFiles] = useState(false)

  const { isLoading, refetch, throwFeedbackError, data, error } =
    usePPAGetDetails<Company>({
      dataKey: 'company',
      path: `/core/private/generator/company/${companyId}`,
    })

  const companyDetails: Company | undefined = useMemo(() => {
    if (isLoading || error || !data) return undefined

    return data
  }, [data, isLoading, error])

  const rowKeys = useMemo(() => {
    return [
      { keyName: 'name', title: t('defList.name') },
      { keyName: 'number', title: t('defList.number') },
      { keyName: 'type', title: t('defList.type') },
      {
        keyName: 'address',
        title: t('defList.address'),
        renderCustomEl: (item: Company) => {
          return parseSnippetAddress(item?.address as Address | undefined)
        },
      },
    ]
  }, [companyDetails])

  const handleEditCompany = () => {
    refetch()
    setIsEditDrawerOpen(false)
  }

  const handleFetchLoaFile = async () => {
    if (isLoadingFiles) return
    setIsLoadingFiles(true)

    try {
      const { response, error: fetchError } = await fetchData({
        method: 'GET',
        url: `/core/private/generator/loa?${parseQuery({ companyId })}`,
        responseType: 'json',
      })

      if (fetchError) throw fetchError

      const base64Data = response?.data.loaFile
      const fileNameFromResponse = response?.data.fileName || 'loaFile.pdf'

      if (!base64Data) {
        setFetchedBlob(null)
        return
      }

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )

      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      setFileName(fileNameFromResponse)

      setFetchedBlob(blob)
    } catch (err: any) {
      throwFeedbackError(err)
    } finally {
      setIsLoadingFiles(false)
    }
  }

  const handleDeleteLoa = async () => {
    if (isDeletingFiles) return
    setIsDeletingFiles(true)

    try {
      const { error: hhdDeleteError } = await fetchData({
        method: 'DELETE',
        url: `/core/private/generator/loa?${parseQuery({ companyId })}`,
      })

      if (hhdDeleteError) throw hhdDeleteError

      feedbackMessage(
        {
          title: 'Loa file deleted successfully',
        },
        'success',
      )
      handleFetchLoaFile()
    } catch (err: any) {
      throwFeedbackError({ err })
    } finally {
      setIsDeletingFiles(false)
    }
  }

  const handleDownloadLoa = async (file: File) => {
    if (isDownloadingFiles) return

    try {
      const { response, error: downloadError } = await fetchData({
        method: 'GET',
        url: `/core/private/generator/loa?${parseQuery({ companyId })}`,
        responseType: 'json',
      })

      if (downloadError) throw downloadError

      const base64Data = response?.data.loaFile

      const binaryResponse = await fetch(
        `data:application/octet-stream;base64,${base64Data}`,
      )
      const binaryData = await binaryResponse.blob()

      const blob = new Blob([binaryData], {
        type: 'application/octet-stream',
      })

      const url = URL.createObjectURL(blob)

      const downloadLink = document.createElement('a')
      downloadLink.href = url
      downloadLink.setAttribute('download', file.name)
      downloadLink.target = '_blank'
      downloadLink.click()

      URL.revokeObjectURL(url)
    } catch (err) {
      throwFeedbackError({ err })
    } finally {
      setIsDownloadingFiles(false)
    }
  }

  const handleLoaUpload = () => {
    handleFetchLoaFile()
    setIsLoaDrawerOpen(false)
  }

  useEffect(() => {
    if (!companyId) {
      navigate('/sites/companies')
    }
  }, [companyId])

  useEffect(() => {
    if (error) {
      throwFeedbackError({ err: error })
    }
  }, [error])

  useEffect(() => {
    handleFetchLoaFile()
  }, [])

  return (
    <div>
      <section
        className={classNames(
          container,
          breakPointsContainer,
          'items-center pt-12 relative',
        )}
      >
        <div className="absolute top-0 left-0 -mx-3 hover:brightness-50 cursor-pointer">
          <Button
            variant="text"
            icon={<ChevronLeftIcon />}
            onClick={() => navigate('/sites/companies')}
          >
            {t('backButton')}
          </Button>
        </div>
        {isLoading && (
          <div className="w-full">
            <Spinner className="mx-auto animate-spin w-5 h-5" />
          </div>
        )}

        {!isLoading && !error && companyDetails && (
          <>
            <div className="flex items-center justify-between w-full">
              <h1 className="text-3xl font-semibold text-ppa/title mt-3 mb-8 mr-8 w-full">
                {companyDetails.name}
              </h1>
              <Dropdown
                content={
                  <div className="flex flex-col justify-start items-start">
                    <Button
                      variant="text"
                      icon={<PencilIcon />}
                      onClick={() => {
                        setIsEditDrawerOpen(true)
                      }}
                    >
                      {t('actionsDropdown.edit')}
                    </Button>
                  </div>
                }
              />
            </div>
            <DefList rowKeys={rowKeys} data={companyDetails} />
            <div className="flex flex-col w-full">
              <div className="flex items-end mb-5 mt-10">
                <h1 className="text-lg font-medium text-ppa/title mr-3">
                  {t('loa.title')}
                </h1>
                <Button
                  onClick={() => setIsLoaDrawerOpen(true)}
                  variant="primary"
                  icon={<UploadIcon />}
                  smallLabel
                >
                  {t('loa.uploadButton')}
                </Button>
              </div>

              <CollapseList
                title={t('loa.collapseList.title')}
                titleEmptyList={t('loa.collapseList.filesNotFound')}
                list={
                  fetchedBlob
                    ? [
                        {
                          id: `blob-${companyId}`,
                          name: fileName,
                          blob: fetchedBlob,
                        },
                      ]
                    : []
                }
                defaultOpen
                rowKeys={[
                  { keyName: 'name' },
                  {
                    keyName: 'actions',
                    containerClass: 'ml-auto pl-2 max-w-[50px]',
                    renderCustomEl: (file: any) => {
                      return (
                        <div className="flex items-center gap-x-2">
                          <DownloadIcon
                            className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                            onClick={() => handleDownloadLoa(file)}
                          />
                          <TrashIcon
                            className="w-5 h-5 cursor-pointer hover:brightness-75 stroke-ppa/primary"
                            onClick={() => handleDeleteLoa()}
                          />
                        </div>
                      )
                    },
                  },
                ]}
              />
            </div>
            <EditCompany
              company={companyDetails}
              onSuccess={handleEditCompany}
              isOpen={isEditDrawerOpen}
              handleClose={() => setIsEditDrawerOpen(false)}
            />
          </>
        )}
        <SlidingDrawer
          isOpen={isLoaDrawerOpen}
          handleClose={() => setIsLoaDrawerOpen(false)}
        >
          <UploadLoa
            companyId={companyId}
            isDrawerOpen={isLoaDrawerOpen}
            onSuccess={handleLoaUpload}
            onCancel={() => setIsLoaDrawerOpen(false)}
          />
        </SlidingDrawer>
      </section>
    </div>
  )
}

export default SiteCompanyDetails
