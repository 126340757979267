import React, { useState } from 'react'
import { useTranslation } from 'react-i18next'
import { PlusIcon } from '@heroicons/react/solid'

import usePPA from '../../../../../../hooks/use-ppa'

import { feedbackMessage } from '../../../../../../components/atoms/feedback/index'
import Button from '../../../../../../components/atoms/button'

import SlidingDrawer from '../../../../../../components/molecules/sliding-drawer'

import InviteUserToOrganisationForm, {
  FormData,
} from '../form-invite-user-to-organisation'

interface FormProps {
  onSuccess: () => void
}

const InviteUser: React.FC<FormProps> = ({ onSuccess }) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'settings.users.inviteUsersToOrganisation',
  })

  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const { fetchData, throwFeedbackError } = usePPA()

  const [isLoading, setIsLoading] = useState(false)
  const [isDrawerOpen, setIsDrawerOpen] = useState(false)

  const handleInviteMember = async ({
    memberType,
    firstName,
    lastName,
    email,
    phoneNumber,
  }: FormData) => {
    if (isLoading) return
    setIsLoading(true)

    const input = {
      memberType,
      firstName,
      lastName,
      email,
      phoneNumber,
    }

    try {
      const { error } = await fetchData({
        method: 'POST',
        url: '/core/private/generator/invite-member',
        body: input,
      })

      if (error) throw error

      feedbackMessage(
        {
          title: tUtils('feedbackMessage.success.title'),
          description: t('handleInviteUser.success.description', {
            firstName,
            lastName,
            userEmail: email,
          }),
        },
        'success',
      )

      onSuccess()
      setIsDrawerOpen(false)
    } catch (err) {
      throwFeedbackError({
        err,
        context: 'generator',
        ERR_EXTERNAL_ERROR: ({ message }) => {
          if (message.includes('Failed to create user in auth0')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.ERR_EXTERNAL_ERROR.create',
              ),
              type: 'error',
            }
          }

          return undefined
        },
        ERR_NOT_AUTHORIZED: ({ message }) => {
          if (message.includes('Invalid member type')) {
            return {
              title: tUtils('feedbackMessage.error.title'),
              description: tUtils(
                'throwFeedbackError.errorCodes.generator.reason.INVALID_MEMBER_TYPE',
              ),
              type: 'error',
            }
          }

          return undefined
        },
      })
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <div className="ml-9">
      <Button
        variant="primary"
        icon={<PlusIcon />}
        onClick={() => setIsDrawerOpen(true)}
      >
        {t('inviteButton')}
      </Button>
      <SlidingDrawer
        isOpen={isDrawerOpen}
        handleClose={() => setIsDrawerOpen(false)}
      >
        <div className="flex flex-col gap-y-5 sm:w-[462px]">
          <InviteUserToOrganisationForm onSubmit={handleInviteMember} />
        </div>
      </SlidingDrawer>
    </div>
  )
}

export default InviteUser
