/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import React, { useState, useMemo } from 'react'
import { useTranslation } from 'react-i18next'

import { capitalizeText, parseSnippetAddress } from '../../../../../../utils'

import DefList from '../../../../../../components/atoms/def-list'
import CompanyDefListModal from '../../../../../../components/organisms/company-details-modal'

import { Site } from '../../../../../../types'

interface TenderDetailsListSleevedProps {
  data: Site
}

const SiteDetailsDefList: React.FC<TenderDetailsListSleevedProps> = ({
  data,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'generators.sleevedTenders.details',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const [isCompanyModalOpen, setIsCompanyModalOpen] = useState(false)

  const rowKeys = useMemo(() => {
    return [
      {
        keyName: 'name',
        title: t('siteDetailsList.titles.name'),
        renderCustomEl: (site: Site) => {
          if (!site.name) return undefined
          return site.name
        },
      },
      {
        keyName: 'technology',
        title: t('siteDetailsList.titles.technology'),
        renderCustomEl: (site: Site) => {
          const { technology } = site
          if (!technology) return undefined
          return capitalizeText(tUtils(`technology.${technology}.abbreviation`))
        },
      },
      {
        keyName: 'capacity',
        title: t('siteDetailsList.titles.totalCapacity'),
        suffix: 'MW',
        renderCustomEl: (site: Site) => {
          if (!site.capacity) return undefined
          return site.capacity
        },
      },
      {
        keyName: 'voltage',
        title: t('siteDetailsList.titles.voltage'),
        suffix: 'kV',
        renderCustomEl: (site: Site) => {
          if (!site.voltage) return undefined
          return site.voltage
        },
      },
      {
        keyName: 'mpan',
        title: t('siteDetailsList.titles.mpan'),
        renderCustomEl: (site: Site) => {
          if (!site.mpan) return undefined
          return site.mpan
        },
      },
      {
        keyName: 'msid',
        title: t('siteDetailsList.titles.msid'),
        renderCustomEl: (site: Site) => {
          if (!site.msid) return undefined
          return site.msid
        },
      },
      {
        keyName: 'rocBand',
        title: t('siteDetailsList.titles.rocBand'),
        renderCustomEl: (site: Site) => {
          if (!site.rocBand) return undefined
          return site.rocBand
        },
      },
      {
        keyName: 'company',
        title: t('siteDetailsList.titles.company.name'),
        renderCustomEl: (site: Site) => {
          if (!site.company) return undefined
          return (
            <div className="flex flex-col">
              <div
                onClick={() => {
                  setIsCompanyModalOpen(true)
                }}
                className="border-b border-ppa/grayText cursor-pointer w-fit"
              >
                {site.company.name}
              </div>
              {site.company.number && (
                <div
                  onClick={() => {
                    setIsCompanyModalOpen(true)
                  }}
                  className="border-b border-ppa/grayText cursor-pointer w-fit"
                >
                  {site.company.number}
                </div>
              )}
            </div>
          )
        },
      },
      {
        keyName: 'address',
        title: data?.address
          ? t('siteDetailsList.titles.address')
          : t('siteDetailsList.titles.location'),
        renderCustomEl: (site: Site): string | undefined => {
          if (!site.address && !site.location) {
            return undefined
          }
          if (site.address) {
            return parseSnippetAddress(site.address)
          }
          return site.location
        },
      },
    ]
  }, [data])

  return (
    <div>
      <h2 className="font-semibold text-lg text-ppa/title mt-5 mb-3">
        {t('siteDetailsTitle')}
      </h2>
      <DefList rowKeys={rowKeys} data={data} />
      {data.company && (
        <CompanyDefListModal
          isOpen={isCompanyModalOpen}
          onClose={() => setIsCompanyModalOpen(false)}
          title={data.company.name}
          companyData={data.company}
        />
      )}
    </div>
  )
}

export default SiteDetailsDefList
