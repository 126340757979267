import React, { useEffect, useState, useMemo } from 'react'
import { useForm, Controller, FormProvider } from 'react-hook-form'
import { useTranslation } from 'react-i18next'

import dayjs from 'dayjs'

import { generatePeriods } from '../utils'

import { dateFormats, priceTypeList } from '../../../../../../../utils/data'

import { Bid } from '../../../../../../../types'

import BenefitsForm, {
  FormValues as BenefitsFormValues,
} from '../form-benefits'
import ChargesForm, { FormValues as ChargesFormValues } from '../form-charges'
import SubsidiesForm, {
  FormValues as GreenCertificatesFormValues,
} from '../form-subsidies'

import Input from '../../../../../../../components/atoms/input'
import Button from '../../../../../../../components/atoms/button'
import Message from '../../../../../../../components/atoms/message'
import SelectComponent from '../../../../../../../components/atoms/select'

import InputGroup from '../../../../../../../components/molecules/input-group'
import BidSubsidiesInfoModal from '../../../../../../../components/organisms/bid-subsidies-modal'

import { TenderInvitation } from '../../details'
import CustomPricingForm from '../form-custom-pricing'

interface FormPeriods {
  id: string
  periodId: string
  startDate: string
  endDate: string
  value?: string
  priceType: string
  title?: string
}

export type FormData = {
  contractType: string
  periods: FormPeriods[]
  expiresAt: string
}

type BidFormData = FormData &
  BenefitsFormValues &
  ChargesFormValues &
  GreenCertificatesFormValues & {
    zeroValueSubsidyNames?: string
  }

interface FormProps {
  tender: TenderInvitation['tender']
  bid?: Bid
  defaultValues: FormData
  brokerFee: number
  onSubmit: (data: any) => void
  isLoading: boolean
}

const BidForm: React.FC<FormProps> = ({
  tender,
  bid,
  defaultValues,
  brokerFee,
  onSubmit,
  isLoading,
}) => {
  const { t } = useTranslation('private/index', {
    keyPrefix: 'suppliers.tenders.details.bids.form',
  })
  const { t: tUtils } = useTranslation('private/index', {
    keyPrefix: 'utils',
  })

  const [isSubsidyConfModalOpen, setIsSubsidyConfModalOpen] = useState(false)
  const [formData, setFormData] = useState<BidFormData | null>(null)

  const methods = useForm<FormData>({ defaultValues })

  const {
    formState: { errors: formErrors, isSubmitting },
    control,
    watch,
    setValue,
  } = methods

  useEffect(() => {
    const contractType = watch('contractType')

    if (contractType === 'MULTIPLE_TYPES') return

    let periods = []

    if (contractType === 'MATCHED_UNMATCHED') {
      const totalPeriods = generatePeriods(
        tender.startDate,
        tender.endDate,
        contractType,
        true,
      )
      periods = totalPeriods.slice(0, 2)
    } else {
      periods = generatePeriods(
        tender.startDate,
        tender.endDate,
        contractType,
        true,
      )
    }

    setValue(
      'periods',
      periods.map((period) => ({
        ...period,
        startDate: dayjs(period.startDate).format(dateFormats.aws),
        endDate: dayjs(period.endDate).format(dateFormats.aws),
        priceType: period.priceType.value,
        value: period.value,
      })),
    )
  }, [watch('contractType')])

  const handleSubmit = (data: BidFormData) => {
    const subsidyTranslations = t('fields.subsidies', {
      returnObjects: true,
    }) as Record<string, { label: string; sublabel: string }>

    const zeroValueSubsidies = data.subsidies
      ? Object.entries(data.subsidies)
          .filter(
            ([, subsidy]) => subsidy.value === '0' || subsidy.value === '',
          )
          .map(([key]) => key)
      : []

    if (zeroValueSubsidies.length > 0) {
      const subsidyNames = zeroValueSubsidies.map((subsidyName) => {
        const translation = subsidyTranslations[subsidyName]
        if (translation) {
          return `${translation.label} ${translation.sublabel}`.trim()
        }
        return subsidyName
      })

      const bidFormData: BidFormData = {
        ...data,
        zeroValueSubsidyNames: subsidyNames.join(', '),
      }

      setFormData(bidFormData)
      setIsSubsidyConfModalOpen(true)
    } else {
      onSubmit(data)
    }
  }

  const handleModalConfirm = () => {
    if (formData) {
      onSubmit(formData)
    }
    setIsSubsidyConfModalOpen(false)
  }

  const handleModalCancel = () => {
    setIsSubsidyConfModalOpen(false)
  }

  const priceTypes = priceTypeList.filter(
    (item) => item.value !== 'MATCHED' && item.value !== 'UNMATCHED',
  )

  const priceTypeOptions = useMemo(() => {
    return priceTypes.map((item) => {
      return {
        value: item.value,
        label: tUtils(`priceType.${item.label}`),
      }
    })
  }, [])

  return (
    <>
      <FormProvider {...methods}>
        <form
          onSubmit={methods.handleSubmit(handleSubmit)}
          className="sm:max-w-[650px]"
        >
          <h1 className="text-xl text-ppa/title font-bold mb-5">
            {bid ? t('UpdateBidTitle') : t('placeBidTitle')}
          </h1>
          <div className="border-l border-ppa/primary mb-5">
            <span className="text-ppa/primary text-base font-semibold ml-3">
              {tender.shortId}
            </span>
          </div>
          <div className="flex flex-col gap-y-4">
            <Controller
              name="contractType"
              control={control}
              rules={{
                required: {
                  message: t('fields.contractType.required'),
                  value: true,
                },
              }}
              render={({ field: { ref: _, ...props } }) => (
                <InputGroup
                  label={t('fields.contractType.label')}
                  required
                  error={formErrors.contractType?.message}
                >
                  <div className="w-full max-w-[350px]">
                    <SelectComponent
                      {...props}
                      error={formErrors.contractType?.message}
                      type="single"
                      disableClean
                      options={priceTypeOptions}
                      aria-label="priceType"
                      testId="select-priceType"
                    />
                  </div>
                </InputGroup>
              )}
            />

            <CustomPricingForm
              tender={tender}
              contractType={watch('contractType')}
              startDate={tender.startDate}
              endDate={tender.endDate}
            />

            <BenefitsForm bid={bid} />
            <SubsidiesForm tender={tender} bid={bid} />
            <ChargesForm bid={bid} />

            <div className="border border-b border-ppa/grayBorder w-full" />
            <InputGroup label={t('fields.expiresAt.label')}>
              <Message variant="info" size="text-xs self-center">
                {t('fields.expiresAt.infoMessage')}
              </Message>
            </InputGroup>
            <div className="flex items-start w-72 -mt-3 mb-4 gap-x-3">
              <Controller
                name="expiresAt"
                control={control}
                rules={{ required: t('fields.expiresAt.required') }}
                render={({ field: props }) => (
                  <InputGroup
                    label={t('fields.expiresAt.title')}
                    error={formErrors.expiresAt?.message}
                    required
                  >
                    <Input
                      type="datetime-local"
                      {...props}
                      min={dayjs().format(dateFormats.dateInput)}
                      error={formErrors.expiresAt?.message}
                    />
                  </InputGroup>
                )}
              />
            </div>
          </div>
          <div className="border border-b border-ppa/grayBorder w-full" />
          <div className="flex justify-end mt-5">
            <div>
              <InputGroup label={t('fields.charges.brokerFee.label')}>
                <Message variant="info" size="text-xs self-center">
                  {t('fields.charges.brokerFee.infoMessage')}
                </Message>
                <div className="w-60 mt-1 ml-[0.2px]">
                  <Input
                    readOnly
                    disabled
                    type="text"
                    suffix="£/MWh"
                    disabledInput
                    value={Number(brokerFee).toFixed(2)}
                  />
                </div>
              </InputGroup>
            </div>
          </div>
          <div className="border border-b border-ppa/grayBorder w-full my-3" />
          <div className="flex justify-end">
            <Message variant="warn" size="text-xs mt-1">
              {t('fields.submit.infoMessage')}
            </Message>
          </div>
          <div className="flex justify-end mt-2">
            <Button
              variant="primary"
              type="submit"
              loading={isLoading}
              disabled={isSubmitting}
              formWidth
            >
              {t('fields.submit.button')}
            </Button>
          </div>
        </form>
      </FormProvider>
      {isSubsidyConfModalOpen && (
        <BidSubsidiesInfoModal
          isOpen={isSubsidyConfModalOpen}
          onClose={handleModalCancel}
          onConfirm={handleModalConfirm}
          subsidyName={formData?.zeroValueSubsidyNames || ''}
        />
      )}
    </>
  )
}

export default BidForm
